import Vue from 'vue'
import { OUTPUT_FORMATS } from '@/constants/utils/moneyFormat'
import { convertToLocal } from '@/utils/date'
import { formatCurrency } from '@/utils/moneyFormat'
import { convertToUsd } from '@/utils-ts/currencies'
import Currency from '@/models-ts/Currency'
import ratesMixin from '@/mixins/ratesMixin'
import paginalMixin from '@/mixins/paginalMixin'
import { getReferrals } from '@/api/timewarp'

export default Vue.extend<any, any, any, any>({
  mixins: [paginalMixin, ratesMixin],
  props: {
    currency: Currency,
  },
  data () {
    return {
      navigationPagination: false,
      loading: false,
      referrals: [],
      pageSize: 40,
      total: 0,
    }
  },
  computed: {
    initialLoading () {
      return this.loading && !this.total
    },
    pagination () {
      return { total: this.total }
    },
  },
  methods: {
    async load ({ offset }: { offset: number }) {
      try {
        this.loading = true
        const { pagination, referrals } = await getReferrals({ limit: this.pageSize, offset })
        this.referrals = referrals
        this.total = pagination.total
        this.$emit('totalLoaded', this.total)
      } finally {
        this.loading = false
      }
    },
    formatDate (date: string) {
      return convertToLocal(date, 'DD.MM.YYYY')
    },
    formatAmount (ref: any) {
      if (!ref.profit.amount) {
        return 0
      }
      return formatCurrency(ref.profit.amount,
        {
          currency: this.currency.name,
          outputFormat: OUTPUT_FORMATS.CURRENCY_COMISSION
        }
      )
    },
    formatAmountUsd (ref: any) {
      if (!ref.profit.amount) {
        return 0
      }
      const usdReward = convertToUsd({
        value: ref.profit.amount,
        currency: this.currency,
        rates: this.rates,
        format: false
      })
      return usdReward
    }
  },
})
