import Vue from 'vue'
import { CURRENCIES } from '@/constants/currency'
import { formatUsd } from '@/utils/moneyFormat'
import JobApplication from '@/models-ts/job/JobApplication'
import { formatDate, getDiff } from '@/utils/date'
import PreferredCurrencies from '@/partials/PreferredCurrencies/PreferredCurrencies.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    PreferredCurrencies,
  },
  props: {
    application: JobApplication,
  },
  data () {
    return {
    }
  },
  computed: {
    job () {
      return this.application.job
    },
    hasDelivery () {
      return !!this.job.delivery_time_at
    },
    daysLeft () {
      const days = Math.max(getDiff(this.job.delivery_time_at, Date.now(), 'day'), 0)
      return days === 1 ? `${days} day` : `${days} days`
    },
    deliveryFormat () {
      return formatDate(this.job.delivery_time_at, 'MMM Do, YYYY')
    },
    budget () {
      return formatUsd(this.job.budget)
    },
    jobCurrencies () {
      return CURRENCIES
    },
  }
})
