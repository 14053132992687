import { render, staticRenderFns } from "./FreelancerArchiveTab.htm?vue&type=template&id=73720048&scoped=true&"
import script from "./FreelancerArchiveTab.ts?vue&type=script&lang=js&"
export * from "./FreelancerArchiveTab.ts?vue&type=script&lang=js&"
import style0 from "./FreelancerArchiveTab.scss?vue&type=style&index=0&id=73720048&lang=scss&scoped=true&"
import style1 from "../../MyJobsTab.scss?vue&type=style&index=1&id=73720048&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73720048",
  null
  
)

export default component.exports