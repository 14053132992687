import Vue, { PropType } from 'vue'
import { mapGetters, mapState } from 'vuex'
import FilesTab from './FilesTab/FilesTab.vue'
import ImagesTab from './ImagesTab/ImagesTab.vue'
import LinksTab from './LinksTab/LinksTab.vue'
import ThreadTab from './ThreadTab/ThreadTab.vue'
import { Stages } from '@/constants/vacancies/statuses'
import Vacancy from '@/models-ts/vacancies/Vacancy'

export default Vue.extend({
  props: {
    disabled: Boolean,
    vacancyStage: Number as PropType<Stages>,
    vacancy: Vacancy,
  },
  components: {
    FilesTab,
    ImagesTab,
    LinksTab,
    ThreadTab,
  },
  data () {
    return {
      activeTab: 'thread',
    }
  },
  computed: {
    ...mapState({
      roomId: (state: any) => state.chatNew.openedRoomId,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    unreadCount () {
      const unreadCount = this.getUnreadCount(this.roomId)
      if (unreadCount) {
        return unreadCount > 99 ? '99+' : unreadCount
      }
    },
  },
})
