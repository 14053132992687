import Vue from 'vue'
import { mapMutations } from 'vuex'
import GeneralInfo from '@/models-ts/user/profiles/cutomer/GeneralInfo'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { saveMyGeneralCustomerProfile } from '@/api/users'
import focusableMixin from '../../focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin],
  props: {
    generalInfo: GeneralInfo,
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: new GeneralInfo({}),
      editableFields: ['name'],
    }
  },
  computed: {
    wasUpdated () {
      return Object.entries(this.form).some(([key, value]) => value !== this.generalInfo[key])
    },
  },
  watch: {
    generalInfo: {
      handler () {
        this.initForm()
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations({
      setProfileData: 'user/setProfileData',
    }),
    initForm () {
      if (this.generalInfo) {
        this.form = new GeneralInfo(this.generalInfo)
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    onEditClick () {
      this.editMode = true
    },
    onCancelClick () {
      this.editMode = false
      this.errors.clear()
      this.initForm()
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('generalInfo')
      if (isValid) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              return saveMyGeneralCustomerProfile(this.form.toServer())
            },
            title: 'General info',
            successText: 'You have successfully updated your general information.',
            failureText: 'Error updating your information. Please try again.'
          })
          this.form = MyCustomerProfile.fromServer(res).generalInfo
          this.setProfileData({ first_name: this.form.firstName, last_name: this.form.lastName })
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  }
})
