import FileChatMessage from '@/models-ts/chat/messages/FileChatMessage'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import FileMessage from './FileMessage/FileMessage.vue'

export default Vue.extend({
  components: {
    FileMessage,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      messages: 'chatNew/getRoomMessages',
    }),
    files () {
      return this.messages.filter((msg: any) => msg instanceof FileChatMessage)
    },
  },
  watch: {
  },
  created () {
  },
  methods: {
  },
})
