import Vue, { PropType } from 'vue'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import { mapActions, mapMutations, mapState } from 'vuex'
import CompanyInfo from '@/models-ts/user/profiles/cutomer/CompanyInfo'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import PrefferedCurrencyModel from '@/models-ts/user/PrefferedCurrency'
import Skill from '@/models-ts/Skill'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import snackMixin from '@/mixins/snackMixin'
import { readImage } from '@/utils/file'
import { saveCustomerCompanyInfoProfile } from '@/api/users'
import SkillsSelect from '../SkillsSelect/SkillsSelect.vue'
import { USER_TYPE_CUSTOMER_COMPANY } from '@/constants/user/roles'
import { Avatar } from '@/models/user'

type Country = { id: number, name: string }
const TO_MB = 1024 * 1024
const IMAGE_LIMIT = 2 * TO_MB
const ACCEPT_FORMATS = ['jpg', 'jpeg', 'png', 'gif']

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    PrefferedCurrency,
    SkillsSelect,
  },
  props: {
    info: CompanyInfo,
    skills: {
      type: Array as PropType<Array<Skill>>,
      default: [],
    },
    currencies: {
      type: Array as PropType<Array<PrefferedCurrencyModel>>,
      default: [],
    },
    editMode: Boolean,
  },
  data () {
    return {
      saving: false,
      form: new CompanyInfo({}),
      formSkills: [],
      formCurrencies: [],
      locationSearch: '',
    }
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    accept () {
      return ACCEPT_FORMATS.join(', ')
    },
    avatar () {
      return this.form.avatar?.src
    },
    countriesNames () {
      return this.form.countries.map((c: any) => c.name).filter(Boolean)
    },
    selectedCountries: {
      get () {
        return this.form.countries.map((c: any) => c.id)
      },
      set (v) {
        this.form.countries = v.map((opt: any) => ({ id: opt }))
      }
    },
    countriesTags () {
      return (this.form.countries || [])
        .map((c: Country) => c.name ? c : this.countries.find((country: Country) => country.id === c.id))
        .filter(Boolean)
        .map((c: Country) => ({ text: c.name }))
    },
    countriesOptions () {
      const options = (this.countries || []).map((c: any) => ({ key: c.id, label: c.name }))
      return this.locationSearch
        ? options.filter((opt: any) => opt.label.toLowerCase().includes(this.locationSearch.toLowerCase()))
        : options
    },
    websiteFormated () {
      if (this.form.website) {
        try {
          const url = new URL(this.form.website)
          return { link: this.form.website, domain: url.hostname }
        } catch (e) {
          if (!this.form.website.startsWith('http')) {
            return { link: `http://${this.form.website}`, domain: this.form.website }
          }
        }
      }
    },
  },
  watch: {
    generalInfo: {
      handler () {
        this.initForm()
      },
      immediate: true
    }
  },
  async created () {
    try {
      this.getCountries()
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapMutations({
      setCompanyLogo: 'user/setCompanyLogo',
    }),
    ...mapActions({
      getCountries: 'countries/getCountries',
      openModal: 'ui/openModal',
      setActiveProfile: 'user/setActiveProfile',
    }),
    initForm () {
      if (this.info) {
        this.form = new CompanyInfo(this.info)
      }
      if (this.skills.length) {
        this.formSkills = [...this.skills]
      }
      if (this.currencies.length) {
        this.formCurrencies = [...this.currencies]
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    validateCountries () {
      if (!this.form.countries.length) {
        this.errors.add({
          field: 'form.countries',
          msg: 'Required field'
        })
        return false
      }
      return true
    },
    validateCurrencies () {
      if (!this.formCurrencies.length) {
        this.errors.add({
          field: 'form.currencies',
          msg: 'Required field'
        })
        return false
      }
      return true
    },
    async onChangeAvatar (event: Event | null) {
      if (event) {
        // @ts-ignore
        const file = event.target.files[0]
        // @ts-ignore
        event.target.value = ''
        const fileData = await readImage(file, this.accept)
        const dotIndex = file.name.lastIndexOf('.')
        let format = null
        let fileSizeLimit = IMAGE_LIMIT
        if (dotIndex !== -1) {
          format = file.name.substring(dotIndex + 1).toLowerCase()
        }
        if (ACCEPT_FORMATS.indexOf(format) === -1) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `Invalid file format. Please upload in ${this.accept}`,
          })
        } else if (file.size > fileSizeLimit) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `File size exceeds ${fileSizeLimit / TO_MB} MB`,
          })
        } else {
          this.form.avatar.setBase64(fileData.base64)
        }
      }
    },
    onRemoveAvatarClick () {
      this.form.avatar.clear()
    },
    onInputCountry (options: Array<number>) {
      this.form.countries === options.map(opt => ({ id: opt }))
    },
    async onLocationSearchInput (v: string) {
      this.locationSearch = v
    },
    onDeleteCountry ({ index }: { index: number }) {
      this.form.countries.splice(index, 1)
    },
    onCancelClick () {
      this.errors.clear()
      this.initForm()
      this.$emit('cancel')
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('companyInfo')
      const isValidCountries = this.validateCountries()
      const isValidCurrencies = this.validateCurrencies()
      if (isValid && isValidCountries && isValidCurrencies) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              return saveCustomerCompanyInfoProfile(merge(this.form.toServer(), {
                relations: {
                  Currency: this.formCurrencies.map((c: PrefferedCurrencyModel) => c.toServer()),
                  Skill: this.formSkills.map((s: Skill) => s.toServer()),
                }
              }))
            },
            title: 'Company info',
            successText: 'You have successfully updated your company information.',
            failureText: 'Error updating your information. Please try again.'
          })
          const profile = MyCustomerProfile.fromServer(res)
          this.form = profile.companyInfo
          this.formSkills = profile.skills
          this.formCurrencies = profile.currencies
          await this.setActiveProfile({
            profile: {
              id: res.user_id,
              type: USER_TYPE_CUSTOMER_COMPANY,
              company_name: this.form.companyName,
              company_size: this.form.employees,
              registered: null,
              description: this.form.description,
              website: this.form.website,
              address: null,
              relations: {
                Country: this.form.countries,
                Skill: profile.skills,
                Currency: profile.currencies,
              }
            }
          })
          if (this.avatar) {
            this.setCompanyLogo(this.form.avatar.toAvatar())
          } else {
            this.setCompanyLogo(null)
          }
          this.$emit('update', cloneDeep(profile))
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  }
})
