import Vue from 'vue'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import WorkExperience from '@/models-ts/user/WorkExperience'
import Education from '@/models-ts/user/Education'
import Channel from '@/models-ts/user/Channel'
import GeneralInfo from '@/models-ts/user/profiles/freelancer/GeneralInfo'
import AvatarSection from '../../AvatarSection/AvatarSection.vue'
import EducationSection from './EducationSection/EducationSection.vue'
import GeneralInfoSectionFreelancer from './GeneralInfoSectionFreelancer/GeneralInfoSectionFreelancer.vue'
import SocialMediaSection from './SocialMediaSection/SocialMediaSection.vue'
import WorkExperienceSection from './WorkExperienceSection/WorkExperienceSection.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    AvatarSection,
    EducationSection,
    GeneralInfoSectionFreelancer,
    SocialMediaSection,
    WorkExperienceSection,
  },
  props: {
    profile: MyFreelancerProfile,
  },
  computed: {
    generalInfo () {
      return this.profile?.generalInfo
    }
  },
  methods: {
    onProfileUpdate (info: GeneralInfo) {
      this.$emit('update', new MyFreelancerProfile({
        ...this.profile,
        generalInfo: info,
      }))
    },
    onExperienceUpdate (exp: Array<WorkExperience>) {
      this.$emit('update', new MyFreelancerProfile({
        ...this.profile,
        workExperience: exp,
      }))
    },
    onEducationUpdate (ed: Array<Education>) {
      this.$emit('update', new MyFreelancerProfile({
        ...this.profile,
        educations: ed,
      }))
    },
    onChannelsUpdate (ch: Array<Channel>) {
      this.$emit('update', new MyFreelancerProfile({
        ...this.profile,
        channels: ch,
      }))
    },
  }
})
