import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { JOB_DETAILS_ADMIN_APPLICATIONS, JOB_CHAT } from '@/constants/routes'
import {
  STAGE_NEW,
  STAGE_IN_PROGRESS,
  STAGE_STARTED,
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DISPUTED,
  STAGE_DEADLINE_OVERDUE,
} from '@/constants/job/jobStages'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { convertToUsd } from '@/utils-ts/currencies'
import { OFFER_REVIEW } from '@/constants/job/jobOfferStatuses'
import { BLOCKCHAIN_OPTIONS, Blockchain, BlockchainNames } from '@/constants/blockchain'
import { DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN } from '@/constants/utils/date'
import { formatDate, addDays, isBefore } from '@/utils/date'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { REFUND_DELAY_DAYS } from '@/constants/job/jobCommon'
import ratesMixin from '@/mixins/ratesMixin'
import Rate from '@/models-ts/Rate'
import { removeJob } from '@/api/jobs/job'
import Button from '@/models-ts/ui/Button'
import { getTxLink } from '@/utils-ts/transaction'
import CardActionMenu from '../../components/CardActionMenu/CardActionMenu.vue'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import JobStatusBadge from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  components: {
    UserInfo,
    CardActionMenu,
    JobTypeBadge,
    JobStatusBadge,
  },
  props: {
    job: MyCustomerJobListItem,
  },
  data () {
    return {
      JOB_DETAILS_ADMIN_APPLICATIONS,
      STATUS_PUBLISHED,
      BlockchainNames,
      DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN,
      deleting: false,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
      room: 'chatNew/getOpenedRoom',
    }),
    hasDeleteBtn () {
      return [STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    isLocked () {
      return this.room && !this.room.isUnlocked
    },
    chatRoomId () {
      if (this.job.job_application_id && this.job.freelancer_id) {
        return ChatRoom.generateRoomId({
          type: RoomTypes.JOB,
          applicationId: this.job.job_application_id,
          freelancerId: this.job.freelancer_id,
          customerId: this.job.customer_id,
        })
      }
    },
    chatLink () {
      return {
        name: JOB_CHAT,
        params: { id: this.job.id, slug: this.job.slug },
        query: { room: this.chatRoomId }
      }
    },
    chatDepositLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'deposit',
        }
      }
    },
    chatPayLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'pay',
        }
      }
    },
    chatFeedbackLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'feedback',
        }
      }
    },
    chatRefundLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'refund',
        }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    hasChat () {
      return this.job.job_application_id
    },
    isStarted () {
      return ![STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    isInProgress () {
      return [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER].includes(this.job.stage)
    },
    isArchived () {
      return this.job.stage !== STAGE_NEW && this.job.offerStage === OFFER_REVIEW
    },
    isCompleted () {
      return [STAGE_COMPLETED, STAGE_DISPUTED, STAGE_DEADLINE_OVERDUE].includes(this.job.stage)
    },
    hasDepositBtn () {
      return this.job.stage === STAGE_STARTED
    },
    hasPayJob () {
      return this.job.stage === STAGE_IN_PROGRESS
    },
    hasRefundBtn () {
      return this.job.stage === STAGE_IN_PROGRESS && isBefore(this.refundDate, Date.now())
    },
    primaryBudget () {
      const budget = this.job.getBudgetFormat()
      return this.isStarted ? `${budget} ${this.job.currencyModel?.name || ''}` : `$${budget}`
    },
    secondaryBudget () {
      if (this.job.escrowBalance && this.job.currencyModel) {
        return `$${convertToUsd({ value: this.job.escrowBalance, currency: this.job.currencyModel, rates: this.rates })}`
      }
    },
    transactionLink () {
      if (this.isInProgress) {
        return this.job.txid_created && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_created })
      }
      return this.job.txid_completed && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_completed })
    },
    transactionEndLink () {
      return this.job.txid_completed && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_completed })
    },
    deadline () {
      return this.job.deadline && formatDate(this.job.deadline, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    refundDate () {
      return this.job.deadline &&
        formatDate(addDays(this.job.deadline, REFUND_DELAY_DAYS), DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    completedDate () {
      return this.job.completedAt && formatDate(this.job.completedAt, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    user () {
      return this.job.freelancer
    },
    goToLink () {
      return { name: JOB_DETAILS_ADMIN_APPLICATIONS, params: { id: this.job.id, slug: this.job.slug } }
    },
    usd () {
      return convertToUsd({ value: this.job.budget, currency: this.job.currencyModel, rates: this.rates as Array<Rate> })
    },
    cardLink () {
      return this.chatRoomId
        ? this.chatLink
        : { name: JOB_DETAILS_ADMIN_APPLICATIONS, params: { id: this.job.id, slug: this.job.slug } }
    },
  },
  methods: {
    ...mapActions({
      publish: 'jobDetails/publish',
      unpublish: 'jobDetails/unpublish',
      openRoom: 'chatNew/openRoom',
      signPayToFreelancerV1: 'jobDetails/signPayToFreelancerV1',
      signPayToFreelancerV2: 'jobDetails/signPayToFreelancerV2',
      openModal: 'ui/openModal',
    }),
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    getBlockchainLabel (blockchain: Blockchain) {
      return BLOCKCHAIN_OPTIONS.find(bl => bl.value === blockchain)?.name || ''
    },
    onDepositToEscrow () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatDepositLink)
    },
    onPayToTalentClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatPayLink)
    },
    // onReportClick () {
    //   const toUser = this.room.participants.find((user: ChatParticipant) => +user.userId !== this.userId)
    //   this.openModal({
    //     component: 'lx-lazy-modal',
    //     props: {
    //       factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ReportUser/ReportUser.vue'),
    //       props: {
    //         toUserId: toUser.userId,
    //         toUserName: toUser.name,
    //       },
    //     },
    //   })
    // },
    onLeaveReviewClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatFeedbackLink)
    },
    onClickDeleteBtn () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Remove Job',
          text: `Are you sure you want to remove Job: <b>${this.job.name}</b>`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    request: () => removeJob(this.job.id),
                    title: 'Remove Job',
                    successText: `Job <b>${this.job.name}</b> has been deleted.`,
                    failureText: 'Error deleting Job. Please try again.'
                  })
                  this.$emit('delete', this.job)
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        }
      })
    },
  },
})
