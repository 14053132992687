import Vue from 'vue'
import { mapActions } from 'vuex'
import ImageChatMessage from '@/models-ts/chat/messages/ImageChatMessage'
import { formatDate } from '@/utils/date'
import { humanizeBytes } from '@/utils-ts/strings'

export default Vue.extend<any, any, any, any>({
  props: {
    message: ImageChatMessage,
  },
  data () {
    return {
    }
  },
  computed: {
    size () {
      return humanizeBytes(this.message.body.size)
    },
    date () {
      return formatDate(this.message.updatedAt, 'MMM DD, HH:mm')
    },
  },
  watch: {
  },
  created () {
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onImageClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
          props: {
            images: [{
              id: this.message.id,
              description: this.message.body.name,
              src: this.message.body.url,
            }]
          }
        }
      })
    },
  },
})
