import BigNumber from 'bignumber.js'
import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import FreelanceInfo from '@/models-ts/user/profiles/freelancer/FreelanceInfo'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { formatUsd } from '@/utils/moneyFormat'
import { saveMyFreelancerInfoProfile } from '@/api/users'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  components: {
    PrefferedCurrency,
  },
  props: {
    info: FreelanceInfo,
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: new FreelanceInfo({}),
      editableFields: ['payments', 'specialization', 'rate'],
    }
  },
  computed: {
    wasUpdated () {
      const a = omit(this.info.toServer(), 'rate')
      const b = omit(this.form.toServer(), 'rate')
      const rateIsEqual = new BigNumber(this.info.rate).eq(this.form.rate)
      return !rateIsEqual || !isEqual(a, b)
    },
  },
  watch: {
    info: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
    editMode () {
      this.initForm()
    },
  },
  methods: {
    formatUsd,
    initForm () {
      if (this.info) {
        this.form = new FreelanceInfo(this.info)
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('freelanceInfo')
      if (isValid) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              return saveMyFreelancerInfoProfile(this.form.toServer())
            },
            title: 'Freelancer info',
            successText: 'You have successfully updated your freelancing profile.',
            failureText: 'Error updating your information. Please try again.'
          })
          const profile = MyFreelancerProfile.fromServer(res)
          this.form = profile.freelanceInfo
          this.$emit('update', profile)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
