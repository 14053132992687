import BigNumber from 'bignumber.js'
import Joi from 'joi'
import AbstractModel from '@/models/AbstractModel'
import Currency from '@/models-ts/Currency'

class AbstractTransaction extends AbstractModel {
  static propTypes = {
    created_at: Joi.string().required(),
    event: Joi.string().required(),
    wallet: Joi.string().required(),
    icon: Joi.string().required(),
    title: Joi.string().required(),
    tx_id: Joi.string().allow(null),
    currency: Joi.alternatives().try(Joi.object().instance(Currency), Joi.object().instance(Currency)),
    amount: Joi.object().instance(BigNumber),
  }
}

export default AbstractTransaction
