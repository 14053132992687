import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Chat from '@/partials/ChatNew/Chat.vue'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import { CHAT, SERVICE_DETAILS } from '@/constants/routes'
import ActionsCard from './ActionsCard/ActionsCard.vue'
import GigDetailsCard from './GigDetailsCard/GigDetailsCard.vue'
import GigJobDetailsCard from './GigJobDetailsCard/GigJobDetailsCard.vue'
import GigStatusBar from './GigStatusBar/GigStatusBar.vue'
import OfferCard from './OfferCard/OfferCard.vue'
import MobileActionsCard from './MobileActionsCard/MobileActionsCard.vue'
import { getApplication, getGigOffer, getService } from '@/api/gig'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import GigApplication from '@/models-ts/gigs/GigApplication'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import Gig from '@/models/backend/Gig'
import InformerCard from './InformerCard/InformerCard.vue'
import ChatRoomPrimaryInfo from '@/partials/ChatRoomPrimaryInfo/ChatRoomPrimaryInfo.vue'
import actionsCardMixin from './actionsCardMixin'
import BigNumber from 'bignumber.js'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, responseMixin, rolebleMixin],
  components: {
    ActionsCard,
    MobileActionsCard,
    Chat,
    ChatRoomPrimaryInfo,
    GigDetailsCard,
    GigJobDetailsCard,
    GigStatusBar,
    InformerCard,
    OfferCard,
  },
  data () {
    return {
      initLoaded: false,
      refreshing: false,
      withDetails: false,
      application: null,
      offer: null,
      lastUpdatedRoomDate: null,
      gig: null,
    }
  },
  computed: {
    ...mapState({
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      userId: (state: any) => state.user?.id,
      roomId: (state: any) => state.chatNew.openedRoomId,
      initialized: (state: any) => state.chatNew.initialized,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
    }),
    applicationId () {
      const { applicationId } = ChatRoom.parseRoomId(this.roomId)
      return applicationId
    },
    isFreelancerInfo () {
      return !this.application || !this.isFreelancer
    },
    gigJob () {
      return this.offer?.job
    },
    isTemporary () {
      return (this.room && this.room.temporary) || (this.roomId || '').startsWith('tmp_')
    },
    hasActions () {
      return !this.isTemporary
    },
    hasOffer () {
      return this.initLoaded && !this.room?.temporary && this.application?.offer && !this.gigJob
    },
    roomUser () {
      if (this.application) {
        return this.isFreelancer
          ? this.application.customer
          : this.application.freelancer
      }
      const freelancer = this.gig.relations.Freelancer
      return {
        id: freelancer.id,
        name: freelancer.name,
        avatar: freelancer.avatar,
        type: freelancer.type,
        avgReviews: freelancer.avg_reviews,
        reviewsCount: freelancer.reviewsCount,
      }
    },
    roomEntity () {
      if (!this.initLoaded) return
      if (this.application) {
        return {
          name: this.application.gig.name,
          link: {
            name: SERVICE_DETAILS,
            params: {
              id: this.application.gig.id,
              slug: this.application.gig.slug
            }
          },
        }
      }
      return {
        name: this.gig.name,
        link: {
          name: SERVICE_DETAILS,
          params: {
            id: this.gig.id,
            slug: this.gig.slug,
          }
        }
      }
    },
    backLink () {
      return this.roomEntity?.link
    },
  },
  watch: {
    initialized: {
      handler () {
        if (this.initialized) {
          if (!this.roomId) {
            const room = this.$route.query.room
            if (room) {
              this.openRoom(room)
            } else {
              this.$router.replace({ name: CHAT })
            }
          }
        }
      },
      immediate: true,
    },
    roomId: {
      async handler () {
        if (this.roomId) {
          this.initLoaded = false
          if (!this.isTemporary) {
            const application = await getApplication(this.applicationId)
            this.application = GigApplication.fromServer(application)
            if (this.application?.offer && this.application.offer.stage !== GigOfferStages.NEW) {
              this.offer = GigOfferListItem.fromServer(await getGigOffer(this.application.offer.id))
            }
            this.initLoaded = true
          } else if (this.room) {
            const gig = await getService(`${this.$route.params.slug}-${this.$route.params.id}`)
            this.gig = Gig.fromServer(gig)
            this.initLoaded = true
          } else {
            this.$router.replace({ name: CHAT })
          }
        }
        if (this.room && this.roomId !== this.$route.query.room) {
          this.$router.replace(this.room.chatLink)
        }
        if (this.$route.query.action) {
          this.processInitialAction(this.$route.query.action)
        }
      },
      immediate: true
    },
    'room.updatedAt': {
      handler () {
        if (this.lastUpdatedRoomDate) {
          let dateA = Number(new Date(this.lastUpdatedRoomDate))
          let dateB = Number(new Date(this.room.updatedAt))
          // ToDo: this could lead to duplicate queries,
          // when first send after emitting the "refresh" event and second after receive "room.action" chat event
          if (Math.abs(dateA - dateB) > 1000) {
            this.onRefresh()
          }
        }
        this.lastUpdatedRoomDate = this.room?.updatedAt
      },
      immediate: true
    },
  },
  // mounted () {
  //   googleAnalyticsV2.send({
  //     event: 'job_apps_view',
  //   })
  // },
  beforeDestroy () {
    this.closeRoom()
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
      closeRoom: 'chatNew/closeRoom'
    }),
    onOfferCancel (id: number) {
      if (this.application.offer?.id === id) {
        this.application.offer = null
      }
    },
    async onRefresh () {
      this.refreshing = true
      if (this.application) {
        const application = await getApplication(this.applicationId)
        this.application = GigApplication.fromServer(application)
        if (this.application?.offer && this.application.offer.stage !== GigOfferStages.NEW) {
          this.offer = GigOfferListItem.fromServer(await getGigOffer(this.application.offer.id))
        }
      }
      this.refreshing = false
    },
    async onOfferAccepted () {
      await this.onRefresh()
      this.startJob()
    },
    async onOfferEdit () {
      const offer = this.application.offer
      if (offer) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
            title: 'Send an offer',
            props: {
              applicationId: this.application.id,
              offer,
              initBudget: new BigNumber(offer.budget).toNumber(),
              initDays: offer.deadlineInDays,
              initHours: offer.timeValue,
              gig: this.application.gig,
              onSuccess: () => {
                this.onRefresh()
              }
            }
          }
        })
      }
    },
    onMessageSent () {
      this.markApplicationAsRead()
      this.onApplicationRead()
    },
    onApplicationRead () {
      this.application.isRead = true
    },
    processInitialAction (action: string) {
      switch (action) {
        case 'deposit': return this.initialStartJob()
        case 'pay': return this.initialPayJob()
        case 'dispute': return this.initialDispute()
        case 'refund': return this.initialRefund()
        case 'feedback': return this.initialLeaveFeedback()
      }
    },
    initialStartJob () {
      if (this.hasStartJob) {
        this.startJob()
      }
    },
    initialPayJob () {
      if (this.hasPayJob || this.hasAcceptJob) {
        this.onPayToTalentClick()
      }
    },
    initialDispute () {
      if (this.hasCreateDispute) {
        this.onCreateDisputeClick()
      }
    },
    initialRefund () {
      if (this.hasRefund) {
        this.onRefundClick()
      }
    },
    initialLeaveFeedback () {
      if (this.hasLeaveReview) {
        this.onLeaveReviewClick()
      }
    },
    async startJob () {
      try {
        this.setDepositingOffer(this.application.offer?.id)
        this.signCreateContractV2({
          gig: this.offer,
          successSign: () => {
            this.openSnackbar({
              type: this.SnackTypes.SUCCESS,
              text: 'Your funds have successfully been deposited to escrow.',
            })
            this.starting = false
            this.emitRefresh()
          },
          cancelSign: () => {
            this.setDepositingOffer(null)
          }
        })
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.setDepositingOffer(null)
      }
    },
  },
  metaInfo () {
    return {
      title: this.application?.gig?.name || 'Chat'
    }
  }
})
