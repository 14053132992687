import Vue, { PropType } from 'vue'
import responseMixin from '@/mixins/responseMixin'
import PortfolioItem from './PortfolioItem/PortfolioItem.vue'
import PortfolioProject from '@/models-ts/user/PortfolioProject'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { saveFreelancerPortfolioProfile } from '@/api/users'
import isNotEmptyProject from './isNotEmptyProject'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  components: {
    PortfolioItem,
  },
  props: {
    portfolio: Array as PropType<Array<PortfolioProject>>
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: [],
      editableFields: ['portfolio'],
    }
  },
  computed: {
  },
  watch: {
    portfolio: {
      handler () {
        if (this.portfolio) {
          this.initForm()
        }
      },
      immediate: true
    },
  },
  methods: {
    initForm () {
      this.form = this.portfolio.map((pr: PortfolioProject) => new PortfolioProject(pr))
    },
    onEditClick () {
      this.editMode = true
      if (!this.form.length) {
        this.form.push(new PortfolioProject({}))
      }
    },
    onAddClick () {
      this.form.push(new PortfolioProject({}))
    },
    onAddPortfolioClick () {
      this.editMode = true
      this.form.push(new PortfolioProject({}))
    },
    onClearClick (i: number) {
      this.form.splice(i, 1)
      if (!this.form.length) {
        this.form.push(new PortfolioProject({}))
      }
    },
    onUpdate (exp: PortfolioProject, i: number) {
      this.form.splice(i, 1, exp)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      const isValid = await Promise.all((this.$refs.projectItem || []).map((item: any) => item.validate()))
      if (isValid.every(Boolean)) {
        try {
          this.saving = true
          const portfolio = await notifiableRequest({
            request: async () => {
              return saveFreelancerPortfolioProfile(this.form
                .filter(isNotEmptyProject)
                .map((exp: PortfolioProject) => exp.toServer()))
            },
            title: 'Portfolio',
            successText: 'Your portfolio has been successfully updated.',
            failureText: 'Error updating your portfolio. Please try again.'
          })
          this.form = portfolio.map(PortfolioProject.fromServer)
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
