import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import ChatRoom from '../ChatRoom/ChatRoom.vue'
import roomsMixin from '../roomsMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [roomsMixin],
  components: {
    ChatRoom,
  },
  computed: {
    ...mapState({
      rooms: (state: any) => Object.values(state.chatNew.vacanciesRooms.values),
      isLoaded: (state: any) => state.chatNew.vacanciesRooms.isLoaded,
      isLoading: (state: any) => state.chatNew.vacanciesRooms.isLoading,
      pagination: (state: any) => state.chatNew.vacanciesRooms.pagination,
    }),
  },
  methods: {
    ...mapActions({
      loadMore: 'chatNew/loadMoreVacanciesRooms'
    })
  },
})
