import Vue from 'vue'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import uniq from 'lodash/uniq'
import rolebleMixin from '@/mixins/rolebleMixin'
import { mapState, mapActions, mapGetters } from 'vuex'
import AbstractChatMessage from '@/models-ts/chat/messages/AbstractChatMessage'
import { ChatMessage } from '@/models-ts/chat/messages/ChatMessageType'
import { MessageTypes } from '@/constants/chat/MessageTypes'
import FileChatMessage from './FileChatMessage/FileChatMessage.vue'
import GigMarkedDoneChatMessage from './GigMarkedDoneChatMessage/GigMarkedDoneChatMessage.vue'
import GigOfferChatMessage from './GigOfferChatMessage/GigOfferChatMessage.vue'
import GigUnavailableChatMessage from './GigUnavailableChatMessage/GigUnavailableChatMessage.vue'
import ReviewChatMessage from './ReviewChatMessage/ReviewChatMessage.vue'
import ImageChatMessage from './ImageChatMessage/ImageChatMessage.vue'
import RoomClosedChatMessage from './RoomClosedChatMessage/RoomClosedChatMessage.vue'
import RoomLockedChatMessage from './RoomLockedChatMessage/RoomLockedChatMessage.vue'
import DisputeCreatedChatMessage from './DisputeCreatedChatMessage/DisputeCreatedChatMessage.vue'
import JobMarkedDoneChatMessage from './JobMarkedDoneChatMessage/JobMarkedDoneChatMessage.vue'
import JobOfferChatMessage from './JobOfferChatMessage/JobOfferChatMessage.vue'
import RefundedChatMessage from './RefundedChatMessage/RefundedChatMessage.vue'
import TextChatMessage from '../../TextChatMessage/TextChatMessage.vue'
import UserBannedChatMessage from './UserBannedChatMessage/UserBannedChatMessage.vue'
import UserInfo from '../../UserInfo/UserInfo.vue'
import JobUnavailableChatMessage from './JobUnavailableChatMessage/JobUnavailableChatMessage.vue'
import RoomUnlockedChatMessage from './RoomUnlockedChatMessage/RoomUnlockedChatMessage.vue'
import VacancyApplicationAppliedChatMessage from './VacancyApplicationAppliedChatMessage/VacancyApplicationAppliedChatMessage.vue'
import VacancyApplicationDeclinedChatMessage from './VacancyApplicationDeclinedChatMessage/VacancyApplicationDeclinedChatMessage.vue'
import VacancyUnavailableChatMessage from './VacancyUnavailableChatMessage/VacancyUnavailableChatMessage.vue'
import { RoomTypes } from '@/constants/chat/RoomTypes'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    DisputeCreatedChatMessage,
    FileChatMessage,
    JobOfferChatMessage,
    JobMarkedDoneChatMessage,
    RefundedChatMessage,
    GigMarkedDoneChatMessage,
    GigOfferChatMessage,
    GigUnavailableChatMessage,
    ReviewChatMessage,
    ImageChatMessage,
    RoomClosedChatMessage,
    RoomLockedChatMessage,
    RoomUnlockedChatMessage,
    TextChatMessage,
    UserBannedChatMessage,
    UserInfo,
    JobUnavailableChatMessage,
    VacancyApplicationAppliedChatMessage,
    VacancyApplicationDeclinedChatMessage,
    VacancyUnavailableChatMessage,
  },
  data () {
    return {
      observer: null,
      readedIds: [],
      types: MessageTypes,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
      messages: 'chatNew/getRoomMessages',
      messageLoading: 'chatNew/getRoomMessageLoading',
    }),
    isTemporary () {
      return this.room?.temporary || (this.$route.query.room || '').startsWith('tmp_')
    },
    isLocked () {
      return this.room && !this.room.isUnlocked && !this.room.isClosed
    },
    isVacancyRoom () {
      return this.room.type === RoomTypes.VACANCY
    },
    loading () {
      if (this.isTemporary) {
        return false
      }
      return !this.room || this.messageLoading
    },
    // reverseMessages () {
    //   return [...this.messages].reverse()
    // },
    chatUserId () {
      return `${this.userId}:${this.activeRole}`
    },
  },
  watch: {
    'messages.length': {
      handler () {
        if (this.messages.length) {
          this.$nextTick(() => {
            this.scrollToBottom()
            this.initObserver()
          })
        }
      },
      immediate: true
    },
  },
  mounted () {
    this.scrollToBottom()
  },
  methods: {
    ...mapActions({
      readMessages: 'chatNew/readMessages',
    }),
    scrollToBottom () {
      if (this.messages.length) {
        this.$nextTick(() => {
          if (this.$el) {
            this.$el.scrollTo(0, this.$el.scrollHeight)
          }
        })
      }
    },
    messageIsReaded (message: AbstractChatMessage) {
      return message.readers.find(user => user.readerId === this.chatUserId)
    },
    initObserver () {
      if (this.$refs.message) {
        const options = {
          root: this.$el,
          rootMargin: '0px',
          threshold: times(10, (n) => n / 10 + 0.1)
        }
        if (!this.observer) {
          this.observer = new IntersectionObserver(this.onVisible, options)
        }
        (this.$refs.message || []).forEach((msg: any, i: number) => {
          const message: AbstractChatMessage = this.messages[i]
          if (
            !message.sending &&
            !this.messageIsReaded(message) &&
            (message.isSystem || message.sender !== this.chatUserId) &&
            !this.readedIds.includes(this.messages[i].id)
          ) {
            this.observer.observe(msg.$el || msg)
          }
        })
      }
    },
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
    onVisible (entries: any) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9 || entry.boundingClientRect.height + 20 >= entry.rootBounds.height) {
          const index = (this.$refs.message || []).findIndex((msg: any) => entry.target === (msg.$el || msg))
          if (this.messages[index] && !this.messageIsReaded(this.messages[index])) {
            this.readedIds.push(this.messages[index].id)
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (this.readedIds.length) {
        this.readDebounced()
      }
    },
    readDebounced: debounce(async function (this: any) {
      const ids: Array<string> = uniq(this.readedIds)
      if (ids.length) {
        let resultsIds = [] as Array<string>
        // get array with indexes in messages sorted by index
        const getIndexes = (array: Array<ChatMessage>, ids: Array<string>) => {
          const indexes = []
          for (let i = array.length - 1; i >= 0; i--) {
            if (ids.includes(array[i].id)) {
              indexes.push({ id: array[i].id, index: i })
            }
          }
          indexes.sort((a, b) => a.index > b.index ? 1 : -1)
          return indexes
        }
        const idsWithIndex = getIndexes(this.messages, ids)
        // check case when there is msg between two read msgs
        for (let i = 0; i < idsWithIndex.length; i++) {
          const currentI = idsWithIndex[i].index
          const nextI = idsWithIndex[i + 1]?.index
          resultsIds.push(this.messages[currentI]?.id)
          if (!nextI) {
            break
          }
          if (currentI + 1 !== nextI) {
            const lessIndexes = times(nextI - currentI - 1, (i) => currentI + i + 1)
            resultsIds.concat(lessIndexes.map(i => this.messages[i]?.id))
          }
        }
        resultsIds = resultsIds.filter(Boolean)
        this.readedIds = this.readedIds.filter((id: string) => !resultsIds.includes(id))
        try {
          await this.readMessages({ roomId: this.room.id, ids: resultsIds, ownId: this.chatUserId })
        } catch (e) {
          console.error('Error reading message', e)
          this.readedIds.push(...ids)
        }
      }
    }, 1000),
    systemMessage (message: AbstractChatMessage) {
      return [
        MessageTypes.ROOM_CLOSED,
        MessageTypes.GIG_DISPUTE,
        MessageTypes.GIG_REFUNDED,
        MessageTypes.GIG_REMOVED,
        MessageTypes.GIG_UNPUBLISHED,
        MessageTypes.JOB_DISPUTE,
        MessageTypes.JOB_REFUNDED,
        MessageTypes.USER_BAN,
        MessageTypes.USER_UNBAN,
        MessageTypes.JOB_REMOVED,
        MessageTypes.JOB_UNPUBLISHED,
        MessageTypes.VACANCY_REMOVED,
        MessageTypes.VACANCY_UNPUBLISHED,
        MessageTypes.VACANCY_APPLICATION_APPLIED,
        MessageTypes.VACANCY_APPLICATION_DECLINED_BY_CUSTOMER,
        MessageTypes.VACANCY_APPLICATION_DECLINED_BY_FREELANCER,
        MessageTypes.VACANCY_ARCHIVED,
      ].includes(message.type)
    },
  },
})
