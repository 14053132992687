import { mapActions, mapState } from 'vuex'
import snackMixin from '@/mixins/snackMixin'

export default {
  mixins: [snackMixin],
  computed: {
    ...mapState({
      loading: state => state.balances.escrowBalance.isLoading,
      balance: state => state.balances.escrowBalance.value,
    }),
  },
  created () {
    try {
      this.loadEscrowBalance()
    } catch (e) {
      console.error(e)
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: 'Error fetching Escrow Balance. Please try again.',
      })
    }
  },
  methods: {
    ...mapActions({
      loadEscrowBalance: 'balances/loadEscrowBalance',
    }),
  },
}
