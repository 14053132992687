import Vue from 'vue'
import { mapState } from 'vuex'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'

export default Vue.extend<any, any, any, any>({
  props: {
    checkSide: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    senderIsMe () {
      return String(this.userId) === ChatParticipant.parseId(this.message.sender)
    },
    isOwn () {
      return this.checkSide && this.senderIsMe
    },
  },
})
