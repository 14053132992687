import Vue from 'vue'
import { CURRENCY_FIELD_NAME } from '@/constants/currency'
import { convertToUsd, getCurrency } from '@/utils-ts/currencies'
import { formatCurrency } from '@/utils/moneyFormat'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'
import ratesMixin from '@/mixins/ratesMixin'
import { addDays, addSeconds, convertToLocal, formatDate } from '@/utils/date'

const EXTRA_DAYS = 2
const DATE_FORMAT = 'MMMM Do, YYYY hh:mm A'
export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  props: {
    offer: GigOfferListItem,
  },
  data () {
    return {
    }
  },
  computed: {
    isHourly () {
      return this.offer.time_type === GigTimeTypes.HOURLY
    },
    currency () {
      return getCurrency({ blockchain: this.offer.blockchain, value: this.offer.currency, field: CURRENCY_FIELD_NAME })
    },
    currencyImage () {
      return this.currency?.imageURL || '/static/images/blockchain/currencies/empty.svg'
    },
    circle () {
      return this.currency.name === 'BRUSH'
    },
    escrowBalance () {
      return formatCurrency(this.offer.job.escrow_balance, { currency: this.currency })
    },
    escrowBalanceInUsd () {
      return this.rates.length
        ? convertToUsd({ value: this.offer.job.escrow_balance, currency: this.currency, rates: this.rates, format: true })
        : null
    },
    deadline () {
      return addSeconds(convertToLocal(this.offer.job.created_at), this.offer.deadline)
    },
    refundDate () {
      return addDays(this.deadline, EXTRA_DAYS)
    },
  },
  methods: {
    getFormattedDate (date: Date) {
      return formatDate(date, DATE_FORMAT)
    },
  }
})
