import { render, staticRenderFns } from "./Referrals.htm?vue&type=template&id=752fd622&scoped=true&"
import script from "./Referrals.ts?vue&type=script&lang=js&"
export * from "./Referrals.ts?vue&type=script&lang=js&"
import style0 from "./Referrals.scss?vue&type=style&index=0&id=752fd622&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "752fd622",
  null
  
)

export default component.exports