import Vue from 'vue'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import GigApplication from '@/models-ts/gigs/GigApplication'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import { mapState } from 'vuex'
import { GigApplicationStatuses } from '@/constants/gig/gigApplicationStatuses'

const PAST = 1
const ACTIVE = 2
const FUTURE = 3

export default Vue.extend<any, any, any, any>({
  props: {
    application: GigApplication,
    offer: GigOfferListItem,
  },
  data () {
    return {
      PAST,
      ACTIVE,
      FUTURE,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    isCancelled () {
      return this.application.status === GigApplicationStatuses.ARCHIVED && !this.application.offer
    },
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.offer?.job?.stage)
    },
    hasReview () {
      return this.isCompleted && this.offer.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    statuses () {
      const statuses = []
      const offer = { title: 'Offer ', stage: PAST }
      if (!this.application?.offer || this.application.offer.stage === GigOfferStages.NEW) {
        offer.stage = ACTIVE
      }
      statuses.push(offer)

      const confirmation = { title: 'Confirmation & Deposit', stage: FUTURE }
      if (offer.stage === PAST) {
        confirmation.stage = this.offer?.job ? PAST : ACTIVE
      }
      statuses.push(confirmation)

      const inProgress = { title: 'In Progress', stage: FUTURE }
      if (confirmation.stage === PAST) {
        inProgress.stage = this.offer.job?.stage === GigJobStages.IN_PROGRESS ? ACTIVE : PAST
      }
      statuses.push(inProgress)

      const review = { title: 'Review', stage: FUTURE }
      if (inProgress.stage === PAST) {
        review.stage = this.hasReview ? PAST : ACTIVE
      }
      statuses.push(review)
      return [offer, confirmation, inProgress, review]
    },
  },
})
