import { mapActions, mapState } from 'vuex'
import Button from '@/models-ts/ui/Button'
import notifiableRequest from '@/utils/notifiableRequest'
import { WalletGroup } from '@/constants/blockchain'
import {
  WALLET_GROUP_CLOUD,
  WALLET_GROUP_METAMASK,
  WALLET_GROUP_WALLET_CONNECT
} from '@/constants/blockchain/wallet'
import { getWalletGroupName, getWalletIcon } from '@/utils-ts/wallet'

export default {
  model: {
    prop: 'selectedWallet',
  },
  props: {
    selectedWallet: Object,
    totalBalances: Object,
  },
  data () {
    return {
      WALLET_GROUP_CLOUD,
      WALLET_GROUP_WALLET_CONNECT,
      WALLET_GROUP_METAMASK,
      WalletGroup,
      defaultWallet: '',
    }
  },
  computed: {
    ...mapState({
      wallets: state => state.user?.wallets,
      walletDefaultLoading: state => state.user?.walletDefaultLoading,
    }),
    sortedWallets () {
      return [...this.wallets]
    },
  },
  watch: {
    wallets: {
      handler () {
        if (this.wallets && !this.defaultWallet) {
          this.defaultWallet = this.wallets.find(wallet => wallet.is_default)?.address
        }
      },
      immediate: true
    }
  },
  created () {
    if (this.walletDefaultLoading) {
      this.defaultWallet = this.walletDefaultLoading
    }
  },
  beforeUpdate () {
    this.$emit('walletsWillUpdate')
  },
  methods: {
    ...mapActions({
      removeWallet: 'user/removeWallet',
      setDefaultWallet: 'user/setDefaultWallet',
      openModal: 'ui/openModal',
    }),
    getWalletGroupName,
    getWalletIcon,
    getAddressCut (wallet) {
      return `${wallet.address.slice(0, 6)}...${wallet.address.slice(-5)}`
    },
    cannotDeleteWallet (wallet) {
      return wallet.address === this.defaultWallet || this.walletDefaultLoading || wallet.group === WALLET_GROUP_CLOUD
    },
    onOpenMenu (index) {
      const walletEl = this.$refs[`wallet${index}`][0] || this.$refs[`wallet${index}`]
      if (walletEl?.scrollIntoViewIfNeeded) {
        walletEl.scrollIntoViewIfNeeded()
      }
    },
    async onChangeDefault (wallet) {
      if (!wallet.address !== this.defaultWallet && !this.walletDefaultLoading) {
        try {
          this.$emit('input', wallet)
          await notifiableRequest({
            request: async () => {
              this.defaultWallet = wallet.address
              await this.setDefaultWallet(wallet)
            },
            title: 'Wallet',
            successText: `Default wallet address successfully updated.`,
            failureText: (err) => err?.response?.data?.validation?.wallet || 'Error setting default wallet. Please try again.'
          })
        } catch (err) {
          this.defaultWallet = this.wallets.find(wallet => wallet.is_default)?.address
        }
      }
    },
    onAddWalletClick (walletType) {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/WalletAdd/WalletAdd.vue'),
          title: 'Add Wallet',
          props: {
            walletType,
          }
        }
      })
    },
    onRenameWalletClick (wallet) {
      if (this.walletDefaultLoading) return
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/RenameWalletModal/RenameWalletModal.vue'),
          title: 'Rename wallet',
          props: {
            wallet,
          },
        }
      })
    },
    onRemoveWalletClick (wallet) {
      if (this.cannotDeleteWallet(wallet)) return
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Remove a Wallet?',
          // eslint-disable-next-line max-len
          text: `<div style="max-width: 536px">Are you sure you want to delete <b>${wallet.name}</b> wallet <b>${wallet.address}</b>?</div>`,
          buttons: [
            new Button({
              text: 'Confirm',
              classes: 'lx-blue-btn',
              onClick: () => notifiableRequest({
                request: async () => this.removeWallet(wallet),
                title: 'Wallet',
                successText: `Wallet successfully deleted.`,
                failureText: 'Error deleting wallet. Please try again.'
              })
            })
          ]
        }
      })
    }
  }
}
