import Vue from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import Chat from '@/partials/ChatNew/Chat.vue'
import responseMixin from '@/mixins/responseMixin'
import { CHAT, VACANCY_DETAILS, VACANCY_DETAILS_ADMIN_APPLICATIONS } from '@/constants/routes'
import MobileActionsCard from './MobileActionsCard/MobileActionsCard.vue'
import TalentDetailsCard from './TalentDetailsCard/TalentDetailsCard.vue'
import VacancyDetailsCard from './VacancyDetailsCard/VacancyDetailsCard.vue'
import { getApplication, setApplicationMeta } from '@/api/vacancies/applications'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import rolebleMixin from '@/mixins/rolebleMixin'
import ChatRoomPrimaryInfo from '@/partials/ChatRoomPrimaryInfo/ChatRoomPrimaryInfo.vue'
import actionsCardMixin from './actionsCardMixin'
import VacancyApplicationMeta from '@/models-ts/vacancies/VacancyApplicationMeta'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import { getVacancy } from '@/api/vacancies'
import { getWorker } from '@/api/usersAndProfiles/profiles'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import UserInfo from '@/models-ts/user/UserInfo'
import FreelancerDetails from '@/models-ts/user/details/FreelancerDetails'
import { AccountTypes } from '@/constants/user/accountTypes'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, responseMixin, rolebleMixin],
  components: {
    MobileActionsCard,
    Chat,
    VacancyDetailsCard,
    TalentDetailsCard,
    ChatRoomPrimaryInfo,
  },
  data () {
    return {
      initLoaded: false,
      refreshing: false,
      withDetails: false,
      application: null,
      vacancy: null,
      freelancer: null,
      lastUpdatedRoomDate: null,
      offer: null,
    }
  },
  computed: {
    ...mapState({
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      userId: (state: any) => state.user?.id,
      roomId: (state: any) => state.chatNew.openedRoomId,
      initialized: (state: any) => state.chatNew.initialized,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
    }),
    isTemporary () {
      return (this.room && this.room.temporary) || (this.roomId || '').startsWith('tmp_')
    },
    isFreelancerInfo () {
      return this.isTemporary || (this.application && !this.isFreelancer)
    },
    hasActions () {
      return !this.isTemporary && this.application
    },
    vacancyStage () {
      return this.application?.vacancy?.stage || this.vacancy?.stage
    },
    roomUser () {
      if (this.application) {
        return this.isFreelancer
          ? this.application.customer
          : this.application.freelancer
      }
      return new UserInfo({
        id: this.freelancer.id,
        name: this.freelancer.fullName || '',
        type: AccountTypes.USER_ACCOUNT_SIMPLE,
        avatar: this.freelancer.avatar,
        avgReviews: this.freelancer.avgReview,
        reviewsCount: this.freelancer.reviews.length,
        is_customer_verified: false,
      })
    },
    roomEntity () {
      if (!this.initLoaded) return
      if (this.application) {
        return {
          name: this.application.vacancy.name,
          link: {
            name: this.isFreelancer ? VACANCY_DETAILS : VACANCY_DETAILS_ADMIN_APPLICATIONS,
            params: {
              id: this.application.vacancy.id,
              slug: this.application.vacancy.slug
            }
          }
        }
      }
      return {
        name: this.vacancy?.name,
        link: {
          name: VACANCY_DETAILS_ADMIN_APPLICATIONS,
          params: {
            id: this.vacancy.id,
            slug: this.vacancy.slug,
          }
        }
      }
    },
    backLink () {
      return this.roomEntity?.link
    },
    applicationId () {
      const { applicationId } = ChatRoom.parseRoomId(this.roomId)
      return applicationId
    },
    applicationIsArchived () {
      return this.isFreelancer && this.application.meta?.archived
    },
    isMessaged () {
      return this.application.meta?.messaged
    },
  },
  watch: {
    initialized: {
      handler () {
        if (this.initialized) {
          if (!this.roomId) {
            const room = this.$route.query.room
            if (room) {
              this.openRoom(room)
            } else {
              this.$router.replace({ name: CHAT })
            }
          }
        }
      },
      immediate: true,
    },
    roomId: {
      async handler () {
        if (this.roomId) {
          this.initLoaded = false
          if (!this.isTemporary) {
            const application = await getApplication(this.applicationId)
            this.application = VacancyApplication.fromServer(application)
            this.initLoaded = true
          } else if (this.room) {
            this.vacancy = this.$store.getters['vacancyDetails/vacancy']
            if (!this.vacancy) {
              const vacancy = await getVacancy(this.room.entity.id)
              this.vacancy = Vacancy.fromServer(vacancy)
            }
            const freelancerId = this.room.participants.find((user: ChatParticipant) => String(user.userId) !== String(this.userId))?.userId
            if (freelancerId) {
              this.freelancer = FreelancerDetails.fromServer(await getWorker(freelancerId))
            }
            this.initLoaded = true
          } else {
            this.$router.replace({ name: CHAT })
          }
        }
        if (this.room && this.roomId !== this.$route.query.room) {
          this.$router.replace(this.room.chatLink)
        }
      },
      immediate: true
    },
    'room.updatedAt': {
      handler () {
        if (this.lastUpdatedRoomDate) {
          let dateA = Number(new Date(this.lastUpdatedRoomDate))
          let dateB = Number(new Date(this.room.updatedAt))
          if (Math.abs(dateA - dateB) > 1000) {
            this.onRefresh()
          }
        }
        this.lastUpdatedRoomDate = this.room?.updatedAt
      },
      immediate: true
    },
  },
  beforeDestroy () {
    this.closeRoom()
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
      closeRoom: 'chatNew/closeRoom'
    }),
    async onRefresh () {
      this.refreshing = true
      const application = await getApplication(this.applicationId)
      this.application = VacancyApplication.fromServer(application)
      this.refreshing = false
    },
    async onRemoveFromArchivedClick () {
      if (!this.archiving) {
        await this.setArchivedMeta(false, true)
        this.onUpdateApplicationMeta({ ...this.application.meta, archived: false })
      }
    },
    onUpdateApplicationMeta (meta: VacancyApplicationMeta) {
      this.application.meta = meta
    },
    async onMessageSent () {
      if (this.isMetaArchived) {
        await this.setArchivedMeta(false)
        this.onUpdateApplicationMeta({ ...this.application.meta, archived: false })
      }
      if (!this.isMessaged) {
        const meta = { ...this.application.meta, messaged: true }
        await setApplicationMeta(this.application.id, meta)
      }
    },
  },
  metaInfo () {
    return {
      title: this.application?.job?.name || 'Chat'
    }
  }
})
