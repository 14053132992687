import Vue from 'vue'
import messageMixin from '../messageMixin'
import GigOfferChatMessage from '@/models-ts/chat/messages/GigOfferChatMessage'
import { formatUsd } from '@/utils/moneyFormat'
import { TIME_FIXED } from '@/constants/backend/service'
import BigNumber from 'bignumber.js'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import rolebleMixin from '@/mixins/rolebleMixin'
import { mapGetters, mapMutations, mapState } from 'vuex'
import { RootState } from '@/store'
import { applyOffer, declineOffer } from '@/api/gig'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import sleep from '@/utils/sleep'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin, snackMixin],
  props: {
    message: GigOfferChatMessage,
  },
  computed: {
    ...mapState<RootState>({
      depositingOffer: (state: RootState) => state.chatNew.depositingOffer,
      acceptingOffer: (state: RootState) => state.chatNew.acceptingOffer,
      decliningOffer: (state: RootState) => state.chatNew.decliningOffer,
    }),
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    depositing () {
      return this.depositingOffer && this.depositingOffer === this.message.offerId
    },
    accepting () {
      return this.acceptingOffer && this.acceptingOffer === this.message.offerId
    },
    declining () {
      return this.decliningOffer && this.decliningOffer === this.message.offerId
    },
    isNew () {
      return this.message.stage === GigOfferStages.NEW
    },
    isDeclined () {
      return [GigOfferStages.DECLINED_BY_FREELANCER, GigOfferStages.DECLINED_BY_CUSTOMER].includes(this.message.stage)
    },
    whoDeclined () {
      if (this.isFreelancer) {
        return this.message.stage === GigOfferStages.DECLINED_BY_FREELANCER ? 'YOU' : 'CUSTOMER'
      } else {
        return this.message.stage === GigOfferStages.DECLINED_BY_CUSTOMER ? 'YOU' : 'TALENT'
      }
    },
    isAccepted () {
      return [GigOfferStages.ACCEPTED_BY_CUSTOMER, GigOfferStages.ACCEPTED_BY_FREELANCER].includes(this.message.stage)
    },
    isEscrowed () {
      return this.isAccepted && this.message.isEscrowed
    },
    isTimeFixed () {
      return this.message.timeType === TIME_FIXED
    },
    deadline () {
      if (this.isTimeFixed) {
        return `${this.message.deadlineInDays} days work`
      }
      const hour = this.message.timeValue === 1 ? 'hour' : 'hours'
      return `${this.message.timeValue} ${hour} work`
    },
    budget () {
      const budget = formatUsd(this.message.budget!)
      return this.isTimeFixed ? budget : `${budget}/hour`
    },
    totalBudget () {
      let total = new BigNumber(this.message.budget)
      if (!this.isTimeFixed) {
        total = total.multipliedBy(this.message.timeValue)
      }
      return formatUsd(total)
    },
    waitForDeposit () {
      return this.isAccepted && !this.message.isEscrowed
    },
  },
  methods: {
    ...mapMutations({
      setAcceptingOffer: 'chatNew/setAcceptingOffer',
      setDecliningOffer: 'chatNew/setDecliningOffer',
    }),
    async onEditOfferClick () {
      this.$emit('edit', this.message.offerId)
    },
    onDepositClick () {
      this.$emit('deposit')
    },
    async onCancelOfferClick () {
      try {
        this.setDecliningOffer(this.message.offerId)
        await declineOffer({ id: this.message.offerId, comment: 'Not specified' })
        this.$emit('cancel', this.message.offerId)
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error declining offer. Please try again.',
        })
      } finally {
        this.setDecliningOffer(null)
      }
    },
    async onAcceptClick () {
      try {
        googleAnalyticsV2.send({ event: 'gig_accept_click' })
        this.setAcceptingOffer(this.message.offerId)
        await notifiableRequest({
          request: async () => {
            await applyOffer(this.message.offerId)
            await sleep(this.backendConfig['CREATE_CONTRACT_BACKEND_FLOW_TIMEOUT'] || 2000)
          },
          title: 'Offer accepted',
          successText: `You have an offer for this Gig.`,
          failureText: 'Error accepting offer. Please try again.'
        })
        this.$emit('accept', this.message.offerId)
      } finally {
        this.setAcceptingOffer(null)
      }
    },
  },
})
