import { BACKEND_PRIVATE } from './base'

export async function getSubscriptions () {
  return BACKEND_PRIVATE.get('/me/subscriptions/list')
}

export async function subscribe (category) {
  return BACKEND_PRIVATE.post('/me/subscriptions/subscribe', { payload: { category } })
}

export async function unsubscribe (category) {
  return BACKEND_PRIVATE.post('/me/subscriptions/unsubscribe', { payload: { category } })
}
