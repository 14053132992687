import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import { JOB_DETAILS_ADMIN_APPLICATIONS, JOB_CHAT } from '@/constants/routes'
import {
  STAGE_NEW,
  STAGE_IN_PROGRESS,
  STAGE_STARTED,
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DISPUTED,
  STAGE_DEADLINE_OVERDUE,
} from '@/constants/job/jobStages'
import { convertToUsd } from '@/utils-ts/currencies'
import { OFFER_REVIEW } from '@/constants/job/jobOfferStatuses'
import { BLOCKCHAIN_OPTIONS, Blockchain, BlockchainNames } from '@/constants/blockchain'
import { DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN } from '@/constants/utils/date'
import { formatDate, addDays } from '@/utils/date'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { REFUND_DELAY_DAYS } from '@/constants/job/jobCommon'
import ratesMixin from '@/mixins/ratesMixin'
import Rate from '@/models-ts/Rate'
import { getTxLink } from '@/utils-ts/transaction'
import { ContractVersion } from '@/constants/contracts'

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  components: {
    UserInfo,
  },
  props: {
    job: MyCustomerJobListItem,
  },
  data () {
    return {
      JOB_DETAILS_ADMIN_APPLICATIONS,
      BlockchainNames,
      DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN,
    }
  },
  computed: {
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
      room: 'chatNew/getOpenedRoom',
    }),
    isLocked () {
      return this.room && !this.room.isUnlocked
    },
    chatRoomId () {
      if (this.job.job_application_id && this.job.freelancer_id) {
        return ChatRoom.generateRoomId({
          type: RoomTypes.JOB,
          applicationId: this.job.job_application_id,
          freelancerId: this.job.freelancer_id,
          customerId: this.job.customer_id,
        })
      }
    },
    chatLink () {
      return {
        name: JOB_CHAT,
        params: { id: this.job.id, slug: this.job.slug },
        query: { room: this.chatRoomId }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    hasChat () {
      return this.job.job_application_id
    },
    hasLeaveReview () {
      return this.isCompleted && !this.job.hasReview
    },
    isStarted () {
      return ![STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    isInProgress () {
      return [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER].includes(this.job.stage)
    },
    isArchived () {
      return this.job.stage !== STAGE_NEW && this.job.offerStage === OFFER_REVIEW
    },
    isCompleted () {
      return [STAGE_COMPLETED, STAGE_DISPUTED, STAGE_DEADLINE_OVERDUE].includes(this.job.stage)
    },
    hasPayJob () {
      return this.job.stage === STAGE_IN_PROGRESS
    },
    primaryBudget () {
      const budget = this.job.getBudgetFormat()
      return this.isStarted ? `${budget} ${this.job.currencyModel?.name || ''}` : `$${budget}`
    },
    secondaryBudget () {
      if (this.job.escrowBalance && this.job.currencyModel) {
        return `$${convertToUsd({ value: this.job.escrowBalance, currency: this.job.currencyModel, rates: this.rates })}`
      }
    },
    transactionLink () {
      if (this.isInProgress) {
        return this.job.txid_created && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_created })
      }
      return this.job.txid_completed && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_completed })
    },
    transactionEndLink () {
      return this.job.txid_completed && getTxLink({ blockchain: this.job.blockchain, tx: this.job.txid_completed })
    },
    deadline () {
      return this.job.deadline && formatDate(this.job.deadline, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    refundDate () {
      return this.job.deadline &&
        formatDate(addDays(this.job.deadline, REFUND_DELAY_DAYS), DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    completedDate () {
      return this.job.completedAt && formatDate(this.job.completedAt, DATE_TIME_FORMAT_BY_MERIDIEM_HUMAN)
    },
    user () {
      return this.job.freelancer
    },
    goToLink () {
      return { name: JOB_DETAILS_ADMIN_APPLICATIONS, params: { id: this.job.id, slug: this.job.slug } }
    },
    usd () {
      return convertToUsd({ value: this.job.budget, currency: this.job.currencyModel, rates: this.rates as Array<Rate> })
    },
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
      signPayToFreelancerV1: 'jobDetails/signPayToFreelancerV1',
      signPayToFreelancerV2: 'jobDetails/signPayToFreelancerV2',
      openModal: 'ui/openModal',
    }),
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    getBlockchainLabel (blockchain: Blockchain) {
      return BLOCKCHAIN_OPTIONS.find(bl => bl.value === blockchain)?.name || ''
    },
    onPayToTalentClick () {
      if (this.job.contract_version === ContractVersion.v1 || !this.job.contract_version) {
        this.signPayToFreelancerV1({
          job: this.job,
          successSign: () => {
            this.$emit('resetJobs')
          }
        })
      } else if (this.job.contract_version === ContractVersion.v2) {
        this.signPayToFreelancerV2({
          job: this.job,
          successSign: () => {
            this.$emit('resetJobs')
          }
        })
      }
    },
    onLeaveReviewClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/LeaveFeedback/LeaveFeedback.vue'),
          title: 'Leave a review',
          props: {
            fromFreelancer: false,
            jobId: this.job.id,
            name: this.job.name,
            profile: this.job.freelancer,
            onSuccess: () => {
              this.$emit('resetJobs')
            },
          }
        }
      })
    },
  },
})
