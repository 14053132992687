import Vue from 'vue'
import FileChatMessage from '@/models-ts/chat/messages/FileChatMessage'
import { formatDate } from '@/utils/date'
import { humanizeBytes } from '@/utils-ts/strings'
import messageMixin from '../messageMixin'

export default Vue.extend({
  mixins: [messageMixin],
  props: {
    message: FileChatMessage,
  },
  computed: {
    isSending () {
      return this.message.sending
    },
    size () {
      return humanizeBytes(this.message.body.size)
    },
    date () {
      return formatDate(this.message.updatedAt, 'MMM DD, HH:mm')
    },
  },
})
