import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import actionsCardMixin from '../actionsCardMixin'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import GigApplication from '@/models-ts/gigs/GigApplication'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, rolebleMixin],
  props: {
    offer: GigOfferListItem,
    application: GigApplication,
  },
  computed: {
    hasBtns () {
      return this.hasGigWasDone ||
        this.hasCreateOffer ||
        this.hasGigDone ||
        this.hasCreateDispute ||
        this.hasStartJob ||
        this.hasPayJob ||
        this.hasRefund ||
        this.hasLeaveReview ||
        this.hasReviewed
    },
  },
})
