import cloneDeep from 'lodash/cloneDeep'
import maxBy from 'lodash/maxBy'
import unescape from 'lodash/unescape'
import UserInfo, { UserInfoFromServer } from '@/models-ts/user/UserInfo'
import GigOffer, { GigOfferFromServer } from './GigOffer'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'
import { parseSlug } from '@/utils-ts/parser'
import { Blockchain } from '@/constants/blockchain'
import { GigApplicationStatuses } from '@/constants/gig/gigApplicationStatuses'
import { getDateFromString } from '@/utils/date'
import { ModerationStages } from '@/constants/backend/ModerationStages'

export default class GigApplication {
  id: number
  gigId: number
  deadline?: number
  budget?: string
  status: GigApplicationStatuses
  moderationStage: ModerationStages
  offer?: GigOffer | null
  isRead: boolean
  gig: {
    id: number
    slug: string
    name: string
    rate: string
    timeType: GigTimeTypes
    timeValue: string
    currencies: Array<{
      blockchain: Blockchain
      currency: number
    }>
  }
  freelancer: UserInfo | null
  customer: UserInfo | null

  constructor (data: Partial<GigApplication>) {
    Object.assign(this, cloneDeep(data))
  }

  static fromServer (data: GigApplicationFromServer) {
    const offer = maxBy((data.relations?.Offers || [])
      .filter(offer => [GigOfferStages.NEW, GigOfferStages.ACCEPTED_BY_CUSTOMER, GigOfferStages.ESCROWED].includes(offer.stage))
      .map(offer => ({
        ...offer,
        createdDate: +(getDateFromString(offer.created_at)),
      })), 'createdDate')
    return new GigApplication({
      id: data.id,
      status: data.status,
      moderationStage: data.moderation_stage || ModerationStages.PASSED,
      gigId: data.gig_id,
      deadline: data.deadline,
      budget: data.budget?.amount,
      offer: offer ? GigOffer.fromServer(offer) : null,
      isRead: Boolean(data.is_read),
      gig: {
        id: data.relations?.Gig.id || 0,
        slug: parseSlug(data.relations?.Gig?.slug),
        name: unescape(data.relations?.Gig?.name),
        timeType: Number(data.relations?.Gig?.time_type) || GigTimeTypes.FIXED,
        timeValue: data.relations?.Gig?.time_value || '',
        rate: data.relations?.Gig?.rate || '',
        currencies: data.relations?.Gig?.relations?.Currency || [],
      },
      customer: data.relations?.Customer ? UserInfo.fromServer(data.relations?.Customer) : null,
      freelancer: data.relations?.Freelancer ? UserInfo.fromServer(data.relations?.Freelancer) : null,
    })
  }
}

export type GigApplicationFromServer = {
  id: number
  status: GigApplicationStatuses
  moderation_stage?: ModerationStages | null
  gig_id: number
  deadline?: number
  is_read: number
  budget?: {
    amount: string
  }
  relations?: {
    Offers?: Array<GigOfferFromServer>
    Gig: {
      id: number
      slug: string
      name: string
      time_type: GigTimeTypes
      time_value: string
      rate: string
      relations: {
        Currency: Array<{
          blockchain: Blockchain
          currency: number
        }>
      }
    }
    Freelancer?: UserInfoFromServer
    Customer?: UserInfoFromServer
  }
}
