class Transak {
  /** List of currencies supported by Transak */
  static CURRENCY_LIST = ['ETH', 'DAI', 'USDT', 'USDC', 'MATIC', 'BNB', 'BUSD']

  /** Trasnak Query Parameters */
  parameters = {
    apiKey: process.env.VUE_APP_TRANSAK_API_KEY,
    environment: process.env.VUE_APP_TRANSAK_ENVIRONMENT,
    networks: 'ethereum,polygon,bsc',
    disableWalletAddressForm: true,
    themeColor: '#4737FF',
    fiatCurrency: '',
    hostURL: window.location.origin,
    widgetHeight: '550px',
  }

  /** TransakSdk instance */
  instance

  /**
   * @param {Array} cryptoCurrencyList options for currency select, @default Transak.CURRENCY_LIST
   * @param {String} defaultCryptoCurrency value of currency select, on of cryptoCurrencyList @default Transak.CURRENCY_LIST[0]
   * @param {String} walletAddress
   * @param {String} email to identity user and his order
   */
  constructor ({
    cryptoCurrencyList = Transak.CURRENCY_LIST,
    defaultCryptoCurrency = Transak.CURRENCY_LIST[0],
    email,
    walletAddress,
  } = {}) {
    Object.assign(this.parameters, {
      cryptoCurrencyList: cryptoCurrencyList.join(','),
      defaultCryptoCurrency,
      walletAddress,
      email,
    })
  }

  /** open transak modal */
  async open () {
    const TransakSDK = (await import(/* webpackChunkName: "transak-sdk" */ '@transak/transak-sdk')).default
    const windowWidth = Math.max((document.documentElement.clientWidth || 0), window.innerWidth)
    this.instance = new TransakSDK({ ...this.parameters, width: Math.min(windowWidth, 450) })
    this.instance.init()
  }
}

export default Transak
