import Vue from 'vue'
import { mapGetters } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import actionsCardMixin from '../actionsCardMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import sleep from '@/utils/sleep'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import { applyOffer, declineOffer } from '@/api/jobs/offers'
import snackMixin from '@/mixins/snackMixin'
import JobApplication from '@/models-ts/job/JobApplication'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, rolebleMixin, snackMixin],
  props: {
    application: JobApplication,
    offer: Object
  },
  data () {
    return {
      canceling: false,
      declining: false,
      accepting: false,
      donning: false,
    }
  },
  computed: {
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    hasAcceptOffer () {
      return this.application?.offer?.stage === JobOfferStages.NEW
    },
  },
  methods: {
    async onCancelOfferClick () {
      try {
        this.canceling = true
        await declineOffer({ id: this.application.offer.id, comment: 'Not specified' })
        // await notifiableRequest({
        //   request: () => declineOffer({ id: this.application.offer.id, comment: 'Not specified' }),
        //   title: 'Decline offer',
        //   successText: 'Offer has been declined',
        //   failureText: 'Error declining offer. Please try again.'
        // })
        this.$emit('cancel', this.application.offer.id)
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error declining offer. Please try again.',
        })
      } finally {
        this.canceling = false
      }
    },
    async onAcceptClick () {
      try {
        this.accepting = true
        await notifiableRequest({
          request: async () => {
            await applyOffer(this.application.offer.id)
            await sleep(this.backendConfig['CREATE_CONTRACT_BACKEND_FLOW_TIMEOUT'] || 2000)
          },
          title: 'Offer accepted',
          successText: `You have accepted an offer for this Job.`,
          failureText: 'Error accepting offer. Please try again.'
        })
        this.starting = true
        this.emitRefresh()
        this.startJob()
      } finally {
        this.accepting = false
      }
    },
  }
})
