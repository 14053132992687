import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import responseMixin from '@/mixins/responseMixin'
import { DATE_TIME_FORMAT_BY_MERIDIEM } from '@/constants/utils/date'
import { SERVICE_CHAT, SERVICE_DETAILS, SERVICE_MY_COMPLETED } from '@/constants/routes'
import { convertToUsd, getCurrency } from '@/utils-ts/currencies'
import { getTxLink } from '@/utils-ts/transaction'
import snackMixin from '@/mixins/snackMixin'
import { Blockchain, BlockchainNames } from '@/constants/blockchain'
import { addDays, addSeconds, convertToLocal, formatDate, isBefore } from '@/utils/date'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Currency from '@/models-ts/Currency'
import { CURRENCY_FIELD_NAME } from '@/constants/currency'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import { formatCurrency } from '@/utils/moneyFormat'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import GigJobStatusBadge from '@/partials/StatusBadges/GigJobStatusBadge/GigJobStatusBadge.vue'
import { DISPUTE_GIG_TYPE } from '@/constants/components/CreateDispute'

const EXTRA_DAYS = 2

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    GigJobStatusBadge,
    UserInfo,
  },
  props: {
    gig: GigOfferListItem,
    isFreelancer: Boolean,
  },
  data () {
    return {
      SERVICE_DETAILS,
    }
  },
  computed: {
    ...mapState({
      payToFreelancerLoading: (state: any) => state.gig.payToFreelancerLoading,
      returnFundsToCustomerLoading: (state: any) => state.gig.returnFundsToCustomerLoading,
      rates: (state: any) => state.rates.rates.value || [],
      isBalancesLoaded: (state: any) => state.balances.balances.isLoaded,
      chatInitialized: (state: any) => state.chatNew?.initialized,
      chatConnected: (state: any) => state.chatNew?.connected,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    hasGig () {
      return !this.gig.isRemoved && (this.isFreelancer || this.gig.gigStatus === GigStatuses.PUBLISHED)
    },
    user () {
      return this.isFreelancer ? this.gig.customerProfile : this.gig.freelancerProfile
    },
    hasChat () {
      return this.gig.applicationId && this.chatInitialized
    },
    hasPayBtn () {
      return !this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS
    },
    hasRefundBtn () {
      return !this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS && this.refundHasCome
    },
    hasDisputeBtn () {
      return this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS && this.deadlineHasCome
    },
    hasPayContractLoading () {
      return !!this.payToFreelancerLoading[this.gig.sc_id]
    },
    hasRefundContractLoading () {
      return !!this.returnFundsToCustomerLoading[this.gig.sc_id]
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.GIG,
        applicationId: this.gig.applicationId,
        freelancerId: this.gig.freelancerId,
        customerId: this.gig.customerId,
      })
    },
    chatLink () {
      return {
        name: SERVICE_CHAT,
        params: { id: this.gig.gig_id, slug: this.gig.slug },
        query: { room: this.chatRoomId }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    deadline () {
      return formatDate(addSeconds(convertToLocal(this.gig.job.created_at), this.gig.deadline), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    refundDate () {
      return formatDate(addDays(this.deadline, EXTRA_DAYS), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    deadlineHasCome () {
      return isBefore(this.deadline, Date.now())
    },
    refundHasCome () {
      return isBefore(this.refundDate, Date.now())
    },
    transactionStartLink () {
      return getTxLink({ blockchain: this.gig.blockchain, tx: this.gig.job.txid_created })
    },
    transactionEndLink () {
      return getTxLink({ blockchain: this.gig.blockchain, tx: this.gig.job.txid_completed })
    },
    deadlineFormatted () {
      if (!this.gig.deadline) {
        return 'TBD'
      }
      const days = new BigNumber(this.gig.deadline).dividedBy(86400)
      return `${days} day${days.isGreaterThan(1) ? 's' : ''}`
    },
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.gig.job?.stage)
    },
    blockchainName () {
      return BlockchainNames[this.gig.blockchain as Blockchain]
    },
    currency () {
      return getCurrency({ blockchain: this.gig.blockchain, value: this.gig.currency, field: CURRENCY_FIELD_NAME })
    },
    escrowBalance () {
      return formatCurrency(this.gig.job.escrow_balance, { currency: this.currency })
    },
    escrowBalanceInUsd () {
      return this.rates.length
        ? convertToUsd({ value: this.gig.job.escrow_balance, currency: this.currency, rates: this.rates, format: true })
        : null
    }
  },
  methods: {
    ...mapActions({
      signCreateContractV2: 'gig/signCreateContractV2',
      signPayToFreelancerV1: 'gig/signPayToFreelancerV1',
      signPayToFreelancerV2: 'gig/signPayToFreelancerV2',
      returnFundsToCustomerV1: 'gig/returnFundsToCustomerV1',
      refundToCustomerByCustomerV2: 'gig/refundToCustomerByCustomerV2',
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
    }),
    refreshList () {
      this.$emit('refreshList')
    },
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    onCreateDisputeClick () {
      if (this.deadlineHasCome) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CreateDispute/CreateDispute.vue'),
            title: 'Open a dispute',
            props: {
              disputeData: {
                type: DISPUTE_GIG_TYPE,
                id: this.gig.job.id,
                name: this.gig.name,
                blockchain: this.gig.blockchain,
              },
              onSuccess: () => {
                this.refreshList()
              }
            }
          }
        })
      } else {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `You can only initiate a dispute once the deadline has been reached.`,
        })
      }
    },
    onPayToTalentClick () {
      if (this.gig.contractVersion === 1 || !this.gig.contractVersion) {
        this.signPayToFreelancerV1({
          gig: this.gig,
          successSign: (
            { tx, walletName, currency, blockchain }:
            { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
          ) => {
            this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
            this.$router.push({ name: SERVICE_MY_COMPLETED })
          }
        })
      } else if (this.gig.contractVersion === 2) {
        this.signPayToFreelancerV2({
          gig: this.gig,
          successSign: (
            { tx, walletName, currency, blockchain }:
            { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
          ) => {
            this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
            this.$router.push({ name: SERVICE_MY_COMPLETED })
          }
        })
      }
    },
    onRefundClick () {
      const refundMethod = this.gig.contractVersion === 1 || !this.gig.contractVersion
        ? 'returnFundsToCustomerV1'
        : 'refundToCustomerByCustomerV2'
      if (process.env.VUE_APP_MODE === 'dev') {
        this[refundMethod]({
          gig: this.gig,
          successSign: () => {
            this.$router.push({ name: SERVICE_MY_COMPLETED })
          }
        })
      } else {
        if (this.refundHasCome) {
          this[refundMethod]({
            gig: this.gig,
            successSign: () => {
              this.$router.push({ name: SERVICE_MY_COMPLETED })
            }
          })
        } else {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `You can only initiate a refund 48 hours after the deadline has been reached.`,
          })
        }
      }
    },
    sendSuccessAnalytics (tx: string, walletName: string, currency: Currency, blockchain: Blockchain) {
      googleAnalyticsV2.send({
        event: 'offer-gig-payment',
        'event-content': tx,
        offer_id: this.gig.id,
        gig_id: this.gig.gig_id,
        block_type: BlockchainNames[blockchain],
        currency: currency.name,
        sum: this.gig.job.escrow_balance
          ? convertToUsd({
            value: this.gig.job.escrow_balance,
            currency,
            rates: this.rates,
          })
          : '0',
        wallet_integration_type: walletName,
      })
    },
  }
})
