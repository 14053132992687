import { render, staticRenderFns } from "./FreelancerCompletedTab.htm?vue&type=template&id=5055cd40&"
import script from "./FreelancerCompletedTab.ts?vue&type=script&lang=js&"
export * from "./FreelancerCompletedTab.ts?vue&type=script&lang=js&"
import style0 from "../MyGigsTab.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports