import Vue from 'vue'
import { mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { DASHBOARD, PROFILE_SETTINGS } from '@/constants/routes'
import { getMyCustomerProfile } from '@/api/users'
import ActionsSection from '../ActionsSection/ActionsSection.vue'
import GeneralInfo from './GeneralInfo/GeneralInfo.vue'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import GeneralInfoModel from '@/models-ts/user/profiles/cutomer/GeneralInfo'
import AvatarSection from '../AvatarSection/AvatarSection.vue'
import CustomerInfo from './CustomerInfo/CustomerInfo.vue'
import SocialMedia from './SocialMediaSection/SocialMediaSection.vue'
import IndividualInfo from '@/models-ts/user/profiles/cutomer/IndividualInfo'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    AvatarSection,
    ActionsSection,
    CustomerInfo,
    GeneralInfo,
    SocialMedia,
  },
  data () {
    return {
      DASHBOARD,
      PROFILE_SETTINGS,
      profile: null,
    }
  },
  computed: {
    ...mapState('user', {
      userInfoLoaded: (state: any) => state.profile.isLoaded,
      userInfoLoading: (state: any) => state.profile.isLoading,
    }),
  },
  watch: {
    userInfoLoaded: {
      async handler () {
        if (this.activeRole) {
          this.profile = MyCustomerProfile.fromServer(await getMyCustomerProfile())
        }
      },
      immediate: true,
    }
  },
  methods: {
    onGeneralInfoUpdate (info: GeneralInfoModel) {
      this.profile.generalInfo = info
    },
    onCustomerInfoUpdate (info: MyCustomerProfile) {
      this.profile = info
    },
  }
})
