import BigNumber from 'bignumber.js'
import { Blockchain } from '@/constants/blockchain'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { getCurrency } from '@/utils/currency'
import AbstractTransaction from './AbstractTransaction'

class PayToFreelancerTransaction extends AbstractTransaction {
  static fromServer (data, userId) {
    if (data) {
      const job = data.relations.Job || data.relations.GigJob
      const freelancerId = job?.freelancer_id
      if (freelancerId === userId) {
        const wallet = job.freelancer_wallet
        const currency = getCurrency({
          blockchain: +(job.blockchain || Blockchain.Ethereum),
          value: +job.currency,
          field: CURRENCY_FIELD_BACKEND_ID
        })
        let amount = new BigNumber(job.escrow_balance).dividedBy(currency.baseUnits)
        return new PayToFreelancerTransaction({
          ...data,
          icon: 'deposit.png',
          title: 'Contract Payment',
          wallet: wallet.toLowerCase(),
          amount,
          currency,
        })
      }
    }
  }
}

export default PayToFreelancerTransaction
