import { render, staticRenderFns } from "./GigMarkedDoneChatMessage.htm?vue&type=template&id=38aa7048&scoped=true&"
import script from "./GigMarkedDoneChatMessage.ts?vue&type=script&lang=js&"
export * from "./GigMarkedDoneChatMessage.ts?vue&type=script&lang=js&"
import style0 from "./GigMarkedDoneChatMessage.scss?vue&type=style&index=0&id=38aa7048&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38aa7048",
  null
  
)

export default component.exports