import Vue from 'vue'
import { POST_A_JOB, SERVICES } from '@/constants/routes'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      POST_A_JOB,
      SERVICES,
    }
  },
  methods: {
    onClickPostJob () {
      googleAnalyticsV2.send({
        event: 'post-job-button',
      })
    },
  }
})
