import Vue, { PropType } from 'vue'
import { mapActions, mapMutations, mapGetters } from 'vuex'
import { getHumanDate } from '@/utils/date'
import messageMixin from '../ThreadTab/MessageViewer/messageMixin'
import { ChatMessage } from '@/models-ts/chat/messages/ChatMessageType'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin],
  props: {
    message: Object as PropType<ChatMessage>,
    checkSide: Boolean,
  },
  computed: {
    ...mapGetters({
      getParticipant: 'chatNew/getParticipant',
    }),
    user () {
      return this.getParticipant(this.message.sender)
    },
    name () {
      return this.isOwn ? 'You' : this.user.name
    },
    date () {
      return getHumanDate(this.message.updatedAt)
    },
  },
})
