import Vue from 'vue'
import GeneralInfo from '@/models-ts/user/profiles/cutomer/GeneralInfo'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import { USER_TYPE_CUSTOMER_COMPANY, USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'
import IndividualInfo from './IndividualInfo/IndividualInfo.vue'
import CompanyInfo from './CompanyInfo/CompanyInfo.vue'

const editableFieldsIndividual = ['description', 'website', 'skills', 'city', 'payments']
const editableFieldsCompany = ['description', 'website', 'skills', 'country', 'payments', 'employees', 'logo']
const editableField = [...editableFieldsIndividual, ...editableFieldsCompany]

export default Vue.extend<any, any, any, any>({
  components: {
    IndividualInfo,
    CompanyInfo,
  },
  props: {
    profile: MyCustomerProfile,
  },
  data () {
    return {
      editMode: false,
      saving: false,
      role: null,
      roleSelected: false,
      roleOptions: [
        { value: USER_TYPE_CUSTOMER_PERSON, label: 'Individual customer' },
        { value: USER_TYPE_CUSTOMER_COMPANY, label: 'Company' },
      ],
      form: new GeneralInfo({}),
      USER_TYPE_CUSTOMER_PERSON,
    }
  },
  watch: {
    profile: {
      handler () {
        this.initForm()
      },
      immediate: true
    }
  },
  mounted () {
    this.setFocusByAnchor()
    const selector = (this.$route.hash || '').slice(1)
    if (this.$route.hash && editableField.includes(selector)) {
      if (!this.role) {
        const role = editableFieldsIndividual.includes(selector) ? USER_TYPE_CUSTOMER_PERSON : USER_TYPE_CUSTOMER_COMPANY
        this.role = this.roleOptions.find((opt: any) => opt.value === role)
        this.roleSelected = true
      }
      this.editMode = true
      this.$nextTick(() => this.setFocusByAnchor())
    }
  },
  methods: {
    initForm () {
      if (this.profile) {
        if (this.profile.roleWasSet) {
          this.role = this.roleOptions.find((opt: any) => opt.value === this.profile.type)
          this.roleSelected = true
        }
        this.form = new GeneralInfo(this.profile.generalInfo)
      }
    },
    setFocusByAnchor () {
      if (this.$route.hash) {
        const el = this.$el.querySelector(this.$route.hash)
        if (el) {
          const focusableEl = el.querySelector('input, textarea')
          if (focusableEl) {
            this.$nextTick(() => {
              focusableEl.focus()
            })
          }
        }
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    onFirstRoleSelect () {
      this.roleSelected = true
      this.editMode = true
    },
    onEditClick () {
      this.editMode = true
    },
    onCancel () {
      this.editMode = false
      if (!this.profile.roleWasSet) {
        this.roleSelected = false
        this.role = null
      }
    },
    onInfoUpdate (info: MyCustomerProfile) {
      this.$emit('update', info)
      this.editMode = false
    },
  }
})
