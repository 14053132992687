import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { SETTINGS } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  data () {
    return {
      viewAll: false,
      SETTINGS,
    }
  },
  computed: {
    ...mapState({
      profileInfo: (state: any) => state.dashboard.profileInfo.value,
      profileInfoIsLoading: (state: any) => state.dashboard.profileInfo.isLoading,
      profileInfoIsLoaded: (state: any) => state.dashboard.profileInfo.isLoaded,
    }),
    accountTypes () {
      return {
        isClient: this.isCustomer,
        isClientPrivatePerson: this.isPrivateCustomer,
      }
    },
    profileFilled () {
      if (this.profileInfoIsLoaded) {
        const filled = this.profileInfo.unfilled.reduce((total: number, info: any) => total - info.getProfit(this.accountTypes), 100)
        return `${Math.max(filled, 0)}%`
      }
      return 0
    },
    fillingsOptions () {
      if (this.profileInfo) {
        return [...this.profileInfo.unfilled].sort((a, b) => b.getProfit(this.accountTypes) - a.getProfit(this.accountTypes))
      }
      return []
    },
  },
  watch: {
    activeRole: {
      handler () {
        if (this.activeRole) {
          this.getProfileInfo()
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      getProfileInfo: 'dashboard/getProfileInfo',
    }),
  },
})
