import Vue from 'vue'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import rolebleMixin from '@/mixins/rolebleMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  props: {
    offer: GigOfferListItem,
  },
  computed: {
    inProgress () {
      return Boolean(this.offer?.job)
    },
  },
})
