export const NULL_LOCK = '0'
export const HOURS1 = '1'
export const DAYS30 = '2'
export const DAYS180 = '3'
export const DAYS365 = '4'
export const DAYS730 = '5'

export const LOCK_MULTIPLIERS = {
  [NULL_LOCK]: 0,
  [HOURS1]: 1,
  [DAYS30]: 1.2,
  [DAYS180]: 1.3,
  [DAYS365]: 1.5,
  [DAYS730]: 2,
}
