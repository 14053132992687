import { ModerationStages } from '@/constants/backend/ModerationStages'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import Vue, { PropType } from 'vue'
import Badges from './Badges'
import { Badge } from '../Badge'

export function getBadge (stage: Stages, moderationStage: ModerationStages, status: Statuses) {
  if (stage === Stages.ARCHIVED || stage === Stages.COMPLETED) return Badges.Archived
  if (moderationStage === ModerationStages.FAILED) return Badges.Declined
  if (status === Statuses.DRAFT) return Badges.Draft
  if (moderationStage === ModerationStages.MANUAL) return Badges.Moderation
  if (status === Statuses.PUBLISHED && moderationStage === ModerationStages.PASSED) return Badges.Published
}

export default Vue.extend({
  props: {
    moderationStage: Number as PropType<ModerationStages>,
    status: Number as PropType<Statuses>,
    stage: Number as PropType<Stages>,
  },
  computed: {
    badge (): Badge | undefined {
      return getBadge(this.stage, this.moderationStage, this.status)
    },
  },
})
