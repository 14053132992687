import Vue from 'vue'
import WorkExperience from '@/models-ts/user/WorkExperience'
import { formatDate } from '@/utils/date'
import isNotEmptyExperience from '../isNotEmptyExperience'

export default Vue.extend<any, any, any, any>({
  props: {
    experience: WorkExperience,
    editMode: Boolean,
  },
  data () {
    return {
      form: new WorkExperience({}),
    }
  },
  computed: {
    to: {
      get () {
        return this.form.isNow() ? '' : this.form.to
      },
      set (v: Date) {
        this.form.to = v
      }
    },
    range () {
      return `${formatDate(this.form.from, 'MMMM YYYY')} – ${this.form.isNow() ? 'Current time' : formatDate(this.form.to, 'MMMM YYYY')}`
    },
  },
  watch: {
    experience: {
      handler () {
        if (this.experience) {
          this.form = new WorkExperience(this.experience)
        }
      },
      immediate: true
    }
  },
  methods: {
    async validate () {
      let isValid = true
      this.errors.clear()
      if (isNotEmptyExperience(this.form)) {
        isValid = await this.$validator.validateScopes('experience')
        if (!this.form.from) {
          this.errors.add({
            field: 'form.from',
            msg: 'Required field'
          })
        }
        if (!this.form.to) {
          this.errors.add({
            field: 'form.to',
            msg: 'Required field'
          })
        }
      }
      return isValid && !this.$validator.errors.count()
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    onNowClick () {
      this.form.to = this.form.isNow() ? null : new Date(0)
      this.emitUpdate()
    },
    emitUpdate () {
      this.$emit('update', this.form)
    },
  },
})
