import { render, staticRenderFns } from "./CustomerDraftTab.htm?vue&type=template&id=f3305890&scoped=true&"
import script from "./CustomerDraftTab.ts?vue&type=script&lang=js&"
export * from "./CustomerDraftTab.ts?vue&type=script&lang=js&"
import style0 from "./CustomerDraftTab.scss?vue&type=style&index=0&id=f3305890&lang=scss&scoped=true&"
import style1 from "../../MyJobsTab.scss?vue&type=style&index=1&id=f3305890&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3305890",
  null
  
)

export default component.exports