import FileChatMessage from '@/models-ts/chat/messages/FileChatMessage'
import { humanizeBytes } from '@/utils-ts/strings'
import { formatDate } from '@/utils/date'
import Vue from 'vue'

export default Vue.extend<any, any, any, any>({
  props: {
    message: FileChatMessage,
  },
  data () {
    return {
    }
  },
  computed: {
    size () {
      return humanizeBytes(this.message.body.size)
    },
    date () {
      return formatDate(this.message.updatedAt, 'MMM DD, HH:mm')
    },
  },
  watch: {
  },
  created () {
  },
  methods: {
  },
})
