import get from 'lodash/get'
import { mapActions, mapMutations } from 'vuex'
import Job from '@/models-ts/job/Job'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { SnackTypes } from '@/constants/SnackTypes'
import {
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_IN_PROGRESS
} from '@/constants/job/jobStages'
import { STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { getJob, publishJob } from '@/api/jobs/job'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'
import { validateJobPublish } from '@/utils-ts/rules/jobValidation'

export default {
  mixins: [snackMixin],
  methods: {
    ...mapActions({
      executeBtnRequest: 'notifications/executeBtnRequest',
      returnJobFundsToCustomerV1: 'jobDetails/returnFundsToCustomerV1',
      refundJobToCustomerByCustomerV2: 'jobDetails/refundToCustomerByCustomerV2',
      signJobPayToFreelancerV1: 'jobDetails/signPayToFreelancerV1',
      signJobPayToFreelancerV2: 'jobDetails/signPayToFreelancerV2',
    }),
    ...mapMutations({
      setNotificationData: 'notifications/setNotificationData',
    }),
    onPublishJobClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: false,
        request: async () => {
          try {
            const data = await getJob(note.params.job?.id)
            const job = Job.fromServer(data)
            const validationError = validateJobPublish(job)
            if (!validationError) {
              await publishJob(note.params.job?.id)
              googleAnalyticsV2.send({
                event: 'job-published',
                job_id: note.params.job?.id,
              })
              this.setNotificationData({ id: note.id, path: 'params.job', property: 'status', data: STATUS_PUBLISHED })
              this.openSnackbar({
                type: SnackTypes.SUCCESS,
                text: 'Job was successfully published.',
              })
            } else {
              this.openSnackbar({
                type: SnackTypes.FAILURE,
                text: validationError,
              })
            }
          } catch (e) {
            const defaultErr = 'Error publishing Job. Please try again.'
            const error = ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
            this.openSnackbar({
              type: SnackTypes.FAILURE,
              text: error,
            })
          }
        },
      })
    },
    onJobPayFreelancerClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: true,
        request: async () => {
          const data = await getJob(note.params.job?.id)
          const job = Job.fromServer(data)
          if (job.stage === STAGE_IN_PROGRESS) {
            if (note.params.job.contract_version === 1 || !note.params.job.contract_version) {
              await this.signJobPayToFreelancerV1({
                job,
                successSign: async () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: STAGE_COMPLETED
                  })
                }
              })
            }
            if (note.params.job.contract_version === 2) {
              await this.signJobPayToFreelancerV2({
                job,
                successSign: async () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: STAGE_COMPLETED
                  })
                }
              })
            }
          } else {
            this.notifyWrongStatus(job)
            this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: job.stage })
          }
        }
      })
    },
    onJobRefundClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: true,
        request: async () => {
          const data = await getJob(note.params.job?.id)
          const job = Job.fromServer(data)
          if (job.stage === STAGE_IN_PROGRESS) {
            if (note.params.job.contract_version === 1 || !note.params.job.contract_version) {
              this.returnJobFundsToCustomerV1({
                job,
                successSign: () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: STAGE_DEADLINE_OVERDUE
                  })
                }
              })
            }
            if (note.params.job.contract_version === 2) {
              this.refundJobToCustomerByCustomerV2({
                job,
                successSign: () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: STAGE_DEADLINE_OVERDUE
                  })
                }
              })
            }
          } else {
            this.notifyWrongStatus(job)
            this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: job.stage })
          }
        }
      })
    },
    notifyWrongStatus (job) {
      const msg = ![STAGE_DISPUTED, STAGE_BLOCKED_BY_FREELANCER].includes(job.stage)
        ? 'A dispute has been initiated under this contract'
        : 'The contract is already completed'
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: msg,
      })
    },
  },
}
