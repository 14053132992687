import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import omit from 'lodash/omit'
import GeneralInfo from '@/models-ts/user/profiles/freelancer/GeneralInfo'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import Skill from '@/models-ts/Skill'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import skillableMixin from '@/mixins/skillableMixin'
import { saveMyGeneralFreelancerProfile } from '@/api/users'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin, skillableMixin],
  props: {
    profile: GeneralInfo,
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: new GeneralInfo({}),
      editableFields: ['name', 'description', 'country', 'skills', 'website'],
      locationSearch: '',
    }
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    selectedCountry: {
      get () {
        return this.form.internalCountryId
      },
      set (v) {
        this.form.internalCountryId = v
      }
    },
    selectedCountryName () {
      const country = (this.countries || []).find((c: any) => c.id === this.selectedCountry)
      return country?.name
    },
    countriesOptions () {
      const options = (this.countries || []).map((c: any) => ({ key: c.id, label: c.name }))
      return this.locationSearch
        ? options.filter((opt: any) => opt.label.toLowerCase().includes(this.locationSearch.toLowerCase()))
        : options
    },
    skillsTags: {
      get () {
        let skills = []
        if (this.skillsLoaded) {
          skills = this.form.skills.map((skill: Skill) => skill.is_custom
            ? skill
            : this.predefinedSkills.find((s: Skill) => s.id === skill.id))
        }
        return skills.filter(Boolean).map((skill: Skill) => ({
          text: (skill.relations?.Parent || []).length || skill.is_custom
            ? skill.name
            : `Category: ${skill.name}`
        }))
      }
    },
    skillsWasUpdated () {
      if (this.profile.skills.length !== this.form.skills.length) {
        return true
      }
      for (let skill of this.profile.skills) {
        if (!this.form.skills.some((s: Skill) => s.id === skill.id)) {
          return true
        }
      }
      return false
    },
    websiteFormatted () {
      if (this.form.website) {
        try {
          const url = new URL(this.form.website)
          return { link: this.form.website, domain: url.hostname }
        } catch (e) {
          if (!this.form.website.startsWith('http')) {
            return { link: `http://${this.form.website}`, domain: this.form.website }
          }
        }
      }
    },
    wasUpdated () {
      const form = omit(this.form, ['skills'])
      return Object.entries(form).some(([key, value]) => value !== this.profile[key]) || this.skillsWasUpdated
    },
  },
  watch: {
    profile: {
      handler () {
        this.initForm()
      },
      immediate: true
    }
  },
  created () {
    try {
      this.getCountries()
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapMutations({
      setProfileData: 'user/setProfileData',
      setSkills: 'user/setSkills',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      getCountries: 'countries/getCountries',
    }),
    initForm () {
      if (this.profile) {
        this.form = new GeneralInfo(this.profile)
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    validateSkills () {
      if (!this.form.skills.length) {
        this.errors.add({
          field: 'form.skills',
          msg: 'Required field'
        })
        return false
      }
      return true
    },
    onLocationSearchInput (search: string) {
      if (!search.trim()) {
        this.selectedCountry = null
      }
    },
    onCountryOptionClick (option: any) {
      this.selectedCountry = option.key
      this.locationSearch = option.label
    },
    onSkillDelete ({ index }: { index: number }) {
      this.form.skills.splice(index, 1)
    },
    onManageSkillClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "skills-modals" */ '@/modals/SkillsSelection/SkillsSelection.vue'),
          title: 'Skills',
          props: {
            skills: this.form.skills,
            onSuccess: (skills: Array<Skill>) => {
              this.form.skills = skills
            }
          }
        }
      })
    },
    onEditClick () {
      this.locationSearch = this.selectedCountryName
      this.editMode = true
    },
    onCancelClick () {
      this.editMode = false
      this.errors.clear()
      this.initForm()
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('generalInfo')
      const skillsIsValid = this.validateSkills()
      if (skillsIsValid && isValid) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              return saveMyGeneralFreelancerProfile(this.form.toServer())
            },
            title: 'General info',
            successText: 'You have successfully updated your general information.',
            failureText: 'Error updating your information. Please try again.'
          })
          this.form = MyFreelancerProfile.fromServer(res).generalInfo
          this.setProfileData({ first_name: this.form.firstName, last_name: this.form.lastName })
          this.setSkills(!!this.form.skills.length)
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
