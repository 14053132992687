import Vue from 'vue'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import rolebleMixin from '@/mixins/rolebleMixin'
import actionsCardMixin from '../actionsCardMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, rolebleMixin],
  props: {
    application: VacancyApplication,
  },
})
