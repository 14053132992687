import Vue from 'vue'
import { mapMutations } from 'vuex'
import { BROWSE_JOBS, SERVICE_ADD } from '@/constants/routes'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import GigCard from '../../cards/gig/GigCard/GigCard.vue'

export default Vue.extend({
  components: {
    GigCard,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      BROWSE_JOBS,
      SERVICE_ADD,
    }
  },
  methods: {
    ...mapMutations({
      setFreelancerGigPublish: 'dashboard/setFreelancerGigPublish',
      setFreelancerGigDelete: 'dashboard/setFreelancerGigDelete',
    }),
    onChangeStatus ({ status, gig }: { status: GigStatuses, gig: MyGigListItem }) {
      this.setFreelancerGigPublish(gig)
      this.$emit('changeTab', 'active')
    },
    onDelete (gig: MyGigListItem) {
      this.setFreelancerGigDelete({ gig, type: 'draft' })
    },
  }
})
