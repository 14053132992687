import Vue from 'vue'
import copy from 'copy-to-clipboard'
import get from 'lodash/get'
import { mapState } from 'vuex'
import AccountType from '@/constants/user/AccountType'
import ratesMixin from '@/mixins/ratesMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  data () {
    return {
      copied: false,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
      userType: (state: any) => state.user?.type,
    }),
    facebookLink () {
      // eslint-disable-next-line
      return `https://www.facebook.com/dialog/share?app_id=${process.env.VUE_APP_FACEBOOK_APP_ID}&href=${this.refLink}&display=popup&redirect_uri=${this.refLink}`
    },
    twitterLink () {
      return `https://twitter.com/intent/tweet?text=${this.refLink}`
    },
    telegramLink () {
      return `https://telegram.me/share/url?url=${this.refLink}`
    },
    linkedinLink () {
      return `https://www.linkedin.com/sharing/share-offsite/?url=${this.refLink}`
    },
    links () {
      return {
        facebook: this.facebookLink,
        twitter: this.twitterLink,
        telegram: this.telegramLink,
        linkedin: this.linkedinLink,
      }
    },
    accountType () {
      return AccountType.getType(this.userType)
    },
    refLink () {
      if (this.userId) {
        return `${get(window, 'location.origin', process.env.VUE_APP_FRONTEND_URL)}?ref=${this.userId}`
      }
    },
    commission () {
      return this.accountType.referralBonuses
    },
  },
  methods: {
    onCopy () {
      this.copied = true
      copy(this.refLink)
    },
  },
})
