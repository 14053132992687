import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import rolebleMixin from '@/mixins/rolebleMixin'
import { REDESIGN_FEEDBACK } from '@/constants/components/quizzes'
import {
  BROWSE_JOBS,
  POST_A_JOB,
  JOB_DETAILS,
  JOB_DETAILS_BY_ID,
  JOB_EDIT,
  JOB_CHAT,
  SERVICES,
  SERVICE_ADD,
  SERVICE_CHAT,
  SERVICE_DETAILS_BY_ID,
  SERVICE_EDIT,
  SERVICE_MY_IN_PROGRESS,
  SERVICE_MY_OFFERS,
  WALLETS,
  VACANCY_EDIT,
  VACANCY_CHAT,
  VACANCY_DETAILS_BY_ID,
} from '@/constants/routes'
import CustomerDetails from '@/models-ts/user/details/CustomerDetails'
import FreelancerDetails from '@/models-ts/user/details/FreelancerDetails'
import {
  GIG_AFTER_CONTRACT_COMPLETED,
  GIG_AFTER_CONTRACT_CREATED,
  GIG_AFTER_DRAFTED,
  GIG_AFTER_PUBLISHED,
  GIG_AFTER_UNPUBLISHED,
  JOB_AFTER_CONTRACT_CREATED,
  JOB_AFTER_PUBLISHED,
  JOB_AFTER_UNPUBLISHED,
  GIG_OFFER_AFTER_APPLY,
  OFFER_AFTER_SEND,
  USER_AFTER_REGISTERED,
  REVIEW_BEFORE_GIG_LEFT,
  REVIEW_AFTER_CREATED,
  JOB_AFTER_DRAFTED,
  JOB_AFTER_CONTRACT_COMPLETED,
  REVIEW_BEFORE_JOB_LEFT,
  DISPUTE_AFTER_CREATED,
  GIG_DEADLINE_OVERDUE,
  JOB_DEADLINE_OVERDUE,
  GIG_DEADLINE_OVERDUE_TWO_DAYS,
  JOB_DEADLINE_OVERDUE_TWO_DAYS,
  DISPUTE_AFTER_RESOLVED,
  JOB_OFFER_AFTER_APPLY,
  OFFER_AFTER_DECLINE,
  TW_BEFORE_JOB_MINING,
  TW_BEFORE_GIG_MINING,
  APPLICATION_AFTER_CREATE,
  JOB_APPLICATION_AFTER_DECLINE,
  JOB_AFTER_MARKED_DONE,
  JOB_AFTER_MARKED_NOT_DONE,
  JOB_ROOM_UNLOCKED,
  JOB_WELCOME_MESSAGE,
  SURVEY,
  GIG_JOB_AFTER_MARKED_NOT_DONE,
  VACANCY_AFTER_DRAFTED,
  VACANCY_AFTER_UNPUBLISHED,
  VACANCY_APPLICATION_AFTER_APPLY,
  JOB_MODERATION_FAILED,
  JOB_MODERATION_PASSED,
  VACANCY_MODERATION_FAILED,
  VACANCY_MODERATION_PASSED,
  GIG_MODERATION_FAILED,
} from '@/constants/notifications'
import { USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'
import { JOB_STATUS_BLOCKED, JOB_STATUS_IN_PROGRESS, STATUS_PUBLISHED as GIG_STATUS_PUBLISHED } from '@/constants/backend/service'
import { STAGE_BLOCKED_BY_FREELANCER, STAGE_IN_PROGRESS } from '@/constants/job/jobStages'
import { STATUS_PUBLISHED as JOB_STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import { getAddressLink, getTxLink } from '@/utils/etherscan'
import { getCustomer, getWorker } from '@/api/usersAndProfiles/profiles'
import snackMixin from '@/mixins/snackMixin'
import { parseSlug } from '@/utils-ts/parser'
import NotificationListItem from '@/models-ts/dashboard/NotificationListItem'
import gigMethods from './gigMethods'
import jobMethods from './jobMethods'
import { Statuses } from '@/constants/vacancies/statuses'
import vacancyMethods from './vacancyMethods'
import zendeskMixin from '@/mixins/zendeskMixin'
import { DISPUTE_GIG_TYPE, DISPUTE_JOB_TYPE } from '@/constants/components/CreateDispute'

export default Vue.extend<any, any, any, any>({
  mixins: [gigMethods, jobMethods, rolebleMixin, vacancyMethods, zendeskMixin, snackMixin],
  props: {
    notification: NotificationListItem
  },
  data () {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
    }),
    actionsButtons () {
      return this.getButtons().filter(Boolean)
    }
  },
  mounted () {
    this.mockLinks()
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      executeBtnRequest: 'notifications/executeBtnRequest',
    }),
    getButtons () {
      const notification: NotificationListItem = this.notification
      const filter = (arr: Array<any>) => arr.filter(Boolean)
      const TX_BTN = {
        title: 'View transaction',
        // @ts-ignore
        href: getTxLink({ blockchain: notification.params.job?.blockchain, tx: notification.params.job?.txid })
      }
      const GIG_PAY_TX_BTN = {
        title: 'View transaction',
        // @ts-ignore
        href: getTxLink({ blockchain: notification.params.job?.blockchain, tx: notification.params.job?.txid })
      }
      const VIEW_WALLET_BTN = {
        title: 'View wallet',
        to: { name: WALLETS },
      }
      const VIEW_CUSTOMER_WALLET_BTN = {
        title: 'View wallet',
        href: getAddressLink({ blockchain: notification.params.job?.blockchain, address: notification.params.job?.customer_wallet })
      }
      const LEAVE_FEEDBACK_BTN = {
        title: 'Leave a review',
        onClick: this.onLeaveFeedbackClick,
      }
      const LEAVE_REDESIGN_FEEDBACK_BTN = {
        title: 'Leave feedback',
        onClick: this.onLeaveRedesignFeedback,
      }
      const VIEW_GIG_DISPUTE_BTN = {
        title: 'View dispute',
        to: { name: SERVICE_MY_IN_PROGRESS },
      }
      const VIEW_JOB_DISPUTE_BTN = {
        title: 'View dispute',
        to: { name: JOB_DETAILS, params: { id: notification.params.job?.id, slug: parseSlug(notification.params.job?.slug) } },
      }
      const EDIT_GIG_BTN = {
        title: 'Edit Gig',
        to: {
          name: SERVICE_EDIT,
          params: { id: notification.params.gig?.id }
        }
      }
      const GIG_CHAT_BTN_PARAMS = {
        to: {
          name: SERVICE_CHAT,
          params: {
            id: notification.params.gig?.id,
            slug: parseSlug(notification.params.gig?.slug),
          },
          query: {
            room: notification.params.room_id,
          }
        }
      }
      const JOB_CHAT_BTN_PARAMS = {
        to: {
          name: JOB_CHAT,
          params: {
            id: notification.params.job?.id,
            slug: parseSlug(notification.params.job?.slug),
          },
          query: {
            room: notification.params.room_id,
          }
        }
      }
      const VACANCY_CHAT_BTN_PARAMS = {
        to: {
          name: VACANCY_CHAT,
          params: {
            id: notification.params.vacancy?.id,
            slug: parseSlug(notification.params.vacancy?.slug),
          },
          query: {
            room: notification.params.room_id,
          }
        }
      }
      const GO_TO_CHAT_PARAMS = {
        ...notification.byGig ? GIG_CHAT_BTN_PARAMS
          : notification.byVacancy ? VACANCY_CHAT_BTN_PARAMS
            : JOB_CHAT_BTN_PARAMS
      }
      const GO_TO_CHAT_BTN = notification.params.room_id ? {
        title: 'Go to chat',
        ...GO_TO_CHAT_PARAMS,
      } : null
      const READ_THE_REVIEW_BTN = notification.params.room_id ? {
        title: 'Read review',
        to: notification.gig_id ? {
          name: SERVICE_CHAT,
          params: { id: notification.gig_id, slug: parseSlug(notification.params?.slug) },
          query: { room: notification.params.room_id }
        } : {
          name: JOB_CHAT,
          params: { id: notification.job_id, slug: parseSlug(notification.params?.slug) },
          query: { room: notification.params.room_id }
        }
      } : null

      const EDIT_VACANCY_BTN = {
        title: 'Edit',
        to: {
          name: VACANCY_EDIT,
          params: { id: notification.params.vacancy?.id }
        }
      }
      const GO_TO_APPLICATIONS_BTN = {
        title: 'Go to applications',
        ...GO_TO_CHAT_PARAMS,
      }

      if (this.isFreelancer) {
        switch (notification.event) {
          case USER_AFTER_REGISTERED: return [
            { title: 'Create Gig', to: { name: SERVICE_ADD } },
            { title: 'Browse Jobs', to: { name: BROWSE_JOBS } },
          ]
          case REVIEW_AFTER_CREATED: return filter([READ_THE_REVIEW_BTN])
          case GIG_MODERATION_FAILED: return [{ title: 'Contact support', onClick: this.onContactSupportClick }]
          case GIG_AFTER_PUBLISHED: return [{
            title: `Improve Your Gig’s Ranking`,
            href: 'https://laborx.com/blog/improve-gig-ranking',
          }]
          case GIG_AFTER_UNPUBLISHED: return filter([
            notification.params.gig?.status !== GIG_STATUS_PUBLISHED && { title: 'Publish Gig', onClick: this.onPublishGigClick },
            notification.params.gig?.status !== GIG_STATUS_PUBLISHED
              ? EDIT_GIG_BTN
              : {
                title: 'View Gig', // TODO How To Improve Your Gig's Ranking
                to: { name: SERVICE_DETAILS_BY_ID, params: { id: notification.params.gig?.id } }
              },
          ])
          case GIG_AFTER_DRAFTED: return filter([
            notification.params.gig?.status !== GIG_STATUS_PUBLISHED && { title: 'Publish Gig', onClick: this.onPublishGigClick },
            notification.params.gig?.status !== GIG_STATUS_PUBLISHED
              ? EDIT_GIG_BTN
              : {
                title: 'View Gig', // TODO How To Improve Your Gig's Ranking
                to: { name: SERVICE_DETAILS_BY_ID, params: { id: notification.params.gig?.id } }
              },
          ])
          case APPLICATION_AFTER_CREATE: return [GO_TO_APPLICATIONS_BTN]
          case OFFER_AFTER_SEND: return [{ title: 'View details', to: { name: SERVICE_MY_OFFERS } }]
          case GIG_AFTER_CONTRACT_CREATED: return filter([GO_TO_CHAT_BTN, TX_BTN]) // "Go to chat / View transaction"
          case GIG_AFTER_CONTRACT_COMPLETED: return filter([GO_TO_CHAT_BTN, TX_BTN])
          case GIG_JOB_AFTER_MARKED_NOT_DONE: return filter([GO_TO_CHAT_BTN])
          case GIG_DEADLINE_OVERDUE: return [
            { title: 'Start dispute', onClick: this.onStartDisputeClick },
            GO_TO_CHAT_BTN,
          ]
          case TW_BEFORE_GIG_MINING: return [VIEW_WALLET_BTN]
          case TW_BEFORE_JOB_MINING: return [VIEW_WALLET_BTN]
          case REVIEW_BEFORE_GIG_LEFT: return filter([!notification.params.review?.id && LEAVE_FEEDBACK_BTN])
          case JOB_AFTER_CONTRACT_CREATED: return filter([GO_TO_CHAT_BTN, TX_BTN])
          case JOB_AFTER_CONTRACT_COMPLETED: return filter([GO_TO_CHAT_BTN, TX_BTN])
          case REVIEW_BEFORE_JOB_LEFT: return filter([!notification.params.review?.id && LEAVE_FEEDBACK_BTN])
          case JOB_DEADLINE_OVERDUE: return filter([
            { title: 'Start dispute', onClick: this.onStartDisputeClick },
            GO_TO_CHAT_BTN,
          ])
          case GIG_OFFER_AFTER_APPLY: return filter([GO_TO_CHAT_BTN])
          case JOB_APPLICATION_AFTER_DECLINE: return [
            {
              title: 'Go to Job',
              to: {
                name: JOB_DETAILS,
                params: {
                  id: notification.params.job?.id,
                  slug: parseSlug(notification.params.job?.slug),
                }
              }
            },
          ]
          case JOB_AFTER_MARKED_NOT_DONE: return filter([GO_TO_CHAT_BTN])
          case JOB_ROOM_UNLOCKED: return filter([GO_TO_CHAT_BTN])
          case JOB_OFFER_AFTER_APPLY: return filter([GO_TO_CHAT_BTN])
          case OFFER_AFTER_DECLINE: return filter([GO_TO_CHAT_BTN])
          case JOB_WELCOME_MESSAGE: return [LEAVE_REDESIGN_FEEDBACK_BTN]
          case VACANCY_APPLICATION_AFTER_APPLY: return filter([GO_TO_CHAT_BTN])
          default: return []
        }
      }
      switch (notification.event) {
        case TW_BEFORE_GIG_MINING: return [VIEW_WALLET_BTN]
        case TW_BEFORE_JOB_MINING: return [VIEW_WALLET_BTN]
        case JOB_APPLICATION_AFTER_DECLINE: return [
          {
            title: 'Browse more Gigs',
            to: { name: SERVICES },
          },
        ]
        case USER_AFTER_REGISTERED: return [
          { title: 'Create Job', to: { name: POST_A_JOB } },
          { title: 'Browse Gigs', to: { name: SERVICES } },
        ]
        case REVIEW_AFTER_CREATED: return filter([READ_THE_REVIEW_BTN])
        case GIG_OFFER_AFTER_APPLY: return filter([GO_TO_CHAT_BTN])
        case OFFER_AFTER_DECLINE: return filter([GO_TO_CHAT_BTN])
        case GIG_AFTER_CONTRACT_CREATED: return [TX_BTN]
        case REVIEW_BEFORE_GIG_LEFT: return filter([!notification.params.review?.id && LEAVE_FEEDBACK_BTN])
        case GIG_AFTER_CONTRACT_COMPLETED: return [GIG_PAY_TX_BTN]
        case GIG_DEADLINE_OVERDUE: return filter([
          notification.params.job?.stage === JOB_STATUS_IN_PROGRESS && { title: 'Pay freelancer', onClick: this.onGigPayFreelancerClick },
        ])
        case GIG_DEADLINE_OVERDUE_TWO_DAYS: return filter([
          notification.params.job?.stage === JOB_STATUS_IN_PROGRESS && { title: 'Refund', onClick: this.onGigRefundClick }
        ])
        case JOB_AFTER_PUBLISHED: return [
          {
            title: 'View Job',
            to: { name: JOB_DETAILS_BY_ID, params: { id: notification.params.job?.id } }
          }
        ]
        case JOB_AFTER_DRAFTED: return filter([
          { title: 'Edit Job', to: { name: JOB_EDIT, params: { id: notification.params.job?.id } } },
          notification.params.job?.status !== JOB_STATUS_PUBLISHED && { title: 'Publish Job', onClick: this.onPublishJobClick },
        ])
        case JOB_AFTER_UNPUBLISHED: return filter([
          { title: 'Edit Job', to: { name: JOB_EDIT, params: { id: notification.params.job?.id } } },
          notification.params.job?.status !== JOB_STATUS_PUBLISHED && { title: 'Republish Job', onClick: this.onPublishJobClick },
        ])
        case OFFER_AFTER_SEND: return notification.byGig ? [] : filter([GO_TO_CHAT_BTN])
        case JOB_AFTER_CONTRACT_CREATED: return filter([GO_TO_CHAT_BTN, TX_BTN])
        case JOB_AFTER_CONTRACT_COMPLETED: return filter([GO_TO_CHAT_BTN, TX_BTN])
        case JOB_DEADLINE_OVERDUE: return filter([
          notification.params.job?.stage === STAGE_IN_PROGRESS && { title: 'Pay Freelancer', onClick: this.onJobPayFreelancerClick },
        ])
        case REVIEW_BEFORE_JOB_LEFT: return filter([!notification.params.review?.id && LEAVE_FEEDBACK_BTN])
        case JOB_DEADLINE_OVERDUE_TWO_DAYS: return filter([
          notification.params.job?.stage === STAGE_IN_PROGRESS && { title: 'Request refund', onClick: this.onJobRefundClick }
        ])
        case DISPUTE_AFTER_CREATED: return [notification.byGig ? VIEW_GIG_DISPUTE_BTN : VIEW_JOB_DISPUTE_BTN]
        case DISPUTE_AFTER_RESOLVED: {
          if (notification.params.winner === 'Freelancer') {
            return [notification.byGig ? VIEW_GIG_DISPUTE_BTN : VIEW_JOB_DISPUTE_BTN]
          }
          return [VIEW_CUSTOMER_WALLET_BTN]
        }
        case APPLICATION_AFTER_CREATE: return [GO_TO_APPLICATIONS_BTN]
        case JOB_AFTER_MARKED_DONE: return filter([GO_TO_CHAT_BTN])
        case JOB_WELCOME_MESSAGE: return [LEAVE_REDESIGN_FEEDBACK_BTN]
        case JOB_MODERATION_FAILED: return [{ title: 'Contact support', onClick: this.onContactSupportClick }]
        case JOB_MODERATION_PASSED: return [{
          title: 'View Job',
          to: { name: JOB_DETAILS_BY_ID, params: { id: notification.params.job?.id } }
        }]
        case SURVEY: return [{
          title: 'Survey',
          href: notification.params.link,
        }]
        case VACANCY_AFTER_DRAFTED: return [EDIT_VACANCY_BTN]
        case VACANCY_AFTER_UNPUBLISHED: return [
          EDIT_VACANCY_BTN,
          notification.params.vacancy?.status === Statuses.DRAFT && { title: 'Publish FTJ', onClick: this.onPublishVacancyClick },
        ]
        case VACANCY_MODERATION_FAILED: return [{ title: 'Contact support', onClick: this.onContactSupportClick }]
        case VACANCY_MODERATION_PASSED: return [{
          title: 'View Job',
          to: { name: VACANCY_DETAILS_BY_ID, params: { id: notification.params.vacancy?.id } }
        }]
        default: return []
      }
    },
    onLeaveRedesignFeedback () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/Quiz/Quiz.vue'),
          props: {
            type: REDESIGN_FEEDBACK,
            meta: {
              userId: this.ownId,
            }
          }
        }
      })
    },
    onLeaveFeedbackClick (note: any) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: true,
        request: async () => {
          let gigId
          let jobId
          let profile
          let name
          if (note.params.gig?.id) {
            gigId = note.params.job?.id
            name = note.params.gig?.name
          } else {
            jobId = note.params.job?.id
            name = note.params.job?.name
          }
          try {
            if (this.isFreelancer) {
              const userData = await getCustomer(note.params.job?.customer_id)
              const user = CustomerDetails.fromServer(userData)
              const isPerson = user.type === USER_TYPE_CUSTOMER_PERSON
              profile = {
                id: user.userId,
                name: user.fullName,
                avatar: isPerson ? user.individualInfo.avatar : user.companyInfo.avatar,
                type: user.accountType,
                avgReviews: user.avgReviews,
                reviewsCount: user.reviewsCount,
              }
            } else {
              const userData = await getWorker(note.params.job?.freelancer_id)
              const user = FreelancerDetails.fromServer(userData)
              profile = {
                id: user.id,
                name: user.fullName,
                avatar: user.avatar,
                type: user.type,
                avgReviews: user.avgReview,
                reviewsCount: user.reviews.length,
              }
            }
            this.openModal({
              component: 'lx-lazy-modal',
              props: {
                factory: import(/* webpackChunkName: "job-modals" */ '@/modals/LeaveFeedback/LeaveFeedback.vue'),
                title: 'Leave a review',
                props: {
                  name,
                  profile,
                  gigId,
                  jobId,
                  fromFreelancer: this.isFreelancer,
                  onSuccess: (review: any) => {
                    this.setNotificationData({ id: note.id, path: 'params.review', property: 'id', data: review.id })
                  },
                }
              }
            })
          } catch (e) {
            if (ErrorMatcher.isGone(e)) {
              this.openSnackbar({
                text: 'User not found',
                type: this.SnackTypes.FAILURE,
              })
            }
          }
        }
      })
    },
    onStartDisputeClick (note: NotificationListItem) {
      if (!note.byGig && !note.byJob) {
        return
      }
      const finalStage = note.byGig ? JOB_STATUS_BLOCKED : STAGE_BLOCKED_BY_FREELANCER
      const job = note.params.job!
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CreateDispute/CreateDispute.vue'),
          title: 'Open a dispute',
          props: {
            disputeData: {
              type: note.byGig ? DISPUTE_GIG_TYPE : DISPUTE_JOB_TYPE,
              id: job.id,
              name: job.name,
              blockchain: job.blockchain,
            },
            onSuccess: () => {
              this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: finalStage })
            }
          }
        }
      })
    },
    onContactSupportClick () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
    mockLinks () {
      this.$nextTick(() => {
        const note = this.$refs.notification
        if (note) {
          const links = note.querySelectorAll('a')
          links.forEach((link: any) => link.addEventListener('click', (e: any) => {
            const path = link.getAttribute('href')
            e.preventDefault()
            e.stopPropagation()
            this.$router.push(path)
          }, true))
        }
      })
    },
  }
})
