import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import JobCardFreelancer from '@/partials/JobCards/JobCardFreelancer/JobCardFreelancer.vue'

const JOBS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin],
  components: {
    JobCardFreelancer,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState({
      jobs: (state: any) => state.myJobs.workerNegotiations.values,
      isLoading: (state: any) => state.myJobs.workerNegotiations.isLoading,
      isLoaded: (state: any) => state.myJobs.workerNegotiations.isLoaded,
      pagination: (state: any) => state.myJobs.workerNegotiations.pagination,
    }),
  },
  async created () {
    await this.load({ limit: JOBS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'myJobs/loadWorkerNegotiations',
      loadMore: 'myJobs/loadMoreWorkerNegotiations',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.jobs.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: JOBS_LIMIT, offset: this.jobs.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
  },
  metaInfo: {
    title: 'My Jobs - Offers',
  },
})
