export const USER_AFTER_REGISTERED = 'userAfterRegistered'
export const PROFILE_AFTER_CHANGE = 'profileAfterChange'
export const REVIEW_AFTER_CREATED = 'reviewAfterCreated'
export const USER_AFTER_GRANT_PREMIUM = 'userAfterGrantPremium'

export const GIG_AFTER_PUBLISHED = 'gigAfterPublished'
export const GIG_AFTER_DRAFTED = 'gigAfterDrafted'
export const GIG_AFTER_UNPUBLISHED = 'gigAfterUnpublished'
export const GIG_AFTER_REMOVED = 'gigAfterRemoved'
export const GIG_MODERATION_FAILED = 'gigModerationFailed'
export const GIG_MODERATION_MANUAL = 'gigModerationManual'
export const OFFER_AFTER_SEND = 'offerAfterSend'
export const GIG_OFFER_AFTER_APPLY = 'gigOfferAfterApply'
export const OFFER_AFTER_DECLINE = 'offerAfterDecline'
export const GIG_AFTER_CONTRACT_CREATED = 'gigAfterContractCreated'
export const GIG_AFTER_CONTRACT_COMPLETED = 'gigAfterContractCompleted'
export const GIG_JOB_AFTER_MARKED_NOT_DONE = 'gigJobAfterMarkedNotDone'
export const REVIEW_BEFORE_GIG_LEFT = 'reviewBeforeGigLeft'
export const GIG_DEADLINE_OVERDUE = 'gigDeadlineOverdue'
export const GIG_DEADLINE_OVERDUE_TWO_DAYS = 'gigDeadlineOverdueTwoDays'

export const JOB_AFTER_PUBLISHED = 'jobAfterPublished'
export const JOB_AFTER_UNPUBLISHED = 'jobAfterUnpublished'
export const JOB_AFTER_DRAFTED = 'jobAfterDrafted'
export const JOB_AFTER_REMOVED = 'jobAfterRemoved'
export const JOB_OFFER_AFTER_APPLY = 'jobOfferAfterApply'
export const JOB_AFTER_CONTRACT_CREATED = 'jobAfterContractCreated'
export const JOB_AFTER_CONTRACT_COMPLETED = 'jobAfterContractCompleted'
export const JOB_DEADLINE_OVERDUE = 'jobDeadlineOverdue'
export const JOB_DEADLINE_OVERDUE_TWO_DAYS = 'jobDeadlineOverdueTwoDays'
export const APPLICATION_AFTER_CREATE = 'applicationAfterCreate'
export const JOB_APPLICATION_AFTER_DECLINE = 'applicationAfterDecline'
export const JOB_AFTER_MARKED_DONE = 'jobAfterMarkedDone'
export const JOB_AFTER_MARKED_NOT_DONE = 'jobAfterMarkedNotDone'
export const JOB_ROOM_UNLOCKED = 'jobRoomUnlocked'
export const JOB_WELCOME_MESSAGE = 'jobWelcomeMessage'
export const JOB_MODERATION_FAILED = 'jobModerationFailed'
export const JOB_MODERATION_PASSED = 'jobModerationPassed'

export const REVIEW_BEFORE_JOB_LEFT = 'reviewBeforeJobLeft'

export const TW_BEFORE_JOB_MINING = 'twBeforeJobMining'
export const TW_BEFORE_GIG_MINING = 'twBeforeGigMining'

export const DISPUTE_AFTER_CREATED = 'disputeAfterCreated'
export const DISPUTE_AFTER_RESOLVED = 'disputeAfterResolved'

export const SURVEY = '20211110'

export const VACANCY_AFTER_DRAFTED = 'vacancyAfterDrafted'
export const VACANCY_AFTER_UNPUBLISHED = 'vacancyAfterUnpublished'
export const VACANCY_APPLICATION_AFTER_APPLY = 'vacancyApplicationAfterApply'
export const VACANCY_MODERATION_FAILED = 'vacancyModerationFailed'
export const VACANCY_MODERATION_PASSED = 'vacancyModerationPassed'
