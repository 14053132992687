import { mapActions } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import {
  MY_JOBS,
  JOBS_ALL,
  JOBS_TO_DO,
  JOBS_COMPLETED,
  JOBS_POSTED,
  JOBS_NEGOTIATIONS,
  JOBS_IN_PROGRESS,
  JOB_ADD,
  JOBS_FINISHED
} from '@/constants/routes'
import ratesMixin from '@/mixins/ratesMixin'
import ClientAllTab from './ClientAllTab/ClientAllTab.vue'
import ClientCompletedTab from './ClientCompletedTab/ClientCompletedTab.vue'
import ClientPostedTab from './ClientPostedTab/ClientPostedTab.vue'
import ClientToDoTab from './ClientToDoTab/ClientToDoTab.vue'
import WorkerAllTab from './WorkerAllTab/WorkerAllTab.vue'
import WorkerNegotiationsTab from './WorkerNegotiationsTab/WorkerNegotiationsTab.vue'
import WorkerToDoTab from './WorkerToDoTab/WorkerToDoTab.vue'
import WorkerFinishedTab from '@/pages/Jobs/MyJobs/WorkerFinishedTab/WorkerFinishedTab.vue'

export default {
  mixins: [rolebleMixin, ratesMixin],
  data () {
    return {
      MY_JOBS,
      JOB_ADD,
      currentTab: 0,
    }
  },
  computed: {
    tabs () {
      return this.isFreelancer
        ? [
          { title: 'All', component: WorkerAllTab, to: { name: JOBS_ALL } },
          { title: 'Offers', component: WorkerNegotiationsTab, to: { name: JOBS_NEGOTIATIONS } },
          { title: 'In Progress', component: WorkerToDoTab, to: { name: JOBS_TO_DO } },
          { title: 'Finished', component: WorkerFinishedTab, to: { name: JOBS_FINISHED } },
        ]
        : [
          { title: 'All', component: ClientAllTab, to: { name: JOBS_ALL } },
          { title: 'Posted', component: ClientPostedTab, to: { name: JOBS_POSTED } },
          { title: 'In Progress', component: ClientToDoTab, to: { name: JOBS_IN_PROGRESS } },
          { title: 'Completed', component: ClientCompletedTab, to: { name: JOBS_COMPLETED } },
        ]
    },
  },
  watch: {
    $route: {
      handler () {
        this.currentTab = this.$route.name
      },
      immediate: true,
    }
  },
}
