import { render, staticRenderFns } from "./CustomerArchiveTab.htm?vue&type=template&id=45f82437&scoped=true&"
import script from "./CustomerArchiveTab.ts?vue&type=script&lang=js&"
export * from "./CustomerArchiveTab.ts?vue&type=script&lang=js&"
import style0 from "./CustomerArchiveTab.scss?vue&type=style&index=0&id=45f82437&lang=scss&scoped=true&"
import style1 from "../../MyJobsTab.scss?vue&type=style&index=1&id=45f82437&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f82437",
  null
  
)

export default component.exports