import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import VacancyCardNew from '@/partials/VacancyCardNew/VacancyCardNew.vue'

const SORT_NEW = {
  name: 'Newest',
  field: 'date',
  dir: 'desc',
}

const SORT_OLD = {
  name: 'Oldest',
  field: 'date',
  dir: 'asc',
}

const SORT_OPTIONS = [
  SORT_NEW,
  SORT_OLD,
]
const BOOKMARKS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin],
  components: {
    VacancyCardNew,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
      sortValue: SORT_NEW,
      sortOptions: SORT_OPTIONS,
    }
  },
  computed: {
    ...mapState({
      bookmarks: (state: any) => state.bookmarks?.vacancyBookmarks.values,
      isLoading: (state: any) => state.bookmarks?.vacancyBookmarks.isLoading,
      isLoaded: (state: any) => state.bookmarks?.vacancyBookmarks.isLoaded,
      pagination: (state: any) => state.bookmarks?.vacancyBookmarks.pagination,
    }),
  },
  async created () {
    await this.load({
      limit: BOOKMARKS_LIMIT,
      offset: 0,
      orderField: this.sortValue.field,
      orderType: this.sortValue.dir
    })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'bookmarks/loadVacancyBookmarks',
      loadMore: 'bookmarks/loadMoreVacancyBookmarks',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.bookmarks.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({
            limit: BOOKMARKS_LIMIT,
            offset: this.bookmarks.length,
            orderField: this.sortValue.field,
            orderType: this.sortValue.dir
          })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    async onSortInput (sort: any) {
      if (sort && sort?.name !== this.sortValue?.name) {
        this.sortValue = sort
        await this.load({
          limit: BOOKMARKS_LIMIT,
          offset: 0,
          orderField: this.sortValue.field,
          orderType: this.sortValue.dir
        })
        this.infiniteId = +new Date()
      }
    },
  },
  metaInfo: {
    title: 'Bookmarks - Full-time Jobs',
  },
})
