import { render, staticRenderFns } from "./FreelancerPostedTab.htm?vue&type=template&id=24fd3b11&scoped=true&"
import script from "./FreelancerPostedTab.ts?vue&type=script&lang=js&"
export * from "./FreelancerPostedTab.ts?vue&type=script&lang=js&"
import style0 from "../MyGigsTab.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./FreelancerPostedTab.scss?vue&type=style&index=1&id=24fd3b11&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24fd3b11",
  null
  
)

export default component.exports