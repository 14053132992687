import Vue from 'vue'
import { mapActions } from 'vuex'
import File from '@/models-ts/File'
import Image from '@/models/Image'
import responseMixin from '@/mixins/responseMixin'
import PortfolioProject from '@/models-ts/user/PortfolioProject'
import { isImage } from '@/utils/file'
import isNotEmptyProject from '../isNotEmptyProject'

const MAX_IMAGES = 10
const MAX_FILES = 10

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  props: {
    editMode: Boolean,
    project: PortfolioProject,
  },
  data () {
    return {
      form: new PortfolioProject({}),
    }
  },
  computed: {
    files () {
      return this.form.getFiles()
    },
    images () {
      return this.files.filter(isImage)
    }
  },
  watch: {
    project: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
    editMode () {
      this.initForm()
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    isImage,
    initForm () {
      if (this.project) {
        this.form = new PortfolioProject(this.project)
      }
    },
    getImageSrc (file: File & UploadedFile) {
      return file.src || file.base64
    },
    emitUpdate () {
      this.$emit('update', this.form)
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    async validate () {
      let isValid = true
      this.errors.clear()
      const files = this.form.getFiles()
      const { filesCount, imagesCount } = files.reduce((acc: any, cur: any) => {
        if (isImage(cur)) {
          acc.imagesCount++
        } else {
          acc.filesCount++
        }
        return acc
      }, { filesCount: 0, imagesCount: 0 })
      if (isNotEmptyProject(this.form)) {
        isValid = await this.$validator.validateScopes('portfolio')
        if (!this.form.files.length) {
          this.errors.add({
            field: 'form.files',
            msg: 'Required field',
            scope: 'portfolio',
          })
        } else if (filesCount > MAX_FILES) {
          this.errors.add({
            field: 'form.files',
            msg: `Maximum number of files: ${MAX_FILES}`,
            scope: 'portfolio',
          })
        } else if (imagesCount > MAX_IMAGES) {
          this.errors.add({
            field: 'form.files',
            msg: `Maximum number of images: ${MAX_IMAGES}`,
            scope: 'portfolio',
          })
        }
      }
      return isValid && !this.$validator.errors.count()
    },
    onFileUpload (file: UploadedFile) {
      this.form.addFile(file)
      this.emitUpdate()
    },
    onDelete (i: number) {
      this.form.deleteFile(i)
      this.emitUpdate()
    },
    onClickToImage (image: Image) {
      // @ts-ignore
      const index = this.images.findIndex((im: Image) => im.id === image.id)
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
          props: {
            title: this.project.projectName,
            images: this.images,
            initialImage: index,
          },
        }
      })
    },
  },
})
