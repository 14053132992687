import Vue from 'vue'
import BigNumber from 'bignumber.js'
import { mapActions, mapState } from 'vuex'
import ratesMixin from '@/mixins/ratesMixin'
import { BlockchainNames, WalletGroup } from '@/constants/blockchain'
import { BUTTON_TRANSPARENT_MEDIUM } from '@/constants/components/button'
import { WALLETS } from '@/constants/routes'
import { formatCurrency, formatUsd } from '@/utils/moneyFormat'
import { getCurrency } from '@/utils/currency'
import Rate from '@/models-ts/Rate'
import Currency from '@/models-ts/Currency'
import Wallet from '@/models-ts/Wallet'

type WalletBalance = { currency: Currency, balanceUsd: BigNumber, value: BigNumber }

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  data () {
    return {
      BUTTON_TRANSPARENT_MEDIUM,
      BlockchainNames,
      WALLETS,
    }
  },
  computed: {
    ...mapState({
      wallet: (state: any) => state.user.wallet,
      wallets: (state: any) => state.user.wallets,
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      balances: (state: any) => state.balances.balances.value || {},
      balancesIsLoaded: (state: any) => state.balances.balances.isLoaded,
      escrowLoaded: (state: any) => state.balances.escrowBalance.isLoaded,
      escrowBalance: (state: any) => state.balances.escrowBalance.value,
    }),
    loaded () {
      return this.balancesIsLoaded && this.ratesLoaded && this.userIsLoaded
    },
    walletImage () {
      switch (this.wallet.group) {
        case WalletGroup.Metamask: return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect: return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink: return '/static/images/wallets/tron-wallet.png'
        default: return '/static/images/wallets/cloud-wallet.svg'
      }
    },
    walletBalances () {
      const balances = {} as { [key in string]: Array<WalletBalance> }
      for (const blockchain in this.balances) {
        for (const currencyName in this.balances[blockchain]) {
          const value = this.balances[blockchain][currencyName]
          if (!+value) {
            continue
          }
          const currency = getCurrency({ value: currencyName, blockchain: +blockchain })!
          const rate = this.rates.find((item: Rate) => item.currency === currency.backendName)
          const balance = new BigNumber(value)
          const balanceUsd = balance
            .dividedBy(currency.baseUnits)
            .multipliedBy(rate ? rate.price : 1)
          if (!balances[blockchain]) {
            balances[blockchain] = []
          }
          balances[blockchain].push({
            currency,
            balanceUsd,
            value,
          })
        }
      }
      for (const blockchain in balances) {
        balances[blockchain] = balances[blockchain].sort((balanceA, balanceB) => balanceB.balanceUsd.minus(balanceA.balanceUsd).toNumber())
      }
      return balances
    },
    walletBalanceExists () {
      return Object.keys(this.walletBalances).length > 0
    },
    totalBalance () {
      let totalBalance = new BigNumber(0)
      for (const blockchain in this.walletBalances) {
        totalBalance = totalBalance.plus(
          // @ts-ignore
          this.walletBalances[blockchain].reduce((total, { balanceUsd }) => total.plus(balanceUsd), new BigNumber(0))
        )
      }
      return formatUsd(totalBalance)
    },
    hasConnectBtn () {
      return this.loaded && !(this.wallets || []).some((wallet: Wallet) => wallet.group === WalletGroup.Metamask)
    },
  },
  created () {
    this.loadEscrowBalance()
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      loadEscrowBalance: 'balances/loadEscrowBalance',
    }),
    formatUsd,
    formatBalance (balance: BigNumber, currency: Currency) {
      return formatCurrency(balance, {
        blockchain: currency.blockchain,
        currency: currency,
        divider: currency.baseUnits
      })
    },
    onAddWalletClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/WalletAdd/WalletAdd.vue'),
          title: 'Add Wallet',
          props: {
            walletType: WalletGroup.Metamask,
            shouldBeDefault: true,
          }
        }
      })
    },
    onBalanceInfoClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/WalletBalance/WalletBalance.vue'),
          title: 'Wallet balance',
        }
      })
    },
  },
})
