import Vue, { PropType } from 'vue'
import { RawLocation } from 'vue-router'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import UserInfoModel from '@/models-ts/user/UserInfo'
import { mapActions } from 'vuex'

export default Vue.extend({
  components: { UserInfo },
  props: {
    entity: Object as PropType<{
      name: String,
      link: RawLocation,
    }>,
    isFreelancer: Boolean,
    user: Object as PropType<UserInfoModel>,
    withDetails: Boolean,
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onToggleClick () {
      this.$emit('toggle', !this.withDetails)
    },
    onReportClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ReportUser/ReportUser.vue'),
          props: {
            toUserId: this.user.id,
            toUserName: this.user.name,
          },
        },
      })
    },
  },
})
