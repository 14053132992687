import Vue from 'vue'
import { mapMutations } from 'vuex'
import { BROWSE_JOBS, SERVICE_ADD } from '@/constants/routes'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import JobApplication from '@/models-ts/job/JobApplication'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import MyApplicationCard from '../../cards/vacancy/MyApplicationCard/MyApplicationCard.vue'
import GigJobCard from '../../cards/gig/GigJobCard/GigJobCard.vue'
import GigCard from '../../cards/gig/GigCard/GigCard.vue'
import OfferCard from '../../cards/gig/OfferCard/OfferCard.vue'
import JobCardFreelancer from '../../cards/job/JobCardFreelancer/JobCardFreelancer.vue'

type ActiveJob = VacancyApplication | MyGigListItem | MyGigApplication | GigOfferListItem | JobApplication

export default Vue.extend<any, any, any, any>({
  components: {
    MyApplicationCard,
    GigJobCard,
    GigCard,
    OfferCard,
    JobCardFreelancer,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      BROWSE_JOBS,
      SERVICE_ADD,
    }
  },
  computed: {
    renderingJobs () {
      return this.jobs.map(this.getComponent)
    },
  },
  methods: {
    ...mapMutations({
      setFreelancerGigUnpublish: 'dashboard/setFreelancerGigUnpublish',
      setFreelancerGigDelete: 'dashboard/setFreelancerGigDelete',
    }),
    getComponent (job: ActiveJob) {
      if (job instanceof VacancyApplication) {
        return {
          component: MyApplicationCard,
          id: job.id,
          props: {
            application: job,
            isClient: true
          }
        }
      } else if (job instanceof GigOfferListItem) {
        return {
          component: GigJobCard,
          // @ts-ignore
          id: job.id,
          props: {
            gig: job,
            isFreelancer: true,
          }
        }
      } else if (job instanceof MyGigListItem) {
        return {
          component: GigCard,
          id: job.id,
          props: {
            gig: job,
          },
          handlers: {
            changeStatus: ({ status, gig }: { status: GigStatuses, gig: MyGigListItem }) => {
              this.setFreelancerGigUnpublish(gig)
              this.$emit('changeTab', 'draft')
            },
            delete: (gig: MyGigListItem) => {
              this.setFreelancerGigDelete({ gig, type: 'active' })
            },
          }
        }
      } else if (job instanceof MyGigApplication) {
        return {
          component: OfferCard,
          id: job.id,
          props: {
            application: job,
            isFreelancer: true,
          }
        }
      } else {
        return {
          component: JobCardFreelancer,
          // @ts-ignore
          id: job.id,
          props: {
            application: job,
          }
        }
      }
    },
  }
})
