import { Badge } from '../Badge'

export default class Badges {
  static ApplicationSent: Badge = {
    name: 'Application Sent',
    cssClass: 'blue-status',
  }
  static ApplicationDeclined: Badge = {
    name: 'Application Declined',
    cssClass: 'gray-status',
  }
  static OfferSent: Badge = {
    name: 'Offer Sent',
    cssClass: 'blue-status',
    description: 'This offer is new',
  }
  static OfferDeclined: Badge = {
    name: 'Offer Declined',
    cssClass: 'orange-status',
    description: 'Offer was rejected',
  }
  static OfferAccepted: Badge = {
    name: 'Hired',
    cssClass: 'blue-status',
    description: 'The offer was successfully accepted',
  }
}
