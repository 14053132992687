import Vue from 'vue'
import { mapActions } from 'vuex'
import ImageChatMessage from '@/models-ts/chat/messages/ImageChatMessage'
import messageMixin from '../messageMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin],
  props: {
    message: ImageChatMessage,
  },
  computed: {
    isSending () {
      return this.message.sending
    },
    srcImage () {
      return this.isSending && this.message.base64 ? this.message.base64 : this.message.body.url
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onImageClick () {
      if (!this.isSending) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ImageViewer/ImageViewer.vue'),
            props: {
              images: [{
                id: this.message.id,
                description: this.message.body.name,
                src: this.message.body.url,
              }]
            }
          }
        })
      }
    },
  }
})
