import Vue from 'vue'
import { CURRENCY_FIELD_NAME } from '@/constants/currency'
import { getWithdraws } from '@/api/timewarp'
import { getCurrency } from '@/utils-ts/currencies'
import BonusesCard from '@/partials/BonusesCard/BonusesCard.vue'
import responseMixin from '@/mixins/responseMixin'
import ReferredTab from './ReferredTab/ReferredTab.vue'
import SummaryCard from './SummaryCard/SummaryCard.vue'
import WithdrawTab from './WithdrawTab/WithdrawTab.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    BonusesCard,
    ReferredTab,
    SummaryCard,
    WithdrawTab,
  },
  data () {
    return {
      activeTab: 0,
      currency: getCurrency({ value: 'TIME', field: CURRENCY_FIELD_NAME }),
    }
  },
  methods: {
    onWithdrawRequested () {
      this.activeTab = 1
    },
  },
  metaInfo: {
    title: 'Referrals',
  },
})
