import ImageChatMessage from '@/models-ts/chat/messages/ImageChatMessage'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import UserInfo from '../UserInfo/UserInfo.vue'
import TextChatMessage from '../TextChatMessage/TextChatMessage.vue'
import TextChatMessageModel from '@/models-ts/chat/messages/TextChatMessage'
import { stripWithLinks } from '@/utils-ts/strings'

export default Vue.extend({
  components: {
    TextChatMessage,
    UserInfo,
  },
  computed: {
    ...mapGetters({
      messages: 'chatNew/getRoomMessages',
    }),
    links () {
      return this.messages
        .filter((msg: any) => msg instanceof TextChatMessageModel)
        .filter((msg: TextChatMessageModel) => msg.body !== stripWithLinks(msg.body))
    },
  },
})
