import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import Button from '@/models-ts/ui/Button'
import responseMixin from '@/mixins/responseMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  data () {
    return {
    }
  },
  computed: {
    ...mapState('user', {
      userInfoLoading: (state: any) => state.profile.isLoading,
      userId: (state: any) => state.id,
      avatar: (state: any) => state.avatar,
    }),
    hasAvatar () {
      return this.avatar?.src
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      removeAvatar: 'user/removeAvatar',
    }),
    onChangeAvatar (event: Event | null) {
      if (event) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/UpdateAvatar/UpdateAvatar.vue'),
            title: 'Crop your profile picture',
            props: {
              // @ts-ignore
              image: event.target.files[0],
            }
          }
        })
        // @ts-ignore
        event.target.value = ''
      }
    },
    onRemoveAvatarClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Remove the image?',
          text: 'The default image will be set as your avatar',
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Remove',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  await this.removeAvatar()
                } catch (e) {
                  this.parseError(e)
                }
              },
            }),
          ]
        }
      })
    },
  },
})
