import Vue, { PropType } from 'vue'
import { mapActions, mapState } from 'vuex'
import IndividualInfo from '@/models-ts/user/profiles/cutomer/IndividualInfo'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'
import PrefferedCurrency from '@/partials/PrefferedCurrency/PrefferedCurrency.vue'
import PrefferedCurrencyModel from '@/models-ts/user/PrefferedCurrency'
import Skill from '@/models-ts/Skill'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { saveCustomerIndividualInfoProfile } from '@/api/users'
import SkillsSelect from '../SkillsSelect/SkillsSelect.vue'
import { USER_TYPE_CUSTOMER_PERSON } from '@/constants/user/roles'

export default Vue.extend<any, any, any, any>({
  components: {
    PrefferedCurrency,
    SkillsSelect,
  },
  props: {
    info: IndividualInfo,
    skills: {
      type: Array as PropType<Array<Skill>>,
      default: [],
    },
    currencies: {
      type: Array as PropType<Array<PrefferedCurrencyModel>>,
      default: [],
    },
    editMode: Boolean,
  },
  data () {
    return {
      saving: false,
      form: new IndividualInfo({}),
      formSkills: [],
      formCurrencies: [],
      locationSearch: '',
    }
  },
  computed: {
    ...mapState('countries', {
      countriesIsLoaded: (state: any) => state.list.isLoaded,
      countriesIsLoading: (state: any) => state.list.isLoading,
      countries: (state: any) => state.list.value,
    }),
    skillsTags: {
      get () {
        return this.formSkills.map((skill: Skill) => ({ text: skill.name }))
      }
    },
    selectedCountry: {
      get () {
        return this.form.internalCountryId
      },
      set (v) {
        this.form.internalCountryId = v
      }
    },
    selectedCountryName () {
      const country = (this.countries || []).find((c: any) => c.id === this.selectedCountry)
      return country?.name
    },
    countriesOptions () {
      const options = (this.countries || []).map((c: any) => ({ key: c.id, label: c.name }))
      return this.locationSearch
        ? options.filter((opt: any) => opt.label.toLowerCase().includes(this.locationSearch.toLowerCase()))
        : options
    },
    websiteFormated () {
      if (this.form.website) {
        try {
          const url = new URL(this.form.website)
          return { link: this.form.website, domain: url.hostname }
        } catch (e) {
          if (!this.form.website.startsWith('http')) {
            return { link: `http://${this.form.website}`, domain: this.form.website }
          }
        }
      }
    },
    wasUpdated () {
      return false // Object.entries(this.form).some(([key, value]) => value !== this.generalInfo[key])
    },
  },
  watch: {
    generalInfo: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
    editMode: {
      handler () {
        this.locationSearch = this.selectedCountryName
      },
      immediate: true,
    }
  },
  async created () {
    try {
      this.getCountries()
    } catch (e) {
      this.parseError(e)
    }
  },
  methods: {
    ...mapActions({
      setActiveProfile: 'user/setActiveProfile',
      openModal: 'ui/openModal',
      getCountries: 'countries/getCountries',
    }),
    initForm () {
      if (this.info) {
        this.form = new IndividualInfo(this.info)
      }
      if (this.skills.length) {
        this.formSkills = [...this.skills]
      }
      if (this.currencies.length) {
        this.formCurrencies = [...this.currencies]
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    validateSkills () {
      if (!this.formSkills.length) {
        this.errors.add({
          field: 'form.skills',
          msg: 'Required field'
        })
        return false
      }
      return true
    },
    validateCurrencies () {
      if (!this.formCurrencies.length) {
        this.errors.add({
          field: 'form.currencies',
          msg: 'Required field'
        })
        return false
      }
      return true
    },
    onCountryOptionClick (option: any) {
      this.selectedCountry = option.key
      this.locationSearch = option.label
    },
    onCancelClick () {
      this.errors.clear()
      this.initForm()
      this.$emit('cancel')
    },
    onLocationSearchInput (search: string) {
      if (!search.trim()) {
        this.selectedCountry = null
      }
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('individualInfo')
      const isValidSkills = this.validateSkills()
      const isValidCurrencies = this.validateCurrencies()
      if (isValid && isValidSkills && isValidCurrencies) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              const response = await saveCustomerIndividualInfoProfile({
                ...this.form.toServer(),
                relations: {
                  Currency: this.formCurrencies.map((c: PrefferedCurrencyModel) => c.toServer()),
                  Skill: this.formSkills.map((s: Skill) => s.toServer()),
                }
              })
              return response
            },
            title: 'Individual info',
            successText: 'You have successfully updated your personal information.',
            failureText: 'Error updating your information. Please try again.'
          })
          const profile = MyCustomerProfile.fromServer(res)
          this.form = profile.individualInfo
          this.formSkills = profile.skills
          this.formCurrencies = profile.currencies
          await this.setActiveProfile({
            profile: {
              id: res.user_id,
              type: USER_TYPE_CUSTOMER_PERSON,
              first_name: profile.generalInfo.firstName,
              last_name: profile.generalInfo.lastName,
              description: this.form.description,
              website: this.form.website,
              relations: {
                Country: [],
                Skill: profile.skills,
                Currency: profile.currencies,
              }
            }
          })
          this.$emit('update', profile)
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  }
})
