import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import GigApplication from '@/models-ts/gigs/GigApplication'
import { TIME_FIXED } from '@/constants/backend/service'
import { formatUsd } from '@/utils/moneyFormat'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { applyOffer, declineOffer, getGigOffer } from '@/api/gig'
import rolebleMixin from '@/mixins/rolebleMixin'
import GigOffer from '@/models-ts/gigs/GigOffer'
import sleep from '@/utils/sleep'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import snackMixin from '@/mixins/snackMixin'
import { GigApplicationStatuses } from '@/constants/gig/gigApplicationStatuses'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  props: {
    application: {
      type: GigApplication,
      required: true,
    },
    offer: GigOfferListItem,

  },
  data () {
    return {
      starting: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      acceptingOffer: (state: RootState) => state.chatNew.acceptingOffer,
      decliningOffer: (state: RootState) => state.chatNew.decliningOffer,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    appOffer () {
      return this.application.offer
    },
    accepting () {
      return this.acceptingOffer && this.acceptingOffer === this.appOffer?.id
    },
    declining () {
      return this.decliningOffer && this.decliningOffer === this.appOffer?.id
    },
    isOwn () {
      return this.appOffer?.freelancerId === this.userId
    },
    isClient () {
      return this.appOffer?.customerId === this.userId
    },
    isTimeFixed () {
      return this.appOffer?.timeType === TIME_FIXED
    },
    isArchived () {
      return this.application.status === GigApplicationStatuses.ARCHIVED
    },
    isNewOffer () {
      return this.appOffer?.stage === GigOfferStages.NEW
    },
    deadline () {
      if (this.isTimeFixed) {
        return `${this.appOffer?.deadlineInDays} days delivery`
      }
      const hour = this.appOffer?.timeValue === 1 ? 'hour' : 'hours'
      return `${this.appOffer?.timeValue} ${hour} delivery`
    },
    budget () {
      let total = new BigNumber(this.appOffer?.budget)
      if (!this.isTimeFixed) {
        total = total.multipliedBy(this.appOffer?.timeValue)
      }
      return formatUsd(total)
    },
    hasActions () {
      return this.appOffer?.stage === GigOfferStages.NEW
    },
  },
  methods: {
    ...mapMutations({
      setAcceptingOffer: 'chatNew/setAcceptingOffer',
      setDecliningOffer: 'chatNew/setDecliningOffer',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      signCreateContractV2: 'gig/signCreateContractV2',
    }),
    emitRefresh () {
      return this.$emit('refresh')
    },
    async onCancelOfferClick () {
      try {
        this.setDecliningOffer(this.appOffer.id)
        await declineOffer({ id: this.appOffer.id, comment: 'Not specified' })
        this.$emit('cancel', this.appOffer.id)
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error declining offer. Please try again.',
        })
      } finally {
        this.setDecliningOffer(null)
      }
    },
    async onEditOfferClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
          title: 'Send an offer',
          props: {
            applicationId: this.application.id,
            offer: this.appOffer,
            initBudget: new BigNumber(this.appOffer.budget).toNumber(),
            initDays: this.appOffer.deadlineInDays,
            initHours: this.appOffer.timeValue,
            gig: this.application.gig,
            onSuccess: () => {
              this.emitRefresh()
            }
          }
        }
      })
    },
    async onAcceptClick () {
      try {
        googleAnalyticsV2.send({
          event: 'gig_accept_click',
          gig_id: this.application.gigId,
        })
        this.setAcceptingOffer(this.appOffer.id)
        const offer: GigOffer = this.appOffer
        await notifiableRequest({
          request: async () => {
            await applyOffer(offer.id)
            await sleep(this.backendConfig['CREATE_CONTRACT_BACKEND_FLOW_TIMEOUT'] || 2000)
          },
          title: 'Offer accepted',
          successText: `You have an offer for this Gig.`,
          failureText: 'Error accepting offer. Please try again.'
        })
        this.starting = true
        this.emitRefresh()
        this.startJob()
      } finally {
        this.setAcceptingOffer(null)
      }
    },
    async startJob () {
      try {
        this.starting = true
        let offer = this.offer
        if (!offer) {
          offer = GigOfferListItem.fromServer(await getGigOffer(this.appOffer.id))
        }
        this.signCreateContractV2({
          gig: offer,
          successSign: () => {
            this.openSnackbar({
              type: this.SnackTypes.SUCCESS,
              text: 'Your funds have successfully been deposited to escrow.',
            })
            this.starting = false
            this.emitRefresh()
          },
          cancelSign: () => {
            this.starting = false
          },
        })
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.starting = false
      }
    },
  }
})
