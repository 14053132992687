import { render, staticRenderFns } from "./GigCard.htm?vue&type=template&id=0c99c48c&scoped=true&"
import script from "./GigCard.ts?vue&type=script&lang=js&"
export * from "./GigCard.ts?vue&type=script&lang=js&"
import style0 from "./GigCard.scss?vue&type=style&index=0&id=0c99c48c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c99c48c",
  null
  
)

export default component.exports