import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import responseMixin from '@/mixins/responseMixin'
import { DATE_TIME_FORMAT_BY_MERIDIEM } from '@/constants/utils/date'
import { SERVICE_CHAT, SERVICE_DETAILS } from '@/constants/routes'
import { convertToUsd, getCurrency } from '@/utils-ts/currencies'
import { getTxLink } from '@/utils-ts/transaction'
import snackMixin from '@/mixins/snackMixin'
import { Blockchain, BlockchainNames } from '@/constants/blockchain'
import { addDays, addSeconds, convertToLocal, formatDate, isBefore } from '@/utils/date'
import GigJobStatusBadge from '@/partials/StatusBadges/GigJobStatusBadge/GigJobStatusBadge.vue'
import { CURRENCY_FIELD_NAME } from '@/constants/currency'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { formatCurrency } from '@/utils/moneyFormat'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import CardActionMenu from '../../components/CardActionMenu/CardActionMenu.vue'

const EXTRA_DAYS = 2

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    CardActionMenu,
    GigJobStatusBadge,
    UserInfo,
    JobTypeBadge,
  },
  props: {
    gig: GigOfferListItem,
    isFreelancer: Boolean,
  },
  data () {
    return {
      SERVICE_DETAILS,
    }
  },
  computed: {
    ...mapState({
      payToFreelancerLoading: (state: any) => state.gig.payToFreelancerLoading,
      returnFundsToCustomerLoading: (state: any) => state.gig.returnFundsToCustomerLoading,
      rates: (state: any) => state.rates.rates.value || [],
      isBalancesLoaded: (state: any) => state.balances.balances.isLoaded,
      chatInitialized: (state: any) => state.chatNew?.initialized,
      chatConnected: (state: any) => state.chatNew?.connected,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    user () {
      return this.isFreelancer ? this.gig.customerProfile : this.gig.freelancerProfile
    },
    hasChat () {
      return this.gig.applicationId && this.chatInitialized
    },
    hasPayBtn () {
      return !this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS
    },
    hasRefundBtn () {
      return !this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS && this.refundHasCome
    },
    hasDisputeBtn () {
      return this.isFreelancer && this.gig.job.stage === GigJobStages.IN_PROGRESS && this.deadlineHasCome
    },
    hasPayContractLoading () {
      return !!this.payToFreelancerLoading[this.gig.sc_id]
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.GIG,
        applicationId: this.gig.applicationId,
        freelancerId: this.gig.freelancerId,
        customerId: this.gig.customerId,
      })
    },
    chatLink () {
      return {
        name: SERVICE_CHAT,
        params: { id: this.gig.gig_id, slug: this.gig.slug },
        query: { room: this.chatRoomId }
      }
    },
    chatPayLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'pay',
        }
      }
    },
    chatFeedbackLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'feedback',
        }
      }
    },
    chatRefundLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'refund',
        }
      }
    },
    chatDisputeLink () {
      return {
        ...this.chatLink,
        query: {
          ...this.chatLink.query,
          action: 'dispute',
        }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    deadline () {
      return formatDate(addSeconds(convertToLocal(this.gig.job.created_at), this.gig.deadline), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    refundDate () {
      return formatDate(addDays(this.deadline, EXTRA_DAYS), DATE_TIME_FORMAT_BY_MERIDIEM)
    },
    deadlineHasCome () {
      return isBefore(this.deadline, Date.now())
    },
    refundHasCome () {
      return isBefore(this.refundDate, Date.now())
    },
    transactionStartLink () {
      return getTxLink({ blockchain: this.gig.blockchain, tx: this.gig.job.txid_created })
    },
    transactionEndLink () {
      return getTxLink({ blockchain: this.gig.blockchain, tx: this.gig.job.txid_completed })
    },
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.gig.job?.stage)
    },
    hasReviewBtn (): boolean {
      return this.isCompleted && !this.gig?.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    hasInvoiceBtn (): boolean {
      return this.isCompleted && this.gig.document
    },
    blockchainName () {
      return BlockchainNames[this.gig.blockchain as Blockchain]
    },
    currency () {
      return getCurrency({ blockchain: this.gig.blockchain, value: this.gig.currency, field: CURRENCY_FIELD_NAME })
    },
    escrowBalance () {
      return formatCurrency(this.gig.job.escrow_balance, { currency: this.currency })
    },
    escrowBalanceInUsd () {
      return this.rates.length
        ? convertToUsd({ value: this.gig.job.escrow_balance, currency: this.currency, rates: this.rates, format: true })
        : null
    },
    gigLink () {
      return { name: SERVICE_DETAILS, params: { id: this.gig.gig_id, slug: this.gig.slug } }
    },
    cardLink () {
      return this.hasChat && this.chatConnected
        ? this.chatLink
        : this.gigLink
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
    }),
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    onPayToTalentClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatPayLink)
    },
  }
})
