import Vue from 'vue'
import isEqual from 'lodash/isEqual'
import responseMixin from '@/mixins/responseMixin'
import FullTimeInfo from '@/models-ts/user/profiles/freelancer/FullTimeInfo'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { saveMyFullTimeInfoProfile } from '@/api/users'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  props: {
    info: FullTimeInfo,
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: new FullTimeInfo({}),
      editableFields: ['position', 'cv', 'years'],
    }
  },
  computed: {
    wasUpdated () {
      return !isEqual(this.info.toServer(), this.form.toServer())
    },
  },
  watch: {
    info: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
    editMode () {
      this.initForm()
    },
  },
  methods: {
    initForm () {
      if (this.info) {
        this.form = new FullTimeInfo(this.info)
      }
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    onFileUpload (file: UploadedFile) {
      this.form.cv.push(file)
    },
    onDelete (i: number) {
      this.form.cv.splice(i, 1)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      this.errors.clear()
      const isValid = await this.$validator.validateScopes('fulltimeInfo')
      if (isValid) {
        try {
          this.saving = true
          const res = await notifiableRequest({
            request: async () => {
              console.log(this.form.toServer())
              return saveMyFullTimeInfoProfile(this.form.toServer())
            },
            title: 'Full-time employee info',
            successText: 'You have successfully updated your full-time profile.',
            failureText: 'Error updating your information. Please try again.'
          })
          const info = FullTimeInfo.fromServer(res)
          this.form = info
          this.$emit('update', info)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
