import Vue from 'vue'
import times from 'lodash/times'

export default Vue.extend<any, any, any, any>({
  props: {
    value: String,
    disabled: Boolean,
    withDivider: Boolean,
    errorMsg: String,
    autofocus: Boolean,
  },
  data () {
    return {
      tfaKey: 'XXX-XXX',
      inputHandler: null,
    }
  },
  watch: {
    value () {
      if (!this.value) {
        this.tfaKey = 'XXX-XXX'
      }
    },
    tfaKey () {
      const keys = this.tfaKey.split('').filter((key: any) => key !== 'X' && key !== '-')
      this.$emit('input', keys.join(''))
    },
  },
  mounted () {
    const inputEl = this.getTfaInput()
    if (inputEl) {
      const hadnler = this.getBackSpaceDownHandler()
      inputEl.addEventListener('keydown', hadnler)
      this.inputHandler = hadnler
    }
  },
  beforeDestroy () {
    const inputEl = this.getTfaInput()
    if (inputEl && this.inputHandler) {
      inputEl.removeEventListener('keydown', this.inputHandler)
    }
  },
  methods: {
    getTfaInput () {
      const inputRef = this.$refs.tfaInput
      if (inputRef) {
        return inputRef.$el.querySelector('input')
      }
    },
    onBackspace (input: HTMLInputElement, e: KeyboardEvent) {
      const inputEl = this.getTfaInput()
      let position = inputEl.selectionStart
      let startPos = 0
      let endPos = 1
      if (position === 4) {
        startPos = 2
        endPos = 3
      } else if (position !== 0) {
        startPos = position - 1
        endPos = position
      }
      const val = this.tfaKey.split('')
      val.splice(position, 1, 'X')
      this.tfaKey = val.join('')
      inputEl.value = this.tfaKey

      inputEl.setSelectionRange(startPos, endPos)
      e.preventDefault()
      e.stopPropagation()
    },
    onArrowLeft () {
      const inputEl = this.getTfaInput()
      let position = inputEl.selectionStart
      let startPos = 0
      let endPos = 1
      if (position === 4) {
        startPos = 2
        endPos = 3
      } else if (position !== 0) {
        startPos = position - 1
        endPos = position
      }
      setTimeout(() => {
        inputEl.setSelectionRange(startPos, endPos)
      }, 0)
    },
    onArrowRight () {
      const inputEl = this.getTfaInput()
      let position = inputEl.selectionStart
      let startPos = 6
      let endPos = 7
      if (position === 2) {
        startPos = 4
        endPos = 5
      } else if (position < 6) {
        startPos = position + 1
        endPos = position + 2
      }
      setTimeout(() => {
        inputEl.setSelectionRange(startPos, endPos)
      }, 0)
    },
    getBackSpaceDownHandler () {
      const input = this.getTfaInput()
      if (input) {
        return (e: KeyboardEvent) => {
          switch (e.key) {
            case 'Backspace': return this.onBackspace(input, e)
            case 'ArrowLeft': return this.onArrowLeft(input, e)
            case 'ArrowRight': return this.onArrowRight(input, e)
          }
        }
      }
    },
    onFocus (e: FocusEvent) {
      const target = e.target as HTMLInputElement
      if (target) {
        target.setSelectionRange(0, 1)
      }
    },
    onKeyDown (e: KeyboardEvent) {
      if (['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) {
        const inputEl = this.getTfaInput()
        if (inputEl) {
          const position = inputEl.selectionStart

          if (position >= 7) {
            e.preventDefault()
            e.stopPropagation()
          } else if (position === 6) {
            setTimeout(() => {
              inputEl.setSelectionRange(6, 7)
            }, 0)
          } else if (position === 2 || position === 3) {
            setTimeout(() => {
              inputEl.setSelectionRange(4, 5)
            }, 0)
          } else {
            setTimeout(() => {
              inputEl.setSelectionRange(position + 1, position + 2)
            }, 0)
          }
        }
        return
      }
      if (!['Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
        e.preventDefault()
        e.stopPropagation()
      }
    },
  },
})
