import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import { JOB_BOOKMARKS, VACANCY_BOOKMARKS, GIG_BOOKMARKS, DASHBOARD } from '@/constants/routes'
import GigBookmarks from './GigBookmarks/GigBookmarks.vue'
import JobBookmarks from './JobBookmarks/JobBookmarks.vue'
import VacancyBookmarks from './VacancyBookmarks/VacancyBookmarks.vue'

export default Vue.extend({
  mixins: [rolebleMixin],
  data () {
    return {
      DASHBOARD,
    }
  },
  computed: {
    tabs () {
      return [
        { title: 'Gigs', component: GigBookmarks, to: { name: GIG_BOOKMARKS } },
        { title: 'Jobs', component: JobBookmarks, to: { name: JOB_BOOKMARKS } },
        { title: 'Full-time Jobs', component: VacancyBookmarks, to: { name: VACANCY_BOOKMARKS } },
      ]
    },
  },
})
