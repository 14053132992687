import Vue from 'vue'
import get from 'lodash/get'
import { mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import Skill from '@/models-ts/Skill'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { STATUS_PUBLISHED, TIME_HOURLY } from '@/constants/backend/service'
import { SERVICE_DETAILS, SERVICE_EDIT } from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { formatCardsDescription } from '@/utils-ts/strings'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  props: {
    gig: MyGigListItem,
  },
  data () {
    return {
      publishing: false,
      SERVICE_DETAILS,
      SERVICE_EDIT,
    }
  },
  computed: {
    description () {
      return formatCardsDescription(this.gig.description)
    },
    isHourly () {
      return this.gig.timeType === TIME_HOURLY
    },
    isPublished () {
      return this.gig.status === STATUS_PUBLISHED
    },
    skills () {
      return this.gig.skills.map((skill: Skill) => ({ text: skill.name }))
    },
    rate () {
      return formatUsd(this.gig.rate)
    },
    views () {
      return this.gig.views > 1000 ? '1000+' : this.gig.views
    },
    hasActions () {
      return this.gig.moderationStage !== ModerationStages.FAILED
    },
  },
  methods: {
    ...mapActions({
      publish: 'myGigs/publish',
      unpublish: 'myGigs/unpublish',
    }),
    async onPublishCheckboxClick () {
      try {
        if (!this.publishing) {
          this.publishing = true
          if (this.isPublished) {
            await notifiableRequest({
              request: () => this.unpublish(this.gig.id),
              successText: `Gig: <b>${this.gig.name}</b> has been unpublished.`,
              failureText: 'Error unpublishing Gig. Please try again.',
            })
          } else {
            await notifiableRequest({
              request: () => this.publish(this.gig.id),
              successText: (moderation_stage) => moderation_stage === ModerationStages.PASSED
                ? `Gig: <b>${this.gig.name}</b> was successfully published`
                : 'Your Gig was successfully submitted for moderation. We will notify you of the outcome shortly.',
              failureText: (e) => {
                const defaultErr = 'Gig publishing error'
                return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
              }
            })
            googleAnalyticsV2.send({
              event: 'gig-published',
              gig_id: this.gig.id,
            })
          }
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.publishing = false
      }
    },
  },
})
