import Vue from 'vue'
import TextChatMessage from '@/models-ts/chat/messages/TextChatMessage'
import { stripWithLinks } from '@/utils-ts/strings'
import messageMixin from '../ThreadTab/MessageViewer/messageMixin'
import ApplicationChatMessage from '@/models-ts/chat/messages/ApplicationChatMessage'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin],
  props: {
    message: [TextChatMessage, ApplicationChatMessage],
  },
  computed: {
    text () {
      return stripWithLinks(this.message.body, !this.senderIsMe)
    },
  },
})
