import Vue from 'vue'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { mapState } from 'vuex'
import { Badge } from '../Badge'
import Badges from './Badges'

export default Vue.extend<any, any, any, any>({
  props: {
    gig: GigOfferListItem,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.gig?.job?.stage)
    },
    hasReview (): boolean {
      return this.isCompleted && this.gig?.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    badge (): Badge | undefined {
      const stage = this.gig.job?.stage as GigJobStages
      if (stage === GigJobStages.IN_PROGRESS) return Badges.InProgress
      if (stage === GigJobStages.BLOCKED) return Badges.Dispute
      if (!this.hasReview) return Badges.Review // Save order!
      switch (stage) {
        case GigJobStages.DISPUTED: return Badges.Resolved
        case GigJobStages.RETURNED: return Badges.Refunded
        case GigJobStages.PAYED: return Badges.Completed
      }
    },
  },
})
