import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { BUTTON_TRANSPARENT_MEDIUM } from '@/constants/components/button'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import { CUSTOMER_PROFILE, VACANCY_CHAT, VACANCY_DETAILS, VACANCY_EDIT } from '@/constants/routes'
import { getHumanDate } from '@/utils/date'
import { formatUsd } from '@/utils/moneyFormat'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import rolebleMixin from '@/mixins/rolebleMixin'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import BookmarkIconButton from '@/partials/BookmarkButtons/BookmarkIconButton/BookmarkIconButton.vue'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import { BookmarkTypes } from '@/constants/bookmarks/bookmarkType'
import BigNumber from 'bignumber.js'
import { formatCardsDescription } from '@/utils-ts/strings'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    BookmarkIconButton,
    UserInfo,
    JobTypeBadge,
    VacancyLocationTooltip,
  },
  props: {
    application: VacancyApplication,
  },
  data () {
    return {
      BUTTON_TRANSPARENT_MEDIUM,
      CUSTOMER_PROFILE,
      VACANCY_DETAILS,
      bookmarkType: BookmarkTypes.VACANCY,
    }
  },
  computed: {
    ...mapState({
      chatInitialized: (state: any) => state.chatNew?.initialized,
      chatConnected: (state: any) => state.chatNew?.connected,
    }),
    ...mapGetters({
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    vacancy () {
      return this.application.vacancy
    },
    description () {
      return formatCardsDescription(this.vacancy.description)
    },
    user () {
      return this.isFreelancer ? this.application.customer : this.application.freelancer
    },
    customerType () {
      return this.application.customer?.customerType
    },
    createdAt () {
      const date = this.vacancy.published_at || this.vacancy.updated_at
      return getHumanDate(date)
    },
    userId () {
      return this.vacancy.user?.id
    },
    userAvatar () {
      return this.vacancy.user?.avatar
    },
    userType () {
      return this.vacancy.user?.type
    },
    userName () {
      return this.vacancy.user?.name
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.positionType as PositionType]?.name
    },
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    salaryFrom () {
      const salaryFrom = new BigNumber(this.vacancy.salary_from).div(12)
      return salaryFrom.gt(100)
        ? formatUsd(salaryFrom, 0)
        : formatUsd(salaryFrom, 2)
    },
    salaryTo () {
      const salaryTo = new BigNumber(this.vacancy.salary_to).div(12)
      return salaryTo.gt(100)
        ? formatUsd(salaryTo, 0)
        : formatUsd(salaryTo, 2)
    },
    remoteInfo () {
      return this.application.remoteInfo
    },
    hasChat () {
      return this.chatInitialized
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.VACANCY,
        applicationId: this.application.id,
        freelancerId: this.application.freelancer.id,
        customerId: this.application.customer.id,
      })
    },
    chatLink () {
      return {
        name: VACANCY_CHAT,
        params: { id: this.vacancy.id, slug: this.vacancy.slug },
        query: { room: this.chatRoomId }
      }
    },
    hasNewMsgs () {
      const count: number = this.getUnreadCount(this.chatRoomId)
      return Boolean(count)
    },
    cardLink () {
      return this.hasChat && this.chatConnected
        ? this.chatLink
        : { name: VACANCY_DETAILS, params: { id: this.vacancy.id, slug: this.vacancy.slug } }
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
    }),
    formatUsd,
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
  },
})
