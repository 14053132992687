import BigNumber from 'bignumber.js'
import Transak from '@/servicies/Transak'
import { mapActions, mapState } from 'vuex'
import { Blockchain, BlockchainNames, EVM_BLOCKCHAIN, WalletGroup } from '@/constants/blockchain'
import { JOB_MINING } from '@/constants/routes'
import { formatUsdWithZeros } from '@/utils/moneyFormat'
import { getBlockchainIcon } from '@/utils-ts/wallet'
import snackMixin from '@/mixins/snackMixin'

export default {
  mixins: [snackMixin],
  props: {
    wallet: Object,
    list: {
      type: Array,
      default: () => []
    },
    total: String,
  },
  data () {
    return {
      JOB_MINING,
      transakLoading: false,
      selectedBlockchain: Blockchain.Ethereum,
      BlockchainNames,
    }
  },
  computed: {
    ...mapState({
      email: state => state.user.email,
      userId: state => state.user.id,
      miningBalance: state => state.balances.miningBalance.value,
      miningBalanceIsLoaded: state => state.balances.miningBalance.isLoaded,
    }),
    loaded () {
      return this.wallet
    },
    isCloud () {
      return this.wallet?.group === WalletGroup.Cloud
    },
    isTronLink () {
      return this.wallet?.group === WalletGroup.TronLink
    },
    walletImage () {
      switch (this.wallet.group) {
        case WalletGroup.Metamask:
          return '/static/images/wallets/metamask-wallet.svg'
        case WalletGroup.WalletConnect:
          return '/static/images/wallets/wallet-connect.png'
        case WalletGroup.TronLink:
          return '/static/images/wallets/tron-wallet.png'
        default:
          return '/static/images/wallets/cloud-wallet.svg'
      }
    },
    addressCut () {
      return `${this.wallet.address.slice(0, 6)}...${this.wallet.address.slice(-5)}`
    },
    sortedList () {
      return [...this.list].sort((a, b) => b.usd.minus(a.usd))
    },
    currencyByBlockchain () {
      const cbb = this.sortedList.reduce((map, item) => {
        if (
          (this.isTronLink && item.blockchain === Blockchain.Tron) ||
          (!this.isTronLink && item.blockchain !== Blockchain.Tron)
        ) {
          const name = BlockchainNames[item.blockchain]
          const key = item.blockchain
          if (!map[key]) {
            map[key] = {
              blockchain: {
                name,
                usd: new BigNumber(0),
              },
              list: []
            }
          }
          map[key].list.push(item)
          map[key].blockchain.usd = map[key].blockchain.usd.plus(item.usd)
        }
        return map
      }, {})
      Object.keys(cbb).forEach(c => {
        cbb[c].blockchain.usdFormatted = formatUsdWithZeros(cbb[c].blockchain.usd)
      })
      return cbb
    },
    blockchains () {
      return this.isTronLink ? [Blockchain.Tron] : EVM_BLOCKCHAIN
    },
    blockchainOptions () {
      return this.blockchains.map(b => ({ key: b, name: BlockchainNames[b] }))
    },
    selectedBlockchainOption () {
      return this.blockchainOptions.find(opt => opt.key === this.selectedBlockchain)
    },
  },
  watch: {
    currencyByBlockchain: {
      handler () {
        console.log('aaaa', this.currencyByBlockchain)
      },
      immediate: true,
    },
    wallet: {
      handler () {
        if (this.wallet) {
          this.selectedBlockchain = this.isTronLink ? Blockchain.Tron : Blockchain.Ethereum
        }
      },
      immediate: true
    }
  },
  async created () {
    try {
      await this.loadMiningBalance()
    } catch (e) {
      console.error('Error loading mining balance', e)
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: 'Error fetching Job Mining Balance. Please try again.',
      })
    }
  },
  methods: {
    formatUsdWithZeros,
    getBlockchainIcon,
    ...mapActions({
      loadMiningBalance: 'balances/loadMiningBalance',
      openModal: 'ui/openModal',
    }),
    onDepositCryptoClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/DepositCrypto/DepositCrypto.vue'),
          title: 'Deposit Crypto',
          props: {
            predefinedAddress: this.wallet.address,
          }
        }
      })
    },
    async onDepositCardClick () {
      if (!this.transakLoading) {
        try {
          this.transakLoading = true
          const transak = new Transak({
            walletAddress: this.wallet.address,
            email: this.email || this.userId,
          })
          await transak.open()
        } finally {
          this.transakLoading = false
        }
      }
    },
    onWithdrawClick () {
      let predefinedCurrency
      let predefinedBlockchain
      let maxUsdValue = new BigNumber(0)

      for (const balance of this.sortedList) {
        if (balance.usd.isGreaterThan(maxUsdValue)) {
          maxUsdValue = balance.usd
          predefinedCurrency = balance.currency
          predefinedBlockchain = +balance.blockchain
        }
      }
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/WithdrawCryptoNew/WithdrawCryptoNew.vue'),
          title: 'Withdraw Crypto',
          props: {
            predefinedAddress: this.wallet.address,
            predefinedCurrency,
            predefinedBlockchain,
            onSuccess: ({ from, to }) => {
              this.$emit('refresh-balances', { from, to })
            },
          },
        }
      })
    },
    async onSwapClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/SwapNew/Swap.vue'),
          title: 'Withdraw',
          props: {
            predefinedBlockchain: this.selectedBlockchain,
            predefinedWallet: this.wallet.address,
          },
        }
      })
    },
    onSelectBlockchain (opt) {
      this.selectedBlockchain = opt.key
    },
  },
}
