import Vue from 'vue'
import { mapActions } from 'vuex'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { JOB_DETAILS_ADMIN_APPLICATIONS, BROWSE_JOBS_BY_SKILL, JOB_EDIT } from '@/constants/routes'
import { STAGE_NEW, STAGE_STARTED } from '@/constants/job/jobStages'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Skill from '@/models-ts/Skill'
import snackMixin from '@/mixins/snackMixin'
import { validateJobPublish, canPublish } from '@/utils-ts/rules/jobValidation'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { STATUS_DRAFT, STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import JobStatusBadge from '@/partials/StatusBadges/JobStatusBadge/JobStatusBadge.vue'
import { publishJob } from '@/api/jobs/job'
import { formatCardsDescription } from '@/utils-ts/strings'

export default Vue.extend<any, any, any, any>({
  mixins: [snackMixin],
  components: {
    JobStatusBadge,
  },
  props: {
    job: MyCustomerJobListItem,
  },
  data () {
    return {
      JOB_DETAILS_ADMIN_APPLICATIONS,
      JOB_EDIT,
      publishing: false,
    }
  },
  computed: {
    description () {
      return formatCardsDescription(this.job.description)
    },
    isStarted () {
      return ![STAGE_NEW, STAGE_STARTED].includes(this.job.stage)
    },
    isPublished () {
      return this.job.status === STATUS_PUBLISHED
    },
    skills () {
      const skills = this.job.skills || []
      if (skills.length > 0) {
        return skills.slice(0, 2).map((skill: Skill) => ({
          text: skill.name,
          link: { name: BROWSE_JOBS_BY_SKILL, params: { skill: skill.url } }
        }))
      }
      return []
    },
    moreSkills () {
      if (this.skills.length > 1) {
        return this.skills.length - 1
      }
    },
    failedModeration () {
      return this.job.moderationStage === ModerationStages.FAILED
    },
    passedModeration () {
      return this.job.moderationStage === ModerationStages.PASSED
    },
    hasEditBtn () {
      return !this.job.freelancer
    },
    hasPublishBtn () {
      return canPublish(this.job)
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    async onClickPublishBtn () {
      if (this.isStarted && this.publishing) {
        return
      }
      if (this.isPublished) {
        await this.onUnpublishJob()
      } else {
        await this.onPublishJob()
      }
    },
    async onPublishJob () {
      try {
        this.publishing = true
        const validationError = validateJobPublish(this.job)
        if (!validationError) {
          let status, moderationStage
          await notifiableRequest({
            request: async () => {
              const job = await publishJob(this.job.id)
              status = job.status
              moderationStage = job.moderation_stage
            },
            title: 'Publish job',
            successText: this.job.moderationStage === ModerationStages.MANUAL
              ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
              : `You have published a Job <b>${this.job.name}</b>`,
            failureText: 'Error publish a Job'
          })
          googleAnalyticsV2.send({
            event: 'job-published',
            job_id: this.job.id,
          })
          this.$emit('changeStatus', { status, moderationStage, jobId: this.job.id })
        } else {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: validationError,
          })
        }
      } finally {
        this.publishing = false
      }
    },
    onUnpublishJob () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/UnpublishJobModal/UnpublishJobModal.vue'),
          title: 'Unpublish Job',
          props: {
            job: this.job,
            onSuccess: () => {
              googleAnalyticsV2.send({
                event: 'job-drafted',
                job_id: this.job.id,
              })
              this.$emit('changeStatus', { status: STATUS_DRAFT, jobId: this.job.id })
            },
            onFail: () => {},
          }
        }
      })
    },
  },
})
