import Vue from 'vue'

export default Vue.extend({
  props: {
    placement: {
      type: String,
      default: 'top-right'
    },
    popperOptions: {
      type: Object,
      default: () => ({})
    }
  }
})
