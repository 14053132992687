import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import Button from '@/models-ts/ui/Button'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import rolebleMixin from '@/mixins/rolebleMixin'
import { STATUS_DRAFT } from '@/constants/job/jobStatuses'
import { VACANCIES_MY, VACANCY_DETAILS_ADMIN } from '@/constants/routes'
import { VacancyApplicationStatuses } from '@/constants/vacancies/vacancyApplicationStatuses'
import { declineApplicationAsFreelancer } from '@/api/vacancies/applications'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  data () {
    return {
      hiring: false,
      declining: false,
      donning: false,
      unlocking: false,
      archiving: false,
    }
  },
  computed: {
    ...mapState({
      roomId: (state: any) => state.chatNew.openedRoomId,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
      getParticipant: 'chatNew/getParticipant',
    }),
    isTemporary () {
      return (this.room && this.room.temporary) || (this.roomId || '').startsWith('tmp_')
    },
    isDone () {
      return [VacancyApplicationStatuses.ARCHIVED, VacancyApplicationStatuses.APPLIED].includes(this.application?.status)
    },
    isLocked () {
      return this.room && !this.room.isUnlocked
    },
    isOwner () {
      return this.application?.customer.id === this.userId || this.vacancy.customer_id === this.userId
    },
    isDraft () {
      return this.application?.job.status === STATUS_DRAFT || this.vacancy?.status === STATUS_DRAFT
    },
    isMetaArchived () {
      return this.application?.meta?.archived
    },
    hasCancelCustomer () {
      return !this.isTemporary && this.isCustomer && !this.isDone && this.isOwner && !this.isMetaArchived
    },
    hasRemoveFromArchiveJob () {
      return !this.isTemporary && this.isCustomer && !this.isDone && this.isOwner && this.isMetaArchived
    },
    hasCancelFreelancer () {
      return !this.isTemporary && this.isFreelancer && !this.isDone
    },
  },
  methods: {
    ...mapActions({
      setApplicationArchivedMeta: 'vacancyDetails/setApplicationArchivedMeta',
      openModal: 'ui/openModal',
      closeRoom: 'chatNew/closeRoom',
    }),
    emitRefresh () {
      return this.$emit('refresh')
    },
    onCancelFreelancerClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Cancel application?',
          text: `This application will be removed from Customer's applications list.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  await declineApplicationAsFreelancer(this.application.id)
                  this.$router.push({ name: VACANCIES_MY })
                } catch (e) {
                  this.parseError(e)
                }
              },
            }),
          ]
        }
      })
    },
    async onDeclineApplicationClick () {
      googleAnalyticsV2.send({
        event: 'response-to-ftj-job',
        'event-content': 'customer-button-click-decline',
        ftj_id: this.application.vacancyId,
      })
      if (!this.archiving) {
        await this.setArchivedMeta(true)
        this.$router.push({
          name: VACANCY_DETAILS_ADMIN,
          params: { id: this.application.vacancyId }
        })
      }
    },
    async onRemoveFromArchivedClick () {
      if (!this.archiving) {
        await this.setArchivedMeta(false)
      }
    },
    onReportClick () {
      const toUser = this.room.participants.find((user: ChatParticipant) => +user.userId !== this.userId)
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ReportUser/ReportUser.vue'),
          props: {
            toUserId: toUser.userId,
            toUserName: toUser.name,
          },
        },
      })
    },
    async setArchivedMeta (flag: boolean, withSnackbar: boolean) {
      this.archiving = true
      try {
        const meta = { ...this.application.meta, archived: flag }
        await this.setApplicationArchivedMeta({
          vacancyId: this.application.vacancyId,
          applicationId: this.application.id,
          meta
        })
        this.$emit('update-meta', meta)
        if (withSnackbar) {
          this.openSnackbar({
            text: 'Application was unarchived',
          })
        }
      } catch (err) {
        if (withSnackbar) {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'Error unarchiving application. Please try again.',
          })
        }
        console.error(err)
      } finally {
        this.archiving = false
      }
    },
  }
})
