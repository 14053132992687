import { mapState, mapActions } from 'vuex'
import ChangeEmailCard from './ChangeEmailCard/ChangeEmailCard.vue'
import ChangePasswordCard from './ChangePasswordCard/ChangePasswordCard.vue'
import TfaCard from './TfaCard/TfaCard.vue'

export default {
  components: {
    ChangeEmailCard,
    ChangePasswordCard,
    TfaCard,
  },
  computed: {
    ...mapState({
      isLoaded: state => state.user?.profile.isLoaded,
      isSocialAuth: state => state.user?.isSocialAuth,
      isCryptoAuth: state => state.user?.isCrypto,
    }),
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onDeleteAccountClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/DeleteAccount/DeleteAccount.vue'),
          title: 'Delete account',
        }
      })
    },
  },
  metaInfo: {
    title: 'Security',
  },
}
