import Vue from 'vue'
import { SERVICES } from '@/constants/routes'

export default Vue.extend({
  data () {
    return {
      SERVICES,
    }
  },
})
