import Vue from 'vue'
import { mapMutations } from 'vuex'
import { JOB_ADD, SERVICES } from '@/constants/routes'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import { JobStatus, STATUS_PUBLISHED } from '@/constants/job/jobStatuses'
import JobPostedCardCustomer from '../../cards/job/JobPostedCardCustomer/JobPostedCardCustomer.vue'
import MyVacancyCard from '../../cards/vacancy/MyVacancyCard/MyVacancyCard.vue'
import { Statuses } from '@/constants/vacancies/statuses'

type DraftJob = MyCustomerJobListItem | MyVacancyListItem

export default Vue.extend<any, any, any, any>({
  components: {
    MyVacancyCard,
    JobPostedCardCustomer,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      JOB_ADD,
      SERVICES,
    }
  },
  computed: {
    renderingJobs () {
      return this.jobs.map(this.getComponent)
    },
  },
  methods: {
    ...mapMutations({
      setCustomerJobPublish: 'dashboard/setCustomerJobPublish',
      setCustomerJobUnpublish: 'dashboard/setCustomerJobUnpublish',
      setCustomerVacancyPublish: 'dashboard/setCustomerVacancyPublish',
      setCustomerVacancyUnpublish: 'dashboard/setCustomerVacancyUnpublish',
      setCustomerJobDelete: 'dashboard/setCustomerJobDelete',
      setCustomerVacancyDelete: 'dashboard/setCustomerVacancyDelete',
      setCustomerVacancyArchive: 'dashboard/setCustomerVacancyArchive',
    }),
    getComponent (job: DraftJob) {
      if (job instanceof MyCustomerJobListItem) {
        return {
          component: JobPostedCardCustomer,
          id: job.id,
          props: {
            job,
          },
          handlers: {
            changeStatus: ({ status, job }: { status: JobStatus, job: MyCustomerJobListItem }) => {
              if (status === STATUS_PUBLISHED) {
                this.setCustomerJobPublish(job)
              } else {
                this.setCustomerJobUnpublish(job)
              }
              this.$emit('changeTab', status === STATUS_PUBLISHED ? 'active' : 'draft')
            },
            delete: (job: MyCustomerJobListItem) => {
              this.setCustomerJobDelete({ job, isActive: false })
            },
          }
        }
      } else {
        return {
          component: MyVacancyCard,
          id: job.id,
          props: {
            vacancy: job,
          },
          handlers: {
            changeStatus: ({ status, vacancy }: { status: Statuses, vacancy: MyVacancyListItem }) => {
              if (status === Statuses.PUBLISHED) {
                this.setCustomerVacancyPublish(vacancy)
              } else {
                this.setCustomerVacancyUnpublish(vacancy)
              }
              this.$emit('changeTab', status === STATUS_PUBLISHED ? 'active' : 'draft')
            },
            delete: (job: MyVacancyListItem) => {
              this.setCustomerVacancyDelete({ job, type: 'draft' })
            },
            archive: (job: MyVacancyListItem) => {
              this.setCustomerVacancyArchive({ job, type: 'active' })
              this.$emit('changeTab', 'archive')
            },
          }
        }
      }
    },
  }
})
