import Vue, { PropType } from 'vue'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { mapActions } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import EducationItem from './EducationItem/EducationItem.vue'
import Education from '@/models-ts/user/Education'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import isNotEmptyEducation from './isNotEmptyEducation'
import { saveEducationsProfile } from '@/api/users'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  components: {
    EducationItem,
  },
  props: {
    educations: {
      type: Array as PropType<Array<Education>>,
      default: [],
    }
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: [],
      editableFields: ['education'],
    }
  },
  computed: {
    wasUpdated () {
      const form = this.form.filter(isNotEmptyEducation)
      if (form.length !== this.educations.length) {
        return true
      }
      for (let edIndex in form) {
        const a = omit(form[edIndex].toServer(), 'id')
        const b = omit(this.educations[edIndex].toServer(), 'id')
        if (!isEqual(a, b)) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    educations: {
      handler () {
        if (this.educations) {
          this.initForm()
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions({
    }),
    initForm () {
      this.form = this.educations.map((exp: Education) => new Education(exp))
    },
    onEditClick () {
      this.editMode = true
      if (!this.form.length) {
        this.form.push(new Education({}))
      }
    },
    onAddClick () {
      this.editMode = true
      this.form.push(new Education({}))
    },
    onClearClick (i: number) {
      this.form.splice(i, 1)
      if (!this.form.length) {
        this.form.push(new Education({}))
      }
    },
    onUpdate (ed: Education, i: number) {
      this.form.splice(i, 1, ed)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      const isValid = await Promise.all((this.$refs.education || []).map(async (item: any) => item.validate()))
      if (isValid.every(Boolean)) {
        try {
          this.saving = true
          const experience = await notifiableRequest({
            request: async () => {
              return saveEducationsProfile(this.form
                .filter(isNotEmptyEducation)
                .map((exp: Education) => exp.toServer()))
            },
            title: 'Education',
            successText: 'Your education has been successfully updated.',
            failureText: 'Error updating your education. Please try again.'
          })
          this.form = experience.map(Education.fromServer)
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
