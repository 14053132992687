import Vue from 'vue'
import { mapState } from 'vuex'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import rolebleMixin from '@/mixins/rolebleMixin'
import { REVIEWS_CUSTOMER, REVIEWS_FREELANCER, REVIEWS_NAME } from '@/constants/components/review'
import messageMixin from '../messageMixin'
import GigReviewChatMessage from '@/models-ts/chat/messages/GigReviewChatMessage'
import JobReviewChatMessage from '@/models-ts/chat/messages/JobReviewChatMessage'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin],
  props: {
    message: [GigReviewChatMessage, JobReviewChatMessage],
    room: ChatRoom,
  },
  data () {
    return {
      REVIEWS_NAME,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    fromFreelancer () {
      return this.message.fromFreelancer
    },
    participantRole () {
      return this.isFreelancer ? 'Customer' : 'Freelancer'
    },
    reviewsCat () {
      return this.fromFreelancer ? REVIEWS_CUSTOMER : REVIEWS_FREELANCER
    },
  },
})
