import Vue from 'vue'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { TIME_HOURLY } from '@/constants/backend/service'
import Currency from '@/models-ts/Currency'
import { getCurrency } from '@/utils-ts/currencies'
import { formatUsd } from '@/utils/moneyFormat'
import GigApplication from '@/models-ts/gigs/GigApplication'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'
import Gig from '@/models/backend/Gig'

export default Vue.extend<any, any, any, any>({
  props: {
    application: GigApplication,
    gig: Gig,
  },
  data () {
    return {
    }
  },
  computed: {
    gigModel () {
      return this.application ? this.application.gig : this.gig
    },
    isHourly () {
      return this.gigModel.timeType === GigTimeTypes.HOURLY
    },
    rate () {
      return formatUsd(this.gigModel.rate)
    },
    prefferedCurrencies () {
      const currencies = this.application ? this.application.gig.currencies : this.gig.relations.Currency
      return currencies
        .map((curr: any) => getCurrency({ blockchain: curr.blockchain, value: curr.currency, field: CURRENCY_FIELD_BACKEND_ID }))
        .filter(Boolean)
    },
    formattedCurrencies () {
      return this.prefferedCurrencies
        .slice(0, 3)
        .map((curr: Currency) => curr.name)
        .join(', ')
    },
    timeValue () {
      return this.gigModel.timeValue
    },
  }
})
