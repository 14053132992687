import Vue from 'vue'
import JobApplication from '@/models-ts/job/JobApplication'
import rolebleMixin from '@/mixins/rolebleMixin'
import actionsCardMixin from '../actionsCardMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [actionsCardMixin, rolebleMixin],
  props: {
    application: JobApplication,
    offer: Object
  },
  data () {
    return {
    }
  },
  computed: {
    hasBtns () {
      return this.hasJobWasDone ||
        this.hasCreateOffer ||
        this.hasJobDone ||
        this.hasCreateDispute ||
        this.hasRequestOffer ||
        this.hasStartJob ||
        this.hasPayJob ||
        this.hasRefund ||
        this.hasLeaveReview ||
        this.hasReviewed ||
        this.hasViewInvoice
    },
  },
  methods: {
  }
})
