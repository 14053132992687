import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { Roles } from '@/constants/user/roles'
import AccountType from '@/constants/user/AccountType'
import MyJobs from './MyJobsNew/MyJobs.vue'
import NotificationsPanel from './NotificationsPanel/NotificationsPanel.vue'
import ProfileCard from './ProfileCard/ProfileCard.vue'
import WalletCard from './WalletCard/WalletCard.vue'
import CustomerNew from './CustomerNew/CustomerNew.vue'
import PremiumCard from './PremiumCard/PremiumCard.vue'
import BrowseGigsCard from './BrowseGigsCard/BrowseGigsCard.vue'
import EnterEmail from './EnterEmail/EnterEmail.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    MyJobs,
    NotificationsPanel,
    ProfileCard,
    WalletCard,
    CustomerNew,
    PremiumCard,
    BrowseGigsCard,
    EnterEmail,
  },
  computed: {
    ...mapState('user', {
      userIsLoaded: (state: any) => state.profile.isLoaded,
      userIsLoading: (state: any) => state.profile.isLoading,
      customerIsNewbie: (state: any) => state.customerIsNewbie,
      type: (state: any) => state.type,
      isAddEmailModalDisabled: (state: any) => state.isAddEmailModalDisabled,
    }),
    ...mapGetters({
      activeProfile: 'user/activeProfile',
      isEmailAdded: 'user/isEmailAdded',
    }),
    isCustomer () {
      return this.activeProfile === Roles.CUSTOMER
    },
    accountType () {
      return AccountType.getType(this.type)
    },
    showEnterEmail () {
      return !this.isEmailAdded && !this.isAddEmailModalDisabled
    }
  },
  metaInfo: {
    title: 'Dashboard',
  }
})
