import Vue from 'vue'
import { BROWSE_JOBS, SERVICE_ADD } from '@/constants/routes'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import JobApplication from '@/models-ts/job/JobApplication'
import MyApplicationCard from '../../cards/vacancy/MyApplicationCard/MyApplicationCard.vue'
import GigJobCard from '../../cards/gig/GigJobCard/GigJobCard.vue'
import JobCardFreelancer from '../../cards/job/JobCardFreelancer/JobCardFreelancer.vue'
import GigCard from '../../cards/gig/GigCard/GigCard.vue'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'

type ActiveJob = VacancyApplication | GigOfferListItem | MyGigListItem | JobApplication

export default Vue.extend<any, any, any, any>({
  components: {
    MyApplicationCard,
    GigJobCard,
    GigCard,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      BROWSE_JOBS,
      SERVICE_ADD,
    }
  },
  computed: {
    renderingJobs () {
      return this.jobs.map(this.getComponent)
    },
  },
  methods: {
    getComponent (job: ActiveJob) {
      if (job instanceof VacancyApplication) {
        return {
          component: MyApplicationCard,
          id: job.id,
          props: {
            application: job,
            isClient: true
          }
        }
      } else if (job instanceof MyGigListItem) {
        return {
          component: GigCard,
          id: job.id,
          props: {
            gig: job,
          }
        }
      } else if (job instanceof GigOfferListItem) {
        return {
          component: GigJobCard,
          // @ts-ignore
          id: job.id,
          props: {
            gig: job,
            isFreelancer: true,
          }
        }
      } else {
        return {
          component: JobCardFreelancer,
          // @ts-ignore
          id: job.id,
          props: {
            application: job,
          }
        }
      }
    },
  }
})
