import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import MyVacancyCard from '@/partials/VacancyCards/MyVacancyCard/MyVacancyCard.vue'

const JOBS_LIMIT = 20
export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    MyVacancyCard,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState('myVacancies', {
      vacancies: (state: any) => state.posted.values,
      isLoading: (state: any) => state.posted.isLoading,
      isLoaded: (state: any) => state.posted.isLoaded,
      pagination: (state: any) => state.posted.pagination,
    }),
  },
  async created () {
    await this.load({ limit: JOBS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'myVacancies/loadPosted',
      loadMore: 'myVacancies/loadMorePosted',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.vacancies.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: JOBS_LIMIT, offset: this.vacancies.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    onChangeStatus (vacancy: any, status: any) {
      this.changePostedStatus({
        jobId: vacancy.id,
        status,
      })
    },
  },
  metaInfo: {
    title: 'My Full-time Jobs - Published',
  },
})
