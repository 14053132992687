import Vue from 'vue'
import { OUTPUT_FORMATS } from '@/constants/utils/moneyFormat'
import { convertToLocal, getDateFromString } from '@/utils/date'
import { formatCurrency } from '@/utils/moneyFormat'
import { convertToUsd } from '@/utils-ts/currencies'
import Currency from '@/models-ts/Currency'
import ratesMixin from '@/mixins/ratesMixin'
import { WithdrawsStatus } from '@/constants/withdraw'
import { mapActions, mapState } from 'vuex'
import WithdrawalRequest from '@/models-ts/WithdrawalRequest'

const WithdrawsStatusTitle = {
  [WithdrawsStatus.NEW]: { title: 'In progress', icon: 'clock', style: 'status-pending' },
  [WithdrawsStatus.PENDING]: { title: 'In progress', icon: 'clock', style: 'status-pending' },
  [WithdrawsStatus.DONE]: { title: 'Completed', icon: 'check-circle', style: 'status-success' },
  [WithdrawsStatus.DECLINED]: { title: 'Declined', icon: 'x-circle', style: 'status-error' },
  [WithdrawsStatus.ERROR]: { title: 'Declined', icon: 'x-circle', style: 'status-error' },
}

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  props: {
    currency: Currency,
  },
  computed: {
    ...mapState('balances', {
      loading: (state: any) => state.withdrawals.isLoading,
      withdrawals: (state: any) => state.withdrawals.value,
    }),
    withdrawsFormatted () {
      const withdrawals: Array<WithdrawalRequest> = this.withdrawals
      withdrawals.sort((a, b) => Number(getDateFromString(b.updatedAt)) - Number(getDateFromString(a.updatedAt)))
      return withdrawals.map(w => ({
        ...w,
        createdAt: this.formatDate(w.createdAt, 'DD.MM.YYYY'),
        updatedAt: this.formatDate(w.updatedAt, 'DD.MM.YYYY'),
        amount: this.formatAmount(w),
        amountUsd: this.formatAmountUsd(w),
        status: this.formatStatus(w.status)
      }))
    },
  },
  created () {
    this.loadWithdrawals()
  },
  methods: {
    ...mapActions({
      loadWithdrawals: 'balances/loadWithdrawals',
    }),
    formatDate (date: string) {
      return convertToLocal(date, 'DD.MM.YYYY')
    },
    formatAmount (withdraw: any) {
      if (!withdraw.amount) {
        return 0
      }
      return formatCurrency(
        withdraw.amount,
        {
          currency: this.currency.name,
          outputFormat: OUTPUT_FORMATS.CURRENCY_COMISSION
        }
      )
    },
    formatAmountUsd (withdraw: any) {
      if (!withdraw.amount) {
        return 0
      }
      const usdReward = convertToUsd({
        value: withdraw.amount,
        currency: this.currency,
        rates: this.rates,
      })
      return usdReward
    },
    formatStatus (status: WithdrawsStatus) {
      return WithdrawsStatusTitle[status]
    },
  },
})
