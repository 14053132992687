import { render, staticRenderFns } from "./TransactionsCard.htm?vue&type=template&id=77dfb3ae&scoped=true&"
import script from "./TransactionsCard.js?vue&type=script&lang=js&"
export * from "./TransactionsCard.js?vue&type=script&lang=js&"
import style0 from "./TransactionsCard.scss?vue&type=style&index=0&id=77dfb3ae&lang=scss&scoped=true&"
import style1 from "../WalletCard.scss?vue&type=style&index=1&id=77dfb3ae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77dfb3ae",
  null
  
)

export default component.exports