import get from 'lodash/get'
import { sendApplication } from '@/api/gig'
import { sendApplicationAsCustomer, setInProgressApplication } from '@/api/vacancies/applications'
import { RoomStages } from '@/constants/chat/RoomStages'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import { Stages } from '@/constants/vacancies/statuses'
import rolebleMixin from '@/mixins/rolebleMixin'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import Vue, { PropType } from 'vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { VACANCY_CHAT } from '@/constants/routes'
import Vacancy from '@/models-ts/vacancies/Vacancy'
import snackMixin from '@/mixins/snackMixin'

const CHAT_MOBILE_BP = 768
const MESSAGES_DESKTOP_INDENT = 19
const MESSAGES_MOBILE_INDENT = 83

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  props: {
    disabled: Boolean,
    vacancyStage: Number as PropType<Stages>,
    vacancy: Vacancy,
  },
  data () {
    return {
      message: '',
      sending: false,
      dragOver: false,
      resizeObserver: null,
      windowWidth: null,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
      roomId: (state: any) => state.chatNew.openedRoomId,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
      messages: 'chatNew/getRoomMessages',
    }),
    blocked () {
      return this.room?.isClosed || (this.room?.participants || []).some((user: ChatParticipant) => user.isRemoved || user.isBanned)
    },
    locked () {
      return this.room && !this.room.isUnlocked && this.isFreelancer
    },
    isTemporary () {
      return this.room?.temporary || (this.$route.query.room || '').startsWith('tmp_')
    },
    isArchived () {
      const stage = this.isCustomer ? this.room?.customerStage : this.room?.freelancerStage
      return stage === RoomStages.ARCHIVED
    },
    sendingDisabled () {
      if (this.isTemporary) {
        return this.sending || !!this.messages.length
      }
      return this.disabled || this.sending || !this.room || this.locked || this.isArchived
    },
  },
  watch: {
    xsAndDown () {
      this.calcInputHight()
    },
  },
  mounted () {
    this.initObserver()
    this.onUpdateWidth()
  },
  beforeDestroy () {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    ...mapActions({
      sendMessage: 'chatNew/sendMessage',
      sendFile: 'chatNew/sendFile',
    }),
    initObserver () {
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(this.onUpdateWidth)
        this.resizeObserver.observe(document.body)
      }
    },
    onKeyDown (e: KeyboardEvent) {
      if (!this.isMobileLx) {
        if (e.key === 'Enter') {
          if (!e.shiftKey) {
            this.onSendClick()
            e.preventDefault()
            e.stopPropagation()
          }
        }
      }
    },
    async onSendClick () {
      if (!this.sendingDisabled) {
        try {
          const message = this.message.trim()
          if (message) {
            this.sending = true
            if (this.isTemporary) {
              if (this.room.type === RoomTypes.GIG) {
                await sendApplication({
                  gig_id: this.room.entity.id,
                  comment: message,
                })
              } else if (this.room.type === RoomTypes.VACANCY) {
                const freelancerId = Number(
                  (this.room as ChatRoom).participants
                    .find(user => String(user.userId) !== String(this.userId))?.userId,
                )
                try {
                  const { id: applicationId }: { id: number } = await sendApplicationAsCustomer({
                    vacancyId: this.room.entity.id,
                    freelancerId,
                    startMessage: this.message,
                  })
                  // this.$router.replace({
                  //   name: VACANCY_CHAT,
                  //   params: { id: this.vacancy.id, slug: this.vacancy.slug },
                  //   query: {
                  //     room: ChatRoom.generateRoomId({
                  //       type: RoomTypes.VACANCY,
                  //       applicationId: applicationId,
                  //       freelancerId: freelancerId,
                  //       customerId: this.myUserId,
                  //     }),
                  //   },
                  // })
                } catch (err) {
                  console.error('Err temporary:', err)
                  this.openSnackbar({
                    type: this.SnackTypes.FAILURE,
                    text: `Error creating application for chat. Please try again.`,
                  })
                }
              }
              this.sending = false
            } else {
              if (this.room.type === RoomTypes.VACANCY && !this.room.isUnlocked && this.vacancyStage === Stages.NEW) {
                this.sending = true
                await setInProgressApplication(this.room.applicationId)
                this.sending = false
              }
              await this.sendMessage({ message, roomId: this.roomId })
              this.message = ''
              this.clearInput()
              this.$emit('message-sent')
            }
          }
        } finally {
          this.sending = false
        }
      }
    },
    async onUploadFile (e: any) {
      if (!this.sendingDisabled) {
        this.dragOver = false
        e.preventDefault()
        const files = (e.dataTransfer ? Array.from(e.dataTransfer.files) : (e.target as HTMLInputElement).files)
        if (files?.length) {
          const file = files[0]
          await this.sendFile({ file, roomId: this.roomId })
          this.$emit('message-sent')
        }
        (e.target as HTMLInputElement).value = ''
      }
    },
    clearInput () {
      this.$nextTick(() => {
        if (!this.message) {
          const textarea = this.$refs.textarea?.$el
          if (textarea) {
            const textareaEl = textarea.querySelector('textarea')
            textareaEl.value = ''
            this.calcInputHight()
          }
        }
      })
    },
    onAttachFileClick () {
      const input = this.$refs.fileInput
      if (input) {
        input.click()
      }
    },
    onMessageInput (value: string, e: Event | null) {
      this.message = value
      this.calcInputHight()
    },
    onUpdateWidth () {
      this.windowWidth = Math.max(
        get(document, 'documentElement.clientWidth', 0),
        window.innerWidth,
      )
      this.calcInputHight()
    },
    calcInputHight () {
      const textarea = this.$refs.textarea?.$el
      const textareaEl = textarea?.querySelector('textarea')
      if (textareaEl) {
        const lineH = 24
        const borderH = 24
        const stringCount = !this.message ? 1 : Math.floor((textareaEl.scrollHeight - borderH) / lineH)
        const maxRows = this.xsAndDown ? 2 : 5
        const height = Math.min(stringCount, maxRows) * lineH + borderH
        textareaEl.style.height = `${height}px`
        const messagesIndent = this.windowWidth > CHAT_MOBILE_BP ? MESSAGES_DESKTOP_INDENT : MESSAGES_MOBILE_INDENT
        this.$emit('indent', height + messagesIndent)
      }
    },
  },
})
