import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import OfferCard from '@/partials/GigCards/OfferCard/OfferCard.vue'

const GIGS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin],
  components: {
    OfferCard,
    InfiniteLoading,
  },
  data () {
    return {
      anchor: '#gig-cards',
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState('myGigs', {
      isLoading: (state: any) => state.freelancerOffersList.isLoading,
      isLoaded: (state: any) => state.freelancerOffersList.isLoaded,
      pagination: (state: any) => state.freelancerOffersList.pagination,
      offers: (state: any) => state.freelancerOffersList.values,
    }),
  },
  async created () {
    await this.load({ limit: GIGS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'myGigs/getFreelancerNegotiationOffersList',
      loadMore: 'myGigs/loadMoreFreelancerNegotiationOffersList',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.offers.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: GIGS_LIMIT, offset: this.offers.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    onRefreshList () {
      this.load({ limit: GIGS_LIMIT, offset: 0 })
      this.infiniteId = +new Date()
    },
  },
  metaInfo: {
    title: 'My Gigs - Offers',
  },
})
