import BigNumber from 'bignumber.js'
import { Blockchain } from '@/constants/blockchain'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { EVENT_DISTRIBUTED_FOR_PARTIALS_V2 } from '@/constants/backend/transaction'
import { getCurrency } from '@/utils/currency'
import AbstractTransaction from './AbstractTransaction'

class DisputeTransaction extends AbstractTransaction {
  static fromServer (data, userId) {
    if (data) {
      const job = data.relations.Job || data.relations.GigJob
      const isCustomer = job?.customer_id === userId
      const currency = getCurrency({
        blockchain: +(job.blockchain || Blockchain.Ethereum),
        value: +job.currency,
        field: CURRENCY_FIELD_BACKEND_ID
      })
      let amount
      if (isCustomer) {
        if (data.event === EVENT_DISTRIBUTED_FOR_PARTIALS_V2) {
          amount = new BigNumber(data.tx_data.customerPayAmount)
        } else {
          amount = data.tx_data.customerAmount._hex
            ? new BigNumber(data.tx_data.customerAmount._hex, 16)
            : new BigNumber(data.tx_data.customerAmount)
        }
      } else {
        if (data.event === EVENT_DISTRIBUTED_FOR_PARTIALS_V2) {
          amount = new BigNumber(data.tx_data.freelancerPayAmount)
        } else {
          amount = data.tx_data.freelancerAmount._hex
            ? new BigNumber(data.tx_data.freelancerAmount._hex, 16)
            : new BigNumber(data.tx_data.freelancerAmount)
        }
      }
      amount = amount.dividedBy(currency.baseUnits)
      const wallet = isCustomer ? job.customer_wallet : job.freelancer_wallet
      return new DisputeTransaction({
        ...data,
        icon: 'dispute.png',
        title: 'Dispute Resolution',
        wallet: wallet.toLowerCase(),
        amount,
        currency,
      })
    }
  }
}

export default DisputeTransaction
