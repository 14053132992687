import DashboardProfileInfo from '@/models-ts/dashboard/DashboardProfileInfo'
import LoadableModel from '@/models-ts/LoadableModel'
import JobApplication from '@/models-ts/job/JobApplication'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'

export type CustomerJobType = MyCustomerJobListItem
export type CustomerGigType = MyGigApplication | GigOfferListItem
export type CustomerVacancyType = MyVacancyListItem
export type FreelancerJobType = JobApplication
export type FreelancerGigType = MyGigListItem | MyGigApplication | GigOfferListItem
export type FreelancerVacancyType = VacancyApplication

export type DashboardJobType = CustomerJobType |
                               CustomerGigType |
                               CustomerVacancyType |
                               FreelancerJobType |
                               FreelancerGigType |
                               FreelancerVacancyType

export function isGigType (job: DashboardJobType) {
  return job instanceof MyGigListItem || job instanceof MyGigApplication || job instanceof GigOfferListItem
}

export function isVacancyType (job: DashboardJobType) {
  return job instanceof MyVacancyListItem || job instanceof VacancyApplication
}

export interface IDashboardState {
  profileInfo: LoadableModel<DashboardProfileInfo>
  customerActiveJobs: LoadableModel<Array<MyCustomerJobListItem>>
  customerArchiveJobs: LoadableModel<Array<MyCustomerJobListItem>>
  customerDraftJobs: LoadableModel<Array<MyCustomerJobListItem>>
  customerActiveGigs: LoadableModel<Array<MyGigApplication | GigOfferListItem>>
  customerArchiveGigs: LoadableModel<Array<GigOfferListItem>>
  customerActiveVacancies: LoadableModel<Array<MyVacancyListItem>>
  customerArchiveVacancies: LoadableModel<Array<MyVacancyListItem>>
  customerDraftVacancies: LoadableModel<Array<MyVacancyListItem>>
  freelancerActiveVacancies: LoadableModel<Array<VacancyApplication>>
  freelancerArchiveVacancies: LoadableModel<Array<VacancyApplication>>
  freelancerActiveJobs: LoadableModel<Array<JobApplication>>
  freelancerArchiveJobs: LoadableModel<Array<JobApplication>>
  freelancerActiveGigs: LoadableModel<Array<MyGigListItem | MyGigApplication | GigOfferListItem>>
  freelancerDraftGigs: LoadableModel<Array<MyGigListItem>>
  freelancerArchiveGigs: LoadableModel<Array<GigOfferListItem>>
}
