import Vue from 'vue'
import InfoSection from './InfoSection/InfoSection.vue'
import ContactsSection from './ContactsSection/ContactsSection.vue'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import FullTimeInfo from '@/models-ts/user/profiles/freelancer/FullTimeInfo'
import Channel from '@/models-ts/user/Channel'

export default Vue.extend<any, any, any, any>({
  components: {
    InfoSection,
    ContactsSection,
  },
  props: {
    profile: MyFreelancerProfile,
  },
  data () {
    return {
    }
  },
  methods: {
    onFulltimeInfoUpdate (info: FullTimeInfo) {
      this.$emit('update', new MyFreelancerProfile({ ...this.profile, fulltimeInfo: info }))
    },
    onChannelsUpdate (ch: Array<Channel>) {
      this.$emit('update', new MyFreelancerProfile({
        ...this.profile,
        channels: ch,
      }))
    },
  }
})
