import { ModerationStages } from '@/constants/backend/ModerationStages'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import Vue from 'vue'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import Badges from './Badges'

export default Vue.extend({
  props: {
    application: MyGigApplication,
  },
  computed: {
    badge () {
      const app = this.application
      if (app.moderationStage === ModerationStages.MANUAL) return Badges.Moderation
      if (app.moderationStage === ModerationStages.FAILED) return Badges.Declined

      const offer = app.offer
      if (!offer) return Badges.OfferRequested
      if (offer?.stage === GigOfferStages.NEW) return Badges.OfferSent
      if ([GigOfferStages.DECLINED_BY_CUSTOMER, GigOfferStages.DECLINED_BY_FREELANCER].includes(offer.stage)) {
        return Badges.OfferDeclined
      }
      if ([GigOfferStages.ACCEPTED_BY_CUSTOMER, GigOfferStages.ACCEPTED_BY_FREELANCER].includes(offer.stage)) {
        return Badges.OfferAccepted
      }
    },
  },
})
