import { render, staticRenderFns } from "./VacancyApplicationDeclinedChatMessage.htm?vue&type=template&id=5e2dc214&scoped=true&"
import script from "./VacancyApplicationDeclinedChatMessage.ts?vue&type=script&lang=js&"
export * from "./VacancyApplicationDeclinedChatMessage.ts?vue&type=script&lang=js&"
import style0 from "./VacancyApplicationDeclinedChatMessage.scss?vue&type=style&index=0&id=5e2dc214&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2dc214",
  null
  
)

export default component.exports