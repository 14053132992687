import { SECURITY, NOTIFICATIONS, PROFILE, MODULE } from '@/constants/routes'
import Notifications from './Notifications/Notifications.vue'
import Security from './Security/Security.vue'

export default {
  components: {
    Notifications,
    Security,
  },
  data () {
    return { SECURITY, NOTIFICATIONS }
  },
}
