import Vue from 'vue'
import responseMixin from '@/mixins/responseMixin'
import { Subscriptions, Subscription } from '@/constants/backend/subscriptions'
import { getSubscriptions, subscribe, unsubscribe } from '@/api/subscriptions'
import snackMixin from '@/mixins/snackMixin'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

type SubscriptionState = { state: boolean, loading: boolean }
type SwitchModel = Record<Subscription, SubscriptionState>

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  data () {
    const subscriptions = Object.values(Subscriptions)
      .reduce<SwitchModel>((sub, cat) => ({ ...sub, [cat]: { state: false, loading: false } }), <SwitchModel>{})
    return {
      ...Subscriptions,
      loading: true,
      subscriptions,
    }
  },
  async created () {
    try {
      const subscriptions = await getSubscriptions()
      subscriptions.forEach(({ category }: { category: Subscription }) => {
        this.subscriptions[category].state = true
      })
      this.loading = false
    } catch (e) {
      console.error(e)
      this.parseError(e)
    }
  },
  methods: {
    onSwitchInput (category: Subscription) {
      if (this.subscriptions[category].state) {
        this.unsubscribeFromCategory(category)
      } else {
        this.subscribeOnCategory(category)
      }
    },
    onSwitchOptionClick (category: Subscription, isSubscirbe: boolean) {
      if (!this.subscriptions[category].loading && this.subscriptions[category].state !== isSubscirbe) {
        if (isSubscirbe) {
          this.subscribeOnCategory(category)
        } else {
          this.unsubscribeFromCategory(category)
        }
      }
    },
    async subscribeOnCategory (category: Subscription) {
      if (!this.subscriptions[category].loading) {
        try {
          this.subscriptions[category].loading = true
          this.subscriptions[category].state = true
          await subscribe(category)
          googleAnalyticsV2.send({
            event: 'settings-notifications-updates-saved',
          })
        } catch (e) {
          this.subscriptions[category].state = false
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'Subscription setup error. Please try again.',
          })
        } finally {
          this.subscriptions[category].loading = false
        }
      }
    },
    async unsubscribeFromCategory (category: Subscription) {
      if (!this.subscriptions[category].loading) {
        try {
          this.subscriptions[category].loading = true
          this.subscriptions[category].state = false
          await unsubscribe(category)
          googleAnalyticsV2.send({
            event: 'settings-notifications-updates-saved',
          })
        } catch (e) {
          this.subscriptions[category].state = true
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: 'Error unsubscribing. Please try again.',
          })
        } finally {
          this.subscriptions[category].loading = false
        }
      }
    }
  },
  metaInfo: {
    title: 'Notifications',
  },
})
