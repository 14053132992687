import get from 'lodash/get'
import { mapActions, mapMutations } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { Statuses } from '@/constants/vacancies/statuses'
import NotificationListItem from '@/models-ts/dashboard/NotificationListItem'
import { publishVacancy } from '@/api/vacancies'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'

export default {
  methods: {
    ...mapActions({
      executeBtnRequest: 'notifications/executeBtnRequest',
    }),
    ...mapMutations({
      setNotificationData: 'notifications/setNotificationData',
    }),
    onPublishVacancyClick (note: NotificationListItem) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: false,
        request: () => notifiableRequest({
          request: async () => {
            const vacancy = await publishVacancy(note.params.vacancy?.id)
            googleAnalyticsV2.send({
              event: 'ftj-published',
              ftj_id: note.params.vacancy?.id,
            })
            this.setNotificationData({ id: note.id, path: 'params.vacancy', property: 'status', data: Statuses.PUBLISHED })
            return vacancy.moderation_stage
          },
          successText: (moderation_stage) => moderation_stage === VacancyModerationStages.MANUAL
            ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
            : `Job${note.params.vacancy?.name ? `: ${note.params.vacancy?.name}` : ''} was successfully published`,
          failureText: (e) => {
            const defaultErr = 'Job publishing error'
            return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
          },
        }),
      })
    },
  },
}
