import Vue from 'vue'
import get from 'lodash/get'
import messageMixin from '../messageMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import UserBannedChatMessage from '@/models-ts/chat/messages/UserBannedChatMessage'
import ChatRoom from '@/models-ts/chat/ChatRoom'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin],
  props: {
    message: UserBannedChatMessage,
    room: ChatRoom,
  },
  computed: {
    participantName () {
      const res = this.message.body.message.match(/\s* (.*) is banned/)
      return get(res, '[1]', '')
    },
  },
})
