import Vue from 'vue'
import BigNumber from 'bignumber.js'
import { mapActions } from 'vuex'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import Skill from '@/models-ts/Skill'
import { BUTTON_TRANSPARENT_MEDIUM } from '@/constants/components/button'
import { ExperienceExplanation } from '@/constants/vacancies/experiences'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import {
  BROWSE_VACANCIES_BY_ROLE,
  BROWSE_VACANCIES_BY_SKILL,
  CUSTOMER_PROFILE,
  VACANCY_DETAILS_ADMIN_APPLICATIONS,
  VACANCY_EDIT
} from '@/constants/routes'
import { getHumanDate } from '@/utils/date'
import { formatUsd } from '@/utils/moneyFormat'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import Button from '@/models-ts/ui/Button'
import { deleteVacancy, publishVacancy, unArchiveVacancy } from '@/api/vacancies'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import VacancyStatusBadge from '@/partials/StatusBadges/VacancyStatusBadge/VacancyStatusBadge.vue'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import CardActionMenu from '../../components/CardActionMenu/CardActionMenu.vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { getBadge } from '@/partials/StatusBadges/VacancyStatusBadge/VacancyStatusBadge'
import Badges from '@/partials/StatusBadges/VacancyStatusBadge/Badges'
import { formatCardsDescription } from '@/utils-ts/strings'
import zendeskMixin from '@/mixins/zendeskMixin'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [zendeskMixin, snackMixin],
  components: {
    CardActionMenu,
    JobTypeBadge,
    VacancyLocationTooltip,
    VacancyStatusBadge,
  },
  props: {
    vacancy: [MyVacancyListItem],
  },
  data () {
    return {
      BUTTON_TRANSPARENT_MEDIUM,
      CUSTOMER_PROFILE,
      VACANCY_DETAILS_ADMIN_APPLICATIONS,
      VACANCY_EDIT,
      publishing: false,
      deleting: false,
      unArchiving: false,
      Badges,
      Stages,
    }
  },
  computed: {
    description () {
      return formatCardsDescription(this.vacancy.description)
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    createdAt () {
      const date: string = this.vacancy.published_at || this.vacancy.updated_at
      return getHumanDate(date)
    },
    userId () {
      return this.vacancy.user?.id
    },
    userAvatar () {
      return this.vacancy.user?.avatar
    },
    userType () {
      return this.vacancy.user?.type
    },
    userName () {
      return this.vacancy.user?.name
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    experience () {
      const vacancy = this.vacancy as MyVacancyListItem
      if (vacancy.work_experience) {
        return ExperienceExplanation[vacancy.work_experience]
      }
    },
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    salaryFrom () {
      const salaryFrom = new BigNumber(this.vacancy.salary_from).div(12)
      return salaryFrom.gt(100)
        ? formatUsd(salaryFrom, 0)
        : formatUsd(salaryFrom, 2)
    },
    salaryTo () {
      const salaryTo = new BigNumber(this.vacancy.salary_to).div(12)
      return salaryTo.gt(100)
        ? formatUsd(salaryTo, 0)
        : formatUsd(salaryTo, 2)
    },
    roleTag () {
      return this.vacancy.primaryRole?.id
        ? {
          text: this.vacancy.primaryRole.name,
          link: { name: BROWSE_VACANCIES_BY_ROLE, params: { role: this.vacancy.primaryRole.url } },
        }
        : null
    },
    skills () {
      const skills = (this.vacancy.skills || []).map((skill: Skill) => ({
        text: skill.name,
        link: {
          name: BROWSE_VACANCIES_BY_SKILL,
          params: { skill: skill.url }
        }
      }))
      const tags = []
      if (this.roleTag) {
        tags.push(this.roleTag)
      }
      return [...tags, ...skills].slice(0, 2)
    },
    moreSkills (): number | undefined {
      if (this.skills.length > 1) {
        return this.skills.length - 1
      }
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
    failedModeration () {
      return this.vacancy.moderationStage === VacancyModerationStages.FAILED
    },
    passedModeration () {
      return this.vacancy.moderationStage === VacancyModerationStages.PASSED
    },
    vacancyStatus () {
      return getBadge(this.vacancy.stage, this.vacancy.moderationStage, this.vacancy.status)
    },
    hasArchiveAction () {
      return this.vacancyStatus === Badges.Draft || this.vacancyStatus === Badges.Moderation
    },
    hasPublishItem () {
      return this.vacancyStatus === Badges.Draft ||
        this.vacancyStatus === Badges.Moderation ||
        this.vacancyStatus === Badges.Published
    },
  },
  methods: {
    ...mapActions({
      unpublish: 'myVacancies/unpublish',
      openModal: 'ui/openModal',
    }),
    formatUsd,
    async onPublishItemClick () {
      if (this.isPublished) {
        await this.onUnpublishClick()
      } else {
        await this.onPublishClick()
      }
    },
    async onPublishClick () {
      try {
        this.publishing = true
        const vacancy = await notifiableRequest({
          request: () => publishVacancy(this.vacancy.id),
          title: 'Publish job',
          successText: (vacancy) => vacancy.moderation_stage === VacancyModerationStages.MANUAL
            ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
            : `Job: ${this.vacancy.name} was successfully published`,
          failureText: 'Error publishing Full-time Job. Please try again.'
        })
        googleAnalyticsV2.send({
          event: 'ftj-published',
          ftj_id: this.vacancy.id,
        })
        this.$emit('changeStatus', {
          status: Statuses.PUBLISHED,
          vacancy: new MyVacancyListItem({
            ...this.vacancy,
            status: vacancy.status,
            moderationStage: vacancy.moderation_stage,
          })
        })
      } finally {
        this.publishing = false
      }
    },
    async onUnpublishClick () {
      try {
        this.publishing = true
        this.openModal({
          component: 'lx-composite-modal-new',
          props: {
            title: 'Are you sure you want to unpublish this Full-time Job?',
            text: `Once a Full-time Job has been unpublished, all talent applications wil be automatically declined.`,
            buttons: [
              new Button({
                text: 'Cancel',
                classes: 'lx-transparent-blue-btn',
              }),
              new Button({
                text: 'Unpublish',
                classes: 'lx-blue-btn',
                onClick: async () => {
                  try {
                    this.publishing = true
                    await notifiableRequest({
                      request: () => this.unpublish(this.vacancy.id),
                      title: 'Unpublish job',
                      successText: `You have successfully unpublished your Full-time Job.`,
                      failureText: 'Error unpublishing Full-time Job. Please try again.'
                    })
                    googleAnalyticsV2.send({
                      event: 'ftj-drafted',
                      ftj_id: this.vacancy.id,
                    })
                    this.$emit('changeStatus', {
                      status: Statuses.DRAFT,
                      vacancy: new MyVacancyListItem({
                        ...this.vacancy,
                        status: Statuses.DRAFT,
                      })
                    })
                  } finally {
                    this.publishing = false
                  }
                },
              }),
            ]
          }
        })
      } finally {
        this.publishing = false
      }
    },
    onArchiveClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ArchiveVacancy/ArchiveVacancy.vue'),
          title: 'Archive Job',
          props: {
            vacancy: this.vacancy,
            onComplete: () => {
              this.$emit('archive', new MyVacancyListItem({
                ...this.vacancy,
                stage: Stages.ARCHIVED,
              }))
            },
          }
        }
      })
    },
    async onUnArchiveClick () {
      this.unArchiving = true
      await notifiableRequest({
        request: async () => {
          try {
            await unArchiveVacancy(this.vacancy.id)
            this.$emit('unArchive', new MyVacancyListItem({
              ...this.vacancy,
              stage: Stages.NEW
            }))
          } catch (err) {
            throw err
          } finally {
            this.unArchiving = false
          }
        },
        title: 'Offer accepted',
        successText: `Job <b>${this.vacancy.name}</b> has been unarchived.`,
        loadingText: `Job <b>${this.vacancy.name}</b> is being unarchived.`,
        failureText: 'Error unarchiving Job. Please try again.',
      })
    },
    async onClickDeleteBtn () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Delete Full-time Job',
          text: `Are you sure you want to remove <b>${this.vacancy.name}</b>? This Full-time Job will be permanently deleted.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Delete',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    request: async () => {
                      await deleteVacancy(this.vacancy.id)
                    },
                    title: 'Delete Full-time Job',
                    successText: `Job: ${this.vacancy.name} has been deleted`,
                    failureText: 'Error deleting Full-time Job. Please try again.',
                  })
                  this.$emit('delete', this.vacancy)
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        }
      })
    },
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
  },
})
