import ImageChatMessage from '@/models-ts/chat/messages/ImageChatMessage'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import ImageMessage from './ImageMessage/ImageMessage.vue'

export default Vue.extend({
  components: {
    ImageMessage,
  },
  computed: {
    ...mapGetters({
      messages: 'chatNew/getRoomMessages',
    }),
    images () {
      return this.messages.filter((msg: any) => msg instanceof ImageChatMessage)
    },
  },
})
