import Vue from 'vue'
import { DASHBOARD } from '@/constants/routes'
import { mapState } from 'vuex'
import { RootState } from '@/store'

export default Vue.extend({
  computed: {
    ...mapState<RootState>({
      isLoggedIn: (state: RootState) => state.app.authorized,
    }),
  },
  created () {
    if (!this.$route.query?.url) {
      this.$router.replace({ name: DASHBOARD })
    }
  },
  methods: {
    onClickBack () {
      this.$router.back()
    },
    onClickProceed () {
      const url: string = `${this.$route.query?.url}`
      window.opener = null
      window.open(url, '_blank')
      this.$router.back()
    }
  }
})
