import { render, staticRenderFns } from "./ThreadTab.htm?vue&type=template&id=20b23866&scoped=true&"
import script from "./ThreadTab.ts?vue&type=script&lang=js&"
export * from "./ThreadTab.ts?vue&type=script&lang=js&"
import style0 from "./ThreadTab.scss?vue&type=style&index=0&id=20b23866&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b23866",
  null
  
)

export default component.exports