import Vue from 'vue'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import FreelancerDetails from '@/models-ts/user/details/FreelancerDetails'

export default Vue.extend({
  props: {
    application: VacancyApplication,
    freelancer: FreelancerDetails,
  },
  computed: {
    position () {
      return this.application?.freelancer?.position || this.freelancer?.position
    },
    cvFileSrc () {
      return this.application?.cvFile?.src || this.freelancer?.cv?.[0]?.src
    },
  },
})
