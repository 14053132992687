import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import roomsMixin from '../roomsMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [roomsMixin],
  computed: {
    ...mapState({
      rooms: (state: any) => Object.values(state.chatNew.archivedRooms.values),
      isLoaded: (state: any) => state.chatNew.archivedRooms.isLoaded,
      isLoading: (state: any) => state.chatNew.archivedRooms.isLoading,
      pagination: (state: any) => state.chatNew.archivedRooms.pagination,
    }),
  },
  methods: {
    ...mapActions({
      loadMore: 'chatNew/loadMoreArchivedRooms'
    }),
  },
})
