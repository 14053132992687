import Vue from 'vue'
import {
  isGigType,
  isVacancyType,
} from '@/store/client/modules/dashboard/types'

export default Vue.extend<any, any, any, any>({
  props: {
    job: Object,
  },
  computed: {
    jobType () {
      if (isVacancyType(this.job)) {
        return {
          title: 'Full-time Job',
          badgeClass: 'ftj',
        }
      } else if (isGigType(this.job)) {
        return {
          title: 'Gig',
          badgeClass: 'gig',
        }
      } else {
        return {
          title: 'Job',
          badgeClass: '',
        }
      }
    },
  },
})
