import Vue from 'vue'
import RoomClosedChatMessage from '@/models-ts/chat/messages/RoomClosedChatMessage'
import messageMixin from '../messageMixin'
import { mapState } from 'vuex'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import ChatRoom from '@/models-ts/chat/ChatRoom'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin],
  props: {
    message: RoomClosedChatMessage,
    room: ChatRoom,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    participant () {
      return this.room.participants.find((user: ChatParticipant) => user.userId !== String(this.userId))
    },
    msgFirst () {
      return `${this.isOwn ? 'You don\'t' : `${this.participant.name} doesn't`} want to continue this conversation.`
    },
    msgSecond () {
      return `You're no longer able to send messages to ${this.participant.name} according to this job.`
    },
  },
})
