import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import { isBefore } from '@/utils/date'
import rolebleMixin from '@/mixins/rolebleMixin'
import CustomerActiveTab from './tabs/CustomerActiveTab/CustomerActiveTab.vue'
import CustomerDraftTab from './tabs/CustomerDraftTab/CustomerDraftTab.vue'
import CustomerArchiveTab from './tabs/CustomerArchiveTab/CustomerArchiveTab.vue'
import FreelancerActiveTab from './tabs/FreelancerActiveTab/FreelancerActiveTab.vue'
import FreelancerDraftTab from './tabs/FreelancerDraftTab/FreelancerDraftTab.vue'
import FreelancerArchiveTab from './tabs/FreelancerArchiveTab/FreelancerArchiveTab.vue'
import { RootState } from '@/store'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import { VacancyModerationStages } from '@/constants/vacancies/vacancyModerationStages'

const sortHandler = (a: any, b: any) => {
  return isBefore(a.sortField, b.sortField) ? 1 : -1
}

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    CustomerActiveTab,
    CustomerDraftTab,
    CustomerArchiveTab,
    FreelancerActiveTab,
    FreelancerDraftTab,
    FreelancerArchiveTab,
  },
  data () {
    return {
      activeTab: 'active',
      isTabClicked: false,
      isLoaded: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      customerActiveJobs: (state: RootState) => state.dashboard.customerActiveJobs.value,
      customerDraftJobs: (state: RootState) => state.dashboard.customerDraftJobs.value,
      customerArchiveJobs: (state: RootState) => state.dashboard.customerArchiveJobs.value,
      customerActiveGigs: (state: RootState) => state.dashboard.customerActiveGigs.value,
      customerArchiveGigs: (state: RootState) => state.dashboard.customerArchiveGigs.value,
      customerActiveVacancies: (state: RootState) => state.dashboard.customerActiveVacancies.value,
      customerDraftVacancies: (state: RootState) => state.dashboard.customerDraftVacancies.value,
      customerArchiveVacancies: (state: RootState) => state.dashboard.customerArchiveVacancies.value,

      freelancerActiveVacancies: (state: RootState) => state.dashboard.freelancerActiveVacancies.value,
      freelancerArchiveVacancies: (state: RootState) => state.dashboard.freelancerArchiveVacancies.value,
      freelancerActiveGigs: (state: RootState) => state.dashboard.freelancerActiveGigs.value,
      freelancerDraftGigs: (state: RootState) => state.dashboard.freelancerDraftGigs.value,
      freelancerArchiveGigs: (state: RootState) => state.dashboard.freelancerArchiveGigs.value,
      freelancerActiveJobs: (state: RootState) => state.dashboard.freelancerActiveJobs.value,
      freelancerArchiveJobs: (state: RootState) => state.dashboard.freelancerArchiveJobs.value,
    }),
    ...mapGetters({
      isCustomerJobsLoading: 'dashboard/isCustomerJobsLoading',
      isCustomerJobsLoaded: 'dashboard/isCustomerJobsLoaded',
      isFreelancerJobsLoading: 'dashboard/isFreelancerJobsLoading',
      isFreelancerJobsLoaded: 'dashboard/isFreelancerJobsLoaded',
    }),
    customerActiveValues () {
      return [
        ...this.customerActiveJobs,
        ...this.customerActiveGigs,
        ...this.customerActiveVacancies,
      ].sort(sortHandler)
    },
    customerDraftValues () {
      return [
        ...this.customerDraftJobs,
        ...this.customerDraftVacancies,
      ].sort(sortHandler)
    },
    customerArchiveValues () {
      return [
        ...this.customerArchiveJobs,
        ...this.customerArchiveGigs,
        ...this.customerArchiveVacancies,
      ].sort(sortHandler)
    },
    freelancerActiveValues () {
      return [
        ...this.freelancerActiveVacancies,
        ...this.freelancerActiveGigs,
        ...this.freelancerActiveJobs,
      ].sort(sortHandler)
    },
    freelancerDraftValues () {
      return [
        ...this.freelancerDraftGigs,
      ].sort(sortHandler)
    },
    freelancerArchiveValues () {
      return [
        ...this.freelancerArchiveVacancies,
        ...this.freelancerArchiveGigs,
        ...this.freelancerArchiveJobs,
      ].sort(sortHandler)
    },
    hasNewOffers () {
      const hasJobOffers = this.customerActiveJobs.some((job: MyCustomerJobListItem) => {
        return job.moderationStage === ModerationStages.PASSED && job.offersCountNew > 0
      })
      const hasVacancyOffers = this.customerActiveVacancies.some((job: MyVacancyListItem) => {
        return job.moderationStage === VacancyModerationStages.PASSED && job.applicationsCountNew > 0
      })
      return hasJobOffers || hasVacancyOffers
    },
  },
  watch: {
    async isFreelancer () {
      this.isTabClicked = false
      await this.loadJobs()
      this.isLoaded = true
    },
    isLoaded (newVal) {
      if (newVal && !this.isTabClicked) {
        if (
          (!this.isFreelancer && this.customerActiveValues.length > 0) ||
          (this.isFreelancer && this.freelancerActiveValues.length) > 0
        ) {
          this.activeTab = 'active'
        } else {
          this.activeTab = 'draft'
        }
      }
    },
  },
  async created () {
    await this.loadJobs()
    this.isLoaded = true
  },
  methods: {
    ...mapActions({
      getCustomerJobs: 'dashboard/getCustomerJobs',
      getCustomerGigs: 'dashboard/getCustomerGigs',
      getCustomerVacancies: 'dashboard/getCustomerVacancies',
      getFreelancerJobs: 'dashboard/getFreelancerJobs',
      getFreelancerGigs: 'dashboard/getFreelancerGigs',
      getFreelancerVacancies: 'dashboard/getFreelancerVacancies',
    }),
    async loadJobs () {
      if (this.isFreelancer) {
        await Promise.all([
          this.getFreelancerJobs(),
          this.getFreelancerVacancies(),
          this.getFreelancerGigs()
        ])
      } else {
        await Promise.all([
          this.getCustomerJobs(),
          this.getCustomerGigs(),
          this.getCustomerVacancies()
        ])
      }
    },
    getTabJobsCount (tabValue: string) {
      let values = []
      if (tabValue === 'active') {
        values = this.isFreelancer ? this.freelancerActiveValues : this.customerActiveValues
      } else if (tabValue === 'draft') {
        values = this.isFreelancer ? this.freelancerDraftValues : this.customerDraftValues
      } else {
        values = this.isFreelancer ? this.freelancerArchiveValues : this.customerArchiveValues
      }
      return values.length
    },
    onInputTab (activeTab: string) {
      this.isTabClicked = true
      this.activeTab = activeTab
    },
    onChangeTab (activeTab: string) {
      this.activeTab = activeTab
    }
  }
})
