import { render, staticRenderFns } from "./JobTypeBadge.htm?vue&type=template&id=232b6770&scoped=true&"
import script from "./JobTypeBadge.ts?vue&type=script&lang=js&"
export * from "./JobTypeBadge.ts?vue&type=script&lang=js&"
import style0 from "./JobTypeBadge.scss?vue&type=style&index=0&id=232b6770&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232b6770",
  null
  
)

export default component.exports