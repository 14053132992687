import Vue from 'vue'
import { mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { DASHBOARD, PROFILE_SETTINGS } from '@/constants/routes'
import FreelancerProfile from './FreelancerProfile/FreelancerProfile.vue'
import CustomerProfile from './CustomerProfile/CustomerProfile.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    FreelancerProfile,
    CustomerProfile,
  },
  data () {
    return {
      DASHBOARD,
      PROFILE_SETTINGS,
    }
  },
  computed: {
    ...mapState('user', {
      userInfoLoaded: (state: any) => state.profile.isLoaded,
      userInfoLoading: (state: any) => state.profile.isLoading,
    }),
    isLoaded () {
      return this.activeRole && !this.userInfoLoading
    },
  },
  metaInfo: {
    title: 'Profile'
  }
})
