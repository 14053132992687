import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import Balances from '@/models/Balances'
import BonusesCard from '@/partials/BonusesCard/BonusesCard.vue'
import ratesMixin from '@/mixins/ratesMixin'
import responseMixin from '@/mixins/responseMixin'
import { convertToUsd, getCurrency } from '@/utils/currency'
import { formatCurrency, formatUsdWithZeros } from '@/utils/moneyFormat'
import { WalletGroup } from '@/constants/blockchain'
import { getBalances } from '@/utils-ts/balance'
import snackMixin from '@/mixins/snackMixin'
import {
  WALLET_GROUP_CLOUD,
  WALLET_GROUP_METAMASK,
  WALLET_GROUP_WALLET_CONNECT,
} from '@/constants/blockchain/wallet'
import BalanceCard from './BalanceCard/BalanceCard.vue'
import TransactionsCard from './TransactionsCard/TransactionsCard.vue'
import WalletsCard from './WalletsCard/WalletsCard.vue'
import EscrowCard from './EscrowCard/EscrowCard.vue'

export default {
  mixins: [ratesMixin, responseMixin, snackMixin],
  components: {
    BalanceCard,
    TransactionsCard,
    WalletsCard,
    EscrowCard,
    BonusesCard,
  },
  data () {
    return {
      selectedWallet: null,
      balances: {},
      WALLET_GROUP_CLOUD,
      WALLET_GROUP_METAMASK,
      WALLET_GROUP_WALLET_CONNECT,
    }
  },
  computed: {
    ...mapState({
      userIsLoaded: state => state.user.profile.isLoaded,
      wallet: state => state.user?.wallet,
      wallets: state => state.user?.wallets || [],
      walletDefaultLoading: state => state.user?.walletDefaultLoading,
      walletRemoveLoading: state => state.user?.walletRemoveLoading,
      isCryptoAuth: state => state.user?.isCrypto,
    }),
    initialLoading () {
      return !this.wallets || this.wallets.length === 0
    },
    disabledButtons () {
      return this.walletDefaultLoading || this.walletRemoveLoading || this.initialLoading
    },
    balanceListMap () {
      const map = {}
      for (let address in this.balances) {
        const list = []
        const balances = this.balances[address]
        for (let blockchain in balances) {
          Object.entries(balances[blockchain]).forEach(([curName, balance]) => {
            const currency = getCurrency({ blockchain: +blockchain, value: curName })
            const value = new BigNumber(balance).dividedBy(currency.baseUnits)
            const usd = convertToUsd({ value, currency: curName, rates: this.rates, format: false })
            list.push({
              usd,
              blockchain: +blockchain,
              currency: curName,
              value: formatCurrency(value, { currency }),
            })
          })
        }
        map[address] = list
      }
      return map
    },
    totalBalancesMap () {
      const map = {}
      for (let address in this.balances) {
        const total = this.balanceListMap[address].reduce((total, { usd }) => total.plus(usd), new BigNumber(0))
        map[address] = formatUsdWithZeros(total)
      }
      return map
    },
    selectedBalanceList () {
      return this.balanceListMap[this.selectedWallet?.address]
    },
    selectedTotalBalance () {
      return this.totalBalancesMap[this.selectedWallet?.address]
    },
  },
  mounted () {
    this.makeHeightTheSame()
  },
  beforeUpdate () {
    this.makeHeightTheSame()
  },
  watch: {
    userIsLoaded: {
      async handler () {
        if (this.userIsLoaded && !this.selectedWallet) {
          this.selectedWallet = this.wallet
        }
      },
      immediate: true
    },
    wallets: {
      async handler () {
        if (this.wallets && this.wallets.length) {
          if (!this.wallets.find(wallet => wallet.address === this.selectedWallet.address)) { // after deleting selected wallet
            this.selectedWallet = this.wallets.find(wallet => wallet.is_default) || this.wallets[0]
          }
          this.wallets.forEach(wallet => this.loadBalance({
            ...(
              wallet.group === WalletGroup.TronLink
                ? { addressTron: wallet.address }
                : { addressEth: wallet.address }
            )
          }))
        }
      },
      immediate: true
    }
  },
  methods: {
    makeHeightTheSame () {
      if (this.$refs.leftCol && this.$refs.rightCol) {
        this.$nextTick(() => {
          this.$refs.leftCol.style.height = `${this.$refs.rightCol.clientHeight}px`
        })
      }
    },
    async loadBalance ({ addressEth, addressTron, forceUpdate = false }) {
      try {
        if (addressEth && (!this.balances[addressEth] || forceUpdate)) {
          const balances = await getBalances({ addressEth })
          Vue.set(this.balances, addressEth, new Balances(balances))
        }
        if (addressTron && (!this.balances[addressTron] || forceUpdate)) {
          const balances = await getBalances({ addressTron })
          Vue.set(this.balances, addressTron, new Balances(balances))
        }
      } catch (e) {
        console.error(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error fetching balance. Please try again',
        })
      }
    },
    onRefreshBalances ({ from, to }) {
      const addresses = [from]
      const toWallet = this.wallets.find(wallet => wallet.address.toLowerCase() === to.toLowerCase())
      if (toWallet) {
        addresses.push(toWallet.address)
      }
      addresses.forEach(address => this.loadBalance({ addressEth: address, forceUpdate: true }))
    }
  },
  metaInfo: {
    title: 'Wallets'
  },
}
