import { render, staticRenderFns } from "./CustomerNew.htm?vue&type=template&id=736dbd80&scoped=true&"
import script from "./CustomerNew.ts?vue&type=script&lang=js&"
export * from "./CustomerNew.ts?vue&type=script&lang=js&"
import style0 from "./CustomerNew.scss?vue&type=style&index=0&id=736dbd80&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736dbd80",
  null
  
)

export default component.exports