import Vue from 'vue'
import { mapActions, mapMutations } from 'vuex'
import CardActionMenu from '@/pages/Dashboard/MyJobsNew/cards/components/CardActionMenu/CardActionMenu.vue'
import ErrorMatcher from '@/utils/ErrorMatcher'
import { SnackTypes } from '@/constants/SnackTypes'

export default Vue.extend<any, any, any, any>({
  components: {
    CardActionMenu,
  },
  data () {
    return {
      email: '',
      key: '',
      activating: false,
      success: false,
      firstHidden: false,
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
      openSnackbar: 'snacks/openSnackbar',
      changeEmail: 'user/changeEmail',
      disableAddEmail: 'user/disableAddEmail'
    }),
    async hideCard () {
      try {
        await this.disableAddEmail()
      } catch (e) {
        this.openSnackbar({
          type: SnackTypes.FAILURE,
          text: 'Error hiding email card. Please try again.',
        })
      }
    },
    async onActivate () {
      if (!await this.$validator.validateAll()) return
      this.activating = true
      try {
        await this.changeEmail({ email: this.email, key: this.key })
        this.success = true
      } catch (e) {
        if (ErrorMatcher.is2FA(e)) {
          this.openModal({
            component: 'lx-confirm-2fa-modal',
            props: {
              confirm: async (key: string) => {
                await this.changeEmail({ email: this.email, key })
                this.success = true
              }
            }
          })
        } else {
          this.openSnackbar({
            type: SnackTypes.FAILURE,
            text: 'Error adding email. Please try again.',
          })
        }
      } finally {
        this.activating = false
      }
    },
    onFirstHidden () {
      this.firstHidden = true
    },
  },
})
