import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Chat from '@/partials/ChatNew/Chat.vue'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import { CHAT, JOB_DETAILS, JOB_DETAILS_ADMIN_APPLICATIONS } from '@/constants/routes'
import { getApplication } from '@/api/jobs/applications'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import ChatRoomPrimaryInfo from '@/partials/ChatRoomPrimaryInfo/ChatRoomPrimaryInfo.vue'
import InformerCard from './InformerCard/InformerCard.vue'
import JobApplication from '@/models-ts/job/JobApplication'
import { getOffer } from '@/api/jobs/offers'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import ActionsCard from './ActionsCard/ActionsCard.vue'
import JobDetailsCard from './JobDetailsCard/JobDetailsCard.vue'
import JobOfferDetailsCard from './JobOfferDetailsCard/JobOfferDetailsCard.vue'
import JobStatusBar from './JobStatusBar/JobStatusBar.vue'
import OfferCard from './OfferCard/OfferCard.vue'
import MobileActionsCard from './MobileActionsCard/MobileActionsCard.vue'
import actionsCardMixin from './actionsCardMixin'
import Job from '@/models-ts/job/Job'
import { getJob } from '@/api/jobs/job'
import snackMixin from '@/mixins/snackMixin'
import JobApplicationMeta from '@/models-ts/job/JobApplicationMeta'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin, actionsCardMixin, snackMixin],
  components: {
    ActionsCard,
    MobileActionsCard,
    Chat,
    ChatRoomPrimaryInfo,
    JobDetailsCard,
    JobOfferDetailsCard,
    JobStatusBar,
    InformerCard,
    OfferCard,
  },
  data () {
    return {
      initLoaded: false,
      refreshing: false,
      withDetails: false,
      application: null,
      lastUpdatedRoomDate: null,
      offer: null,
    }
  },
  computed: {
    ...mapState({
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      userId: (state: any) => state.user?.id,
      roomId: (state: any) => state.chatNew.openedRoomId,
      initialized: (state: any) => state.chatNew.initialized,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
    }),
    applicationId () {
      const { applicationId } = ChatRoom.parseRoomId(this.roomId)
      return applicationId
    },
    isFreelancerInfo () {
      return this.application && !this.isFreelancer
    },
    roomUser () {
      if (this.application) {
        return this.isFreelancer
          ? this.application.customer
          : this.application.freelancer
      }
    },
    roomEntity () {
      if (!this.initLoaded) return
      return {
        name: this.application.job.name,
        link: {
          name: this.isFreelancer ? JOB_DETAILS : JOB_DETAILS_ADMIN_APPLICATIONS,
          params: {
            id: this.application.job.id,
            slug: this.application.job.slug
          },
        },
      }
    },
    backLink () {
      return this.roomEntity?.link
    },
    isTemporary () {
      return (this.room && this.room.temporary) || (this.roomId || '').startsWith('tmp_')
    },
    hasActions () {
      return !this.isTemporary && this.application
    },
    hasOffer () {
      return this.initLoaded && !this.room?.temporary && this.application?.offer && !this.wasStarted
    },
    wasStarted () {
      return this.application?.wasStarted
    },
    applicationIsArchived () {
      return this.isFreelancer && this.application.meta?.archived
    },
    isMessaged () {
      return this.application.meta?.messaged
    },
  },
  watch: {
    initialized: {
      handler () {
        if (this.initialized) {
          if (!this.roomId) {
            const room = this.$route.query.room
            if (room) {
              this.openRoom(room)
            } else {
              this.$router.replace({ name: CHAT })
            }
          }
        }
      },
      immediate: true,
    },
    roomId: {
      async handler () {
        if (this.roomId) {
          this.initLoaded = false
          if (!this.isTemporary) {
            const application = await getApplication(this.applicationId)
            this.application = JobApplication.fromServer(application)
            if (this.application.isCompleted) {
              this.offer = await getOffer(this.application.offer.id)
            }
            this.initLoaded = true
          } else if (this.room) {
            this.initLoaded = true
          } else {
            this.$router.replace({ name: CHAT })
          }
        }
        if (this.room && this.roomId !== this.$route.query.room) {
          this.$router.replace(this.room.chatLink)
        }
        if (this.$route.query.action) {
          this.processInitialAction(this.$route.query.action)
        }
      },
      immediate: true
    },
    'room.updatedAt': {
      handler () {
        if (this.lastUpdatedRoomDate && this.application) {
          let dateA = Number(new Date(this.lastUpdatedRoomDate))
          let dateB = Number(new Date(this.room.updatedAt))
          if (Math.abs(dateA - dateB) > 1000) {
            this.onRefresh()
          }
        }
        this.lastUpdatedRoomDate = this.room?.updatedAt
      },
      immediate: true
    },
  },
  // mounted () {
  //   googleAnalyticsV2.send({
  //     event: 'job_apps_view',
  //   })
  // },
  beforeDestroy () {
    this.closeRoom()
  },
  methods: {
    ...mapMutations({
      setDepositingOffer: 'chatNew/setDepositingOffer',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
      closeRoom: 'chatNew/closeRoom'
    }),
    async onOfferEdit () {
      const offer = this.application.offer
      if (offer) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
            title: 'Send an offer',
            props: {
              applicationId: this.application.id,
              offer,
              initBudget: new BigNumber(offer.budget).toNumber(),
              initDays: offer.deadlineInDays,
              initHours: offer.timeValue,
              onSuccess: () => {
                this.onRefresh()
              }
            }
          }
        })
      }
    },
    onOfferCancel (id: number) {
      if (this.application.offer?.id === id) {
        this.application.offer = null
      }
    },
    async onOfferAccept () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/AcceptTalentsOffer/AcceptTalentsOffer.vue'),
          title: `Accept Talent's Offer`,
          props: {
            application: this.application,
            onSuccess: async () => {
              await this.onRefresh()
              this.startJob()
            },
          }
        }
      })
    },
    async onDeclineApplicationClick () {
      if (!this.archiving) {
        await this.setArchivedMeta(true)
        this.onUpdateApplicationMeta({ ...this.application.meta, archived: true })
        this.$router.push({
          name: JOB_DETAILS_ADMIN_APPLICATIONS,
          params: { id: this.application.job.id, slug: this.application.job.slug }
        })
      }
    },
    async onRemoveFromArchivedClick () {
      if (!this.archiving) {
        await this.setArchivedMeta(false)
        this.onUpdateApplicationMeta({ ...this.application.meta, archived: false })
      }
    },
    onUpdateApplicationMeta (meta: JobApplicationMeta) {
      this.application.meta = meta
    },
    async onMessageSent () {
      if (this.isMetaArchived) {
        await this.setArchivedMeta(false)
        this.onUpdateApplicationMeta({ ...this.application.meta, archived: false })
      }
      if (!this.isMessaged) {
        const meta = { ...this.application.meta, messaged: true }
        await this.setApplicationMeta({
          jobId: this.application.job.id,
          applicationId: this.application.id,
          meta,
        })
      }
    },
    processInitialAction (action: string) {
      switch (action) {
        case 'deposit': return this.initialStartJob()
        case 'pay': return this.initialPayJob()
        case 'dispute': return this.initialDispute()
        case 'refund': return this.initialRefund()
        case 'feedback': return this.initialLeaveFeedback()
      }
    },
    initialStartJob () {
      if (this.hasStartJob) {
        this.startJob()
      }
    },
    initialPayJob () {
      if (this.hasPayJob || this.hasAcceptJob) {
        this.onPayToTalentClick()
      }
    },
    initialDispute () {
      if (this.hasCreateDispute) {
        this.onCreateDisputeClick()
      }
    },
    initialRefund () {
      if (this.hasRefund) {
        this.onRefundClick()
      }
    },
    initialLeaveFeedback () {
      if (this.hasLeaveReview) {
        this.onLeaveReviewClick()
      }
    },
    async startJob () {
      try {
        const offer = this.application.offer
        this.setDepositingOffer(offer?.id)
        if (this.isMetaArchived) {
          await this.setArchivedMeta(false)
          this.onUpdateApplicationMeta({ ...this.application.meta, archived: false })
        }
        const job = Job.fromServer(await getJob(`${this.application.job.slug}-${this.application.job.id}`))
        if (job && offer) {
          this.signCreateContractV2({
            params: {
              deadline: offer.deadline,
              rate: offer.budget,
              // @ts-ignore
              freelancerWallets: job?.meta?.FreelancerWallets || [],
              id: job.id,
              slug: job.slug,
              scId: job.sc_id,
              name: job.name,
              offerId: offer.id,
              freelancer: {
                ...job.freelancer,
                wallet: offer.freelancerWallet,
              },
            },
            successSign: () => {
              this.openSnackbar({
                type: this.SnackTypes.SUCCESS,
                text: 'Your funds have successfully been deposited to escrow.',
              })
              this.onRefresh()
            },
            cancelSign: () => {
              this.setDepositingOffer(null)
            },
          })
        }
      } catch (err) {
        console.error(err)
        this.setDepositingOffer(null)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
      }
    },
    async onRefresh () {
      this.refreshing = true
      const application = await getApplication(this.applicationId)
      this.application = JobApplication.fromServer(application)
      if (this.application.isCompleted) {
        this.offer = await getOffer(this.application.offer.id)
      }
      this.refreshing = false
    },
  },
  metaInfo () {
    return {
      title: this.application?.job?.name || 'Chat'
    }
  }
})
