import { render, staticRenderFns } from "./JobActiveCardCustomer.htm?vue&type=template&id=49d08f8e&scoped=true&"
import script from "./JobActiveCardCustomer.ts?vue&type=script&lang=ts&"
export * from "./JobActiveCardCustomer.ts?vue&type=script&lang=ts&"
import style0 from "./JobActiveCardCustomer.scss?vue&type=style&index=0&id=49d08f8e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49d08f8e",
  null
  
)

export default component.exports