import Vue from 'vue'
import get from 'lodash/get'
import { mapActions } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import MyGigListItem from '@/models-ts/gigs/MyGigListItem'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { TIME_HOURLY } from '@/constants/backend/service'
import { SERVICE_DETAILS, SERVICE_EDIT } from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import Button from '@/models-ts/ui/Button'
import { deleteService } from '@/api/gig'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import { formatCardsDescription } from '@/utils-ts/strings'
import GigStatusBadge from '@/partials/StatusBadges/GigStatusBadge/GigStatusBadge.vue'
import CardActionMenu from '../../components/CardActionMenu/CardActionMenu.vue'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import zendeskMixin from '@/mixins/zendeskMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, zendeskMixin],
  components: {
    CardActionMenu,
    JobTypeBadge,
    GigStatusBadge,
  },
  props: {
    gig: MyGigListItem,
  },
  data () {
    return {
      publishing: false,
      SERVICE_DETAILS,
      SERVICE_EDIT,
      deleting: false,
    }
  },
  computed: {
    isHourly () {
      return this.gig.timeType === TIME_HOURLY
    },
    isPublished () {
      return this.gig.status === GigStatuses.PUBLISHED
    },
    isFailed () {
      return this.gig.moderationStage === ModerationStages.FAILED
    },
    rate () {
      return formatUsd(this.gig.rate)
    },
    description () {
      return formatCardsDescription(this.gig.description)
    },
  },
  methods: {
    ...mapActions({
      publish: 'myGigs/publish',
      unpublish: 'myGigs/unpublish',
      openModal: 'ui/openModal',
    }),
    async onPublishClick () {
      try {
        if (!this.publishing) {
          this.publishing = true
          if (this.isPublished) {
            await notifiableRequest({
              request: () => this.unpublish(this.gig.id),
              title: 'Gig unpublished',
              successText: `Gig: <b>${this.gig.name}</b> has been unpublished.`,
              failureText: 'Error unpublishing Gig. Please try again.',
            })
            this.$emit('changeStatus', { status: GigStatuses.DRAFT, gig: this.gig })
          } else {
            await notifiableRequest({
              request: () => this.publish(this.gig.id),
              successText: (moderation_stage) => moderation_stage === ModerationStages.PASSED
                ? `Gig: <b>${this.gigDetails.name}</b> was successfully published`
                : 'Your Gig was successfully submitted for moderation. We will notify you of the outcome shortly.',
              failureText: (e) => {
                const defaultErr = 'Gig publishing error'
                return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
              }
            })
            this.$emit('changeStatus', { status: GigStatuses.PUBLISHED, gig: this.gig })
            googleAnalyticsV2.send({
              event: 'gig-published',
              gig_id: this.gig.id,
            })
          }
        }
      } catch (e) {
        this.parseError(e)
      } finally {
        this.publishing = false
      }
    },
    async onClickDeleteBtn () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Delete Gig',
          text: `Are you sure you want to delete <i>\`${this.gig.name}\`</i>? This Gig will be permanently deleted.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
              onClick: () => {},
            }),
            new Button({
              text: 'Delete',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  this.deleting = true
                  await notifiableRequest({
                    // @ts-ignore
                    request: async () => {
                      await deleteService(this.gig.id)
                    },
                    title: 'Gig deleted',
                    successText: `Gig: <b>${this.gig.name}</b> has been deleted`,
                    failureText: 'Error deleting Gig. Please try again.',
                  })
                  this.$emit('delete', this.gig)
                } catch (e) {
                  this.parseError(e)
                } finally {
                  this.deleting = false
                }
              },
            }),
          ]
        },
      })
    }
  },
})
