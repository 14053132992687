import Vue, { PropType } from 'vue'
import responseMixin from '@/mixins/responseMixin'
import Channel from '@/models-ts/user/Channel'
import { Channel as Channels } from '@/constants/user/channels'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import { saveFreelancerChannelsProfile } from '@/api/users'
import { getSocialLink } from '@/utils-ts/strings'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  props: {
    channels: {
      type: Array as PropType<Array<Channel>>,
      default: true,
    }
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: [],
      github: '',
      linkedIn: '',
      Channels,
    }
  },
  computed: {
    wasUpdated () {
      return this.linkedIn !== this.getChannel(Channels.LinkedIn) ||
        this.github !== this.getChannel(Channels.Github)
    },
  },
  watch: {
    channels: {
      handler () {
        if (this.channels) {
          this.initForm()
        }
      },
      immediate: true
    }
  },
  methods: {
    getSocialLink,
    getChannel (type: Channels) {
      const channels = this.channels as Array<Channel>
      return channels.find(ch => ch.type === type)?.value || ''
    },
    initForm () {
      this.linkedIn = this.getChannel(Channels.LinkedIn)
      this.github = this.getChannel(Channels.Github)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      try {
        this.saving = true
        const channels = await notifiableRequest({
          request: async () => {
            const channels = [
              ...this.channels.filter((ch: Channel) => ![Channels.LinkedIn, Channels.Github].includes(ch.type)),
              { type: Channels.LinkedIn, value: this.linkedIn.trim() },
              { type: Channels.Github, value: this.github.trim() },
            ].filter(ch => ch.value)
            return saveFreelancerChannelsProfile(channels)
          },
          title: 'Contacts',
          successText: 'Your references have been successfully updated.',
          failureText: 'Error updating your references. Please try again.'
        })
        this.form = channels.map(Channel.fromServer)
        this.$emit('update', this.form)
        this.editMode = false
      } catch (e) {
        console.error(e)
      } finally {
        this.saving = false
      }
    },
  },
})
