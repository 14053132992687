import Vue from 'vue'
import VacancyApplication from '@/models-ts/vacancies/VacancyApplication'
import { formatUsd } from '@/utils/moneyFormat'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyLocationTooltip,
  },
  props: {
    application: VacancyApplication,
  },
  data () {
    return {
    }
  },
  computed: {
    vacancy () {
      return this.application.vacancy
    },
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.positionType as PositionType]?.name
    },
    remoteInfo () {
      return this.application.remoteInfo
    },
  },
  methods: {
    formatUsd,
  }
})
