import Vue from 'vue'
import BigNumber from 'bignumber.js'
import { mapActions, mapState } from 'vuex'
import { formatCurrency, formatUsdWithZeros } from '@/utils/moneyFormat'
import { OUTPUT_FORMATS } from '@/constants/utils/moneyFormat'
import { convertToUsd } from '@/utils-ts/currencies'
import { CURRENCY_FIELD_BACKEND_NAME } from '@/constants/currency'
import { getCurrency } from '@/utils/currency'
import Button from '@/models-ts/ui/Button'
import ratesMixin from '@/mixins/ratesMixin'
import AccountType from '@/constants/user/AccountType'
import { getReferrals } from '@/api/timewarp'
import { WithdrawsStatus, WITHDRAW_LIMIT } from '@/constants/withdraw'

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  data () {
    return {
      totalReferrals: 0,
      totalReferralsLoading: true,
    }
  },
  computed: {
    ...mapState('balances', {
      miningBalance: (state: any) => state.miningBalance.value,
      miningBalanceIsLoaded: (state: any) => state.miningBalance.isLoaded,
      referralBalance: (state: any) => state.referralBalance.value,
      referralBalanceIsLoaded: (state: any) => state.referralBalance.isLoaded,
      withdrawLoading: (state: any) => state.withdrawals.isLoading,
      withdrawLoaded: (state: any) => state.withdrawals.isLoaded,
      withdrawals: (state: any) => state.withdrawals.value,
    }),
    ...mapState('user', {
      premiumType: (state: any) => state.type,
    }),
    initialLoaded () {
      return !this.miningBalanceIsLoaded ||
        !this.referralBalanceIsLoaded ||
        !this.ratesLoaded ||
        !this.withdrawLoaded
    },
    referralBalanceFormatted () {
      return formatUsdWithZeros(this.referralBalance)
    },
    miningBalanceFormatted () {
      return formatUsdWithZeros(this.miningBalance)
    },
    activeWithdraw () {
      return this.withdrawals.find((w: any) => [WithdrawsStatus.NEW, WithdrawsStatus.PENDING].includes(w.status))
    },
    summaryBalance () {
      return this.activeWithdraw
        ? this.activeWithdraw.amount
        : new BigNumber(this.miningBalance).plus(this.referralBalance)
    },
    summaryBalanceFormatted () {
      return formatUsdWithZeros(this.summaryBalance)
    },
    timeCurrency () {
      return getCurrency({ value: 'TIME', field: CURRENCY_FIELD_BACKEND_NAME })
    },
    summaryBalanceUsd () {
      return formatUsdWithZeros(
        convertToUsd({
          value: this.summaryBalance,
          currency: this.timeCurrency,
          rates: this.rates,
        })
      )
    },
    accountType () {
      return AccountType.getType(this.premiumType)
    },
  },
  async created () {
    this.loadBonusBalance()
    this.loadWithdrawals()
    this.loadingReferrals()
  },
  methods: {
    ...mapActions({
      loadBonusBalance: 'balances/loadBonusBalance',
      loadWithdrawals: 'balances/loadWithdrawals',
      openModal: 'ui/openModal'
    }),
    async loadingReferrals () {
      const { pagination } = await getReferrals({ limit: 0, offset: 0 })
      this.totalReferrals = pagination.total
      this.totalReferralsLoading = false
    },
    formatAmount (amount: any, currency: any) {
      return `${formatCurrency(amount, { currency, outputFormat: OUTPUT_FORMATS.CURRENCY_COMISSION })} ${currency.name}`
    },
    onClickWithdraw () {
      try {
        const balance = new BigNumber(this.miningBalance).plus(this.referralBalance)
        const balanceFormatted = this.formatAmount(balance, this.timeCurrency)
        const limitFormatted = this.formatAmount(WITHDRAW_LIMIT, this.timeCurrency)
        if (balance.isLessThan(WITHDRAW_LIMIT)) {
          this.openModal({
            component: 'lx-composite-modal-new',
            props: {
              title: 'Withdraw bonuses',
              text: `Minimum value to withdraw is ${limitFormatted}. <br>Your balance is ${balanceFormatted}`,
              buttons: [
                new Button({
                  text: 'Ok',
                  classes: 'lx-blue-btn',
                }),
              ]
            }
          })
        } else {
          this.openModal({
            component: 'lx-lazy-modal',
            props: {
              factory: import(/* webpackChunkName: "cash-modals" */ '@/modals/WithdrawBonuses/WithdrawBonuses.vue'),
              title: 'Withdraw bonuses',
              props: {
                totalEarnings: balance,
                currency: this.timeCurrency,
                onSuccess: () => {
                  try {
                    this.loadWithdrawals()
                    this.$emit('withdraw-requested')
                  } catch (e) {
                    this.parseError(e)
                  }
                },

              },
            }
          })
        }
      } catch (e) {
        this.parseError(e)
      }
    },
  }
})
