import { render, staticRenderFns } from "./FileMessage.htm?vue&type=template&id=742cffe2&scoped=true&"
import script from "./FileMessage.ts?vue&type=script&lang=js&"
export * from "./FileMessage.ts?vue&type=script&lang=js&"
import style0 from "./FileMessage.scss?vue&type=style&index=0&id=742cffe2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "742cffe2",
  null
  
)

export default component.exports