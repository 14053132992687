import { mapActions, mapState } from 'vuex'
import TfaCodeInputNew from '@/partials/TfaCodeInputNew/TfaCodeInputNew.vue'
import notifiableRequest from '@/utils/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { BUTTON_BLUE_MEDIUM, BUTTON_TRANSPARENT_MEDIUM, BUTTON_BLUE_SMALL } from '@/constants/components/button'
import { get2FASecret } from '@/api/users'
import { getRandomString } from '@/utils/strings'
import snackMixin from '@/mixins/snackMixin'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'

export default {
  mixins: [responseMixin, snackMixin],
  components: {
    TfaCodeInputNew,
  },
  data () {
    return {
      BUTTON_BLUE_MEDIUM,
      BUTTON_TRANSPARENT_MEDIUM,
      BUTTON_BLUE_SMALL,
      step: 'download',
      qr2fa: '',
      secret2fa: '',
      qrLoading: true,
      key2fa: '',
      processing: false,
    }
  },
  computed: {
    ...mapState({
      activated2FA: state => state.user?.active2FA,
      last2FAConfirmation: state => state.ui.last2FAConfirmation,
    }),
    formDisabled () {
      return this.key2fa.length !== 6 || !(/^[0-9]+$/.test(this.key2fa))
    },
  },
  created () {
    if (this.activated2FA) {
      this.step = 'activated'
    }
  },
  methods: {
    ...mapActions({
      enable2FA: 'user/enable2FA',
      disable2FA: 'user/disable2FA',
    }),
    async onProceedClick () {
      try {
        this.qrLoading = true
        this.step = 'activation'
        this.qr2fa = ''
        const response = await get2FASecret()
        this.qr2fa = response.qr
        this.secret2fa = response.secret
      } catch (e) {
        this.parseError(e)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error with 2FA. Please try again',
        })
      }
    },
    async onDeactivateClick () {
      if (this.last2FAConfirmation) {
        const now = +(new Date(new Date().toISOString()))
        const confirmed = +(new Date(this.last2FAConfirmation))
        const CODE_LIFETIME = 50000
        if (now - confirmed > CODE_LIFETIME) {
          this.step = 'deactivation'
        } else {
          this.key2fa = getRandomString(6)
          this.onDeactivate2FASubmit()
        }
      } else {
        this.step = 'deactivation'
      }
    },
    onCancelActivationClick () {
      this.step = 'download'
      this.qr2fa = ''
      this.secret2fa = ''
      this.key2fa = ''
    },
    async onActivate2FASubmit () {
      try {
        this.processing = true
        await notifiableRequest({
          request: async () => this.enable2FA({ key: this.key2fa, secret: this.secret2fa }),
          title: '2FA activation',
          successText: 'Two-factor authentication successfully enabled.',
          failureText: 'Error enabling two-factor authentication. Please try again.',
        })
        this.step = 'activated'
        this.key2fa = ''
        googleAnalyticsV2.send({
          event: 'settings-2fa-activated',
        })
      } finally {
        this.processing = false
      }
    },
    async onDeactivate2FASubmit () {
      try {
        this.processing = true
        await notifiableRequest({
          request: async () => this.disable2FA(this.key2fa),
          title: '2FA deactivation',
          successText: 'Two-factor authentication successfully disabled.',
          failureText: 'Error disabling two-factor authentication. Please try again.',
        })
        this.step = 'deactivated'
        this.key2fa = ''
      } finally {
        this.processing = false
      }
    },
  },
}
