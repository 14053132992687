import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { SERVICE_DETAILS, SERVICE_MY_IN_PROGRESS, SERVICE_CHAT } from '@/constants/routes'
import { formatUsd } from '@/utils/moneyFormat'
import snackMixin from '@/mixins/snackMixin'
import { applyOffer, getGigOffer } from '@/api/gig'
import { formatDate } from '@/utils/date'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import { GigTimeTypes } from '@/constants/gig/gigTimeTypes'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import sleep from '@/utils/sleep'
import GigOffer from '@/models-ts/gigs/GigOffer'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import UserInfo from '@/partials/UserInfo/UserInfo.vue'
import { RoomTypes } from '@/constants/chat/RoomTypes'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge.vue'
import GigApplicationStatusBadge from '@/partials/StatusBadges/GigApplicationStatusBadge/GigApplicationStatusBadge.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, snackMixin],
  components: {
    JobTypeBadge,
    UserInfo,
    GigApplicationStatusBadge,
  },
  props: {
    gig: GigOfferListItem,
    application: MyGigApplication,
    isFreelancer: Boolean,
  },
  data () {
    return {
      accepting: false,
      starting: false,
      SERVICE_DETAILS,
    }
  },
  computed: {
    ...mapState({
      createContractLoading: (state: any) => state.gig.createContractLoading,
      rates: (state: any) => state.rates.rates.value || [],
      isBalancesLoaded: (state: any) => state.balances.balances.isLoaded,
      chatInitialized: (state: any) => state.chatNew?.initialized,
      chatConnected: (state: any) => state.chatNew?.connected,
    }),
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
      getUnreadCount: 'chatNew/getUnreadCount',
    }),
    customerType () {
      return this.application?.customer?.customerType
    },
    hasChat () {
      return this.application.id && this.chatInitialized && this.application.moderationStage === ModerationStages.PASSED
    },
    dateFormatted () {
      return formatDate(this.application.createdAt, 'DD MMM YYYY, hh:mm A')
    },
    isHourly () {
      return this.application.offer?.timeType === GigTimeTypes.HOURLY
    },
    budget () {
      return this.application.offer?.budget || this.application.budget
    },
    totalBudget () {
      if (this.rates.length) {
        return new BigNumber(this.budget).multipliedBy(this.isHourly ? this.application.offer.timeValue : 1)
      }
      return 0
    },
    budgetInUsd () {
      return formatUsd(this.totalBudget)
    },
    deadline () {
      if (this.application.offer?.deadline) {
        return `${this.application.offer.deadlineInDays} days`
      } else if (this.application.deadline) {
        return `${this.application.deadlineInDays} days`
      }
      return 'Not specified'
    },
    chatRoomId () {
      return ChatRoom.generateRoomId({
        type: RoomTypes.GIG,
        applicationId: this.application.id,
        freelancerId: this.application.freelancer.id,
        customerId: this.application.customer.id,
      })
    },
    chatLink () {
      return {
        name: SERVICE_CHAT,
        params: { id: this.application.gig.id, slug: this.application.gig.slug },
        query: { room: this.chatRoomId }
      }
    },
    unreadMsgs () {
      return this.getUnreadCount(this.chatRoomId)
    },
    user () {
      return this.isFreelancer ? this.application.customer : this.application.freelancer
    },
    hasCreateOfferBtn () {
      return this.isFreelancer && !this.application?.offer
    },
    hasStartJob () {
      return !this.isFreelancer && this.application?.offer?.stage === GigOfferStages.ACCEPTED_BY_CUSTOMER
    },
    hasAcceptBtn () {
      return !this.isFreelancer && this.application?.offer?.stage === GigOfferStages.NEW
    },
    hasGig () {
      return !this.gig.isRemoved && (this.isFreelancer || this.gig.gigStatus === GigStatuses.PUBLISHED)
    },
    cardLink () {
      return this.hasChat && this.chatConnected
        ? this.chatLink
        : { name: SERVICE_DETAILS, params: { id: this.application.gig.id, slug: this.application.gig.slug } }
    },
  },
  methods: {
    ...mapActions({
      signCreateContractV2: 'gig/signCreateContractV2',
      openModal: 'ui/openModal',
      openRoom: 'chatNew/openRoom',
    }),
    onChatClick () {
      this.openRoom(this.chatRoomId)
      this.$router.push(this.chatLink)
    },
    onCreateOfferClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
          title: 'Send an offer',
          props: {
            applicationId: this.application.id,
            initBudget: 0,
            initDeadline: 0,
            gig: this.application.gig,
            onSuccess: () => {
              this.refreshList()
            }
          }
        }
      })
    },
    async onAcceptClick () {
      try {
        this.accepting = true
        const offer: GigOffer = this.application.offer
        await notifiableRequest({
          request: async () => {
            await applyOffer(offer.id)
            await sleep(this.backendConfig['CREATE_CONTRACT_BACKEND_FLOW_TIMEOUT'] || 2000)
            this.refreshList()
          },
          title: 'Offer accepted',
          successText: `You have an offer for this Gig.`,
          failureText: 'Error accepting offer. Please try again.'
        })
        this.starting = true
        this.startJob()
      } finally {
        this.accepting = false
      }
    },
    async startJob () {
      try {
        this.starting = true
        const offer = GigOfferListItem.fromServer(await getGigOffer(this.application.offer.id))
        this.signCreateContractV2({
          gig: offer,
          successSign: () => {
            this.openSnackbar({
              type: this.SnackTypes.SUCCESS,
              text: 'Your funds have successfully been deposited to escrow.',
            })
            this.starting = false
            this.$router.push({ name: SERVICE_MY_IN_PROGRESS })
          }
        })
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.starting = false
      }
      this.starting = false
    },
    refreshList () {
      this.$emit('refreshList')
    },
  }
})
