import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import JobActiveCardCustomer from '@/partials/JobCards/JobActiveCardCustomer/JobActiveCardCustomer.vue'
import JobPostedCardCustomer from '@/partials/JobCards/JobPostedCardCustomer/JobPostedCardCustomer.vue'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import { ModerationStages } from '@/constants/backend/ModerationStages'

const OFFERS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin],
  components: {
    JobActiveCardCustomer,
    JobPostedCardCustomer,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState({
      jobs: (state: any) => state.myJobs.clientCompleted.values,
      isLoading: (state: any) => state.myJobs.clientCompleted.isLoading,
      isLoaded: (state: any) => state.myJobs.clientCompleted.isLoaded,
      pagination: (state: any) => state.myJobs.clientCompleted.pagination,
    }),
  },
  async created () {
    await this.load({ limit: OFFERS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'myJobs/loadClientCompleted',
      loadMore: 'myJobs/loadMoreClientCompleted',
    }),
    isFailedModeration (job: MyCustomerJobListItem) {
      return job.moderationStage === ModerationStages.FAILED
    },
    async onLoadMore ($state: any) {
      try {
        if (this.jobs.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: OFFERS_LIMIT, offset: this.jobs.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    async onResetCompleteJobs () {
      await this.load({ limit: OFFERS_LIMIT, offset: 0 })
      this.infiniteId = +new Date()
    }
  },
  metaInfo: {
    title: 'My Jobs - Completed',
  },
})
