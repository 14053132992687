import InfiniteLoading from 'vue-infinite-loading'
import { mapState } from 'vuex'
import ContractCreatedTransaction from '@/models/lists/transactions/ContractCreatedTransaction'
import DisputeTransaction from '@/models/lists/transactions/DisputeTransaction'
import PayToFreelancerTransaction from '@/models/lists/transactions/PayToFreelancerTransaction'
import RefundToCustomerTransaction from '@/models/lists/transactions/RefundToCustomerTransaction'
import ratesMixin from '@/mixins/ratesMixin'
import {
  EVENT_CONTRACT_CREATED,
  EVENT_PAY_TO_FREELANCER,
  EVENT_RETURN_FUNDS_TO_CUSTOMER,
  EVENT_DISTRIBUTION_FUNDS_FOR_PARTIALS,
  STATUS_APPLIED,
  EVENT_PAYED_TO_FREELANCER_V2,
  EVENT_DISTRIBUTED_FOR_PARTIALS_V2,
  EVENT_REFUNDED_TO_CUSTOMER_V2
} from '@/constants/backend/transaction'
import { convertToLocal } from '@/utils/date'
import { convertToUsd } from '@/utils/currency'
import { formatCurrency } from '@/utils/moneyFormat'
import { getList } from '@/api/transactions/me'
import { getTxLink } from '@/utils/etherscan'
import snackMixin from '@/mixins/snackMixin'

const CUSTOMER_VIEWED_EVENTS_MAP = {
  [EVENT_CONTRACT_CREATED]: ContractCreatedTransaction.fromServer,
  [EVENT_PAY_TO_FREELANCER]: PayToFreelancerTransaction.fromServer,
  [EVENT_RETURN_FUNDS_TO_CUSTOMER]: RefundToCustomerTransaction.fromServer,
  [EVENT_DISTRIBUTION_FUNDS_FOR_PARTIALS]: DisputeTransaction.fromServer,
  [EVENT_PAYED_TO_FREELANCER_V2]: PayToFreelancerTransaction.fromServer,
  [EVENT_DISTRIBUTED_FOR_PARTIALS_V2]: DisputeTransaction.fromServer,
  [EVENT_REFUNDED_TO_CUSTOMER_V2]: RefundToCustomerTransaction.fromServer,
}

export default {
  mixins: [ratesMixin, snackMixin],
  components: {
    InfiniteLoading,
  },
  props: {
    wallet: Object,
  },
  data () {
    return {
      infiniteId: 0,
      total: 0,
      totalLoaded: 0,
      list: [],
      loaded: false,
      loading: false,
    }
  },
  computed: {
    ...mapState({
      userId: state => state.user.id,
    }),
    walletAddress () {
      return this.wallet?.address?.toLowerCase()
    },
    filteredList () {
      return this.list.filter(tr => tr?.wallet === this.walletAddress)
    },
  },
  async created () {
    try {
      await this.load()
      this.infiniteId = +new Date()
      this.loaded = true
    } catch (e) {
      console.error(e)
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: 'Error fetching transaction history. Please try again.',
      })
    }
  },
  methods: {
    formatTime (tr) {
      return convertToLocal(tr.created_at, 'hh:mm A D MMM YYYY')
    },
    formatAmount (tr) {
      return tr.amount?.isNaN() ? '' : formatCurrency(tr.amount, { currency: tr.currency })
    },
    formatUsd (tr) {
      if (tr.amount?.isNaN()) {
        return ''
      }
      const formated = convertToUsd({ value: tr.amount.abs(), currency: tr.currency, rates: this.rates })
      return tr.amount.isLessThan(0) ? `-$${formated}` : `$${formated}`
    },
    getTxLink (tr) {
      return getTxLink({ blockchain: tr.currency.blockchain, tx: tr.tx_id })
    },
    async load (offset = 0, limit = 1000) {
      const { models, total } = await getList({
        limit,
        offset,
        status: STATUS_APPLIED,
        event: Object.keys(CUSTOMER_VIEWED_EVENTS_MAP),
      })
      const list = models.map(tr => {
        try {
          return CUSTOMER_VIEWED_EVENTS_MAP[tr.event]?.(tr, this.userId)
        } catch (e) {
          console.error(e)
        }
      }).filter(Boolean)
      this.list.push(...list)
      this.totalLoaded += models.length
      this.total = Number(total)
    },
    async onLoadMore ($state) {
      try {
        if (this.totalLoaded < this.total) {
          this.loading = true
          await this.load(this.totalLoaded)
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.loading = false
      }
    },
  },
}
