import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import JobPostedCardCustomer from '@/partials/JobCards/JobPostedCardCustomer/JobPostedCardCustomer.vue'
import { ModerationStages } from '@/constants/backend/ModerationStages'

const JOBS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin],
  components: {
    JobPostedCardCustomer,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState({
      jobs: (state: any) => state.myJobs.clientPosted.values,
      isLoading: (state: any) => state.myJobs.clientPosted.isLoading,
      isLoaded: (state: any) => state.myJobs.clientPosted.isLoaded,
      pagination: (state: any) => state.myJobs.clientPosted.pagination,
    }),
  },
  async created () {
    await this.load({ limit: JOBS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapMutations({
      updateJobStatus: 'myJobs/updateJobStatus',
    }),
    ...mapActions({
      load: 'myJobs/loadClientPosted',
      loadMore: 'myJobs/loadMoreClientPosted',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.jobs.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: JOBS_LIMIT, offset: this.jobs.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
    onChangeStatus ({ jobId, status, moderationStage }: { status: any, moderationStage: ModerationStages, jobId: number }) {
      this.updateJobStatus({ jobId, status, moderationStage })
    },
  },
  metaInfo: {
    title: 'My Jobs - Posted',
  },
})
