import Vue from 'vue'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import PortfolioSection from './PortfolioSection/PortfolioSection.vue'
import InfoSection from './InfoSection/InfoSection.vue'
import PortfolioProject from '@/models-ts/user/PortfolioProject'

export default Vue.extend<any, any, any, any>({
  components: {
    InfoSection,
    PortfolioSection,
  },
  props: {
    profile: MyFreelancerProfile,
  },
  methods: {
    onFreelanceInfoUpdate (profile: MyFreelancerProfile) {
      this.$emit('update', profile)
    },
    onPortfolioUpdate (portfolio: Array<PortfolioProject>) {
      this.$emit('update', { ...this.profile, portfolio })
    },
  }
})
