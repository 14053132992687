import Vue, { PropType } from 'vue'
import ActionPanel from './ActionPanel/ActionPanel.vue'
import MessageViewer from './MessageViewer/MessageViewer.vue'
import { Stages } from '@/constants/vacancies/statuses'
import Vacancy from '@/models-ts/vacancies/Vacancy'

export default Vue.extend({
  props: {
    disabled: Boolean,
    vacancyStage: Number as PropType<Stages>,
    vacancy: Vacancy,
  },
  components: {
    ActionPanel,
    MessageViewer,
  },
  data () {
    return {
      indent: 67,
    }
  },
  computed: {
    chatHeight (): string {
      return `calc(100% - ${this.indent}px)`
    },
  },
  methods: {
    onIndent (indent: number) {
      this.indent = indent
    },
  },
})
