import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { cancelDoneGig, doneGig, readApplications } from '@/api/gig'
import { CHAT } from '@/constants/routes'
import snackMixin from '@/mixins/snackMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import { GigOfferStages } from '@/constants/gig/gigOfferStages'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import Currency from '@/models-ts/Currency'
import { Blockchain, BlockchainNames } from '@/constants/blockchain'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { convertToUsd } from '@/utils-ts/currencies'
import ratesMixin from '@/mixins/ratesMixin'
import { addDays, addSeconds, convertToLocal, formatDate, isBefore } from '@/utils/date'
import { GigApplicationStatuses } from '@/constants/gig/gigApplicationStatuses'
import { RootState } from '@/store'
import { DISPUTE_GIG_TYPE } from '@/constants/components/CreateDispute'

const EXTRA_DAYS = 2

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin, rolebleMixin, snackMixin],
  data () {
    return {
      starting: false,
      donning: false,
      cancelDone: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      roomId: (state: RootState) => state.chatNew.openedRoomId,
      depositingOffer: (state: RootState) => state.chatNew.depositingOffer,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
      getParticipant: 'chatNew/getParticipant',
    }),
    depositing () {
      return this.depositingOffer && this.depositingOffer === this.application.offer?.id
    },
    isArchived () {
      return this.application.status === GigApplicationStatuses.ARCHIVED
    },
    isOwner () {
      return this.application.freelancer.id === this.userId
    },
    isClient () {
      return this.application?.offer?.customerId === this.userId
    },
    isEmployer () {
      return this.isCustomer && this.isClient
    },
    isWorker () {
      return this.isFreelancer && this.isOwner
    },
    isStarted () {
      return !!this.offer?.job
    },
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.offer?.job?.stage)
    },
    deadline () {
      return addSeconds(convertToLocal(this.offer?.job?.created_at), this.offer?.deadline)
    },
    deadlineHasCome () {
      return isBefore(this.deadline, Date.now())
    },
    refundDate () {
      return addDays(this.deadline, EXTRA_DAYS)
    },
    refundHasCome () {
      return isBefore(this.refundDate, Date.now())
    },
    hasCreateOffer () {
      return this.isWorker && this.isOwner && !this.isArchived && this.isFreelancer && !this.application?.offer
    },
    hasGigDone () {
      return this.isWorker && !this.offer?.isDone && this.offer?.job?.stage === GigJobStages.IN_PROGRESS
    },
    hasGigWasDone () {
      return this.offer?.isDone && this.offer?.job?.stage === GigJobStages.IN_PROGRESS
    },
    hasCancelCustomerGig () {
      return this.isCustomer && !this.isArchived && !this.isOwner && !this.offer?.job
    },
    hasCancelFreelancerGig () {
      return this.isFreelancer &&
        !this.isArchived &&
        !this.offer?.job &&
        this.application.offer?.stage !== GigOfferStages.ACCEPTED_BY_CUSTOMER
    },
    hasStartJob () {
      return this.isEmployer && !this.isStarted && this.application.offer?.stage === GigOfferStages.ACCEPTED_BY_CUSTOMER
    },
    hasPayJob () {
      return this.isEmployer && this.offer?.job?.stage === GigJobStages.IN_PROGRESS && !this.hasGigWasDone
    },
    hasAcceptJob () {
      return this.isEmployer && this.offer?.job?.stage === GigJobStages.IN_PROGRESS && this.hasGigWasDone
    },
    hasCreateDispute () {
      return this.isWorker && this.offer?.job?.stage === GigJobStages.IN_PROGRESS && this.deadlineHasCome
    },
    hasRefund () {
      return this.isEmployer && this.offer?.job?.stage === GigJobStages.IN_PROGRESS && this.refundHasCome
    },
    hasReviewed () {
      return this.isCompleted && this.offer.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    hasLeaveReview () {
      return this.isCompleted && !this.offer.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    hasViewPdf () {
      return this.offer?.document
    },
  },
  methods: {
    ...mapMutations({
      setDepositingOffer: 'chatNew/setDepositingOffer',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      closeRoom: 'chatNew/closeRoom',
      signCreateContractV2: 'gig/signCreateContractV2',
      signPayToFreelancerV1: 'gig/signPayToFreelancerV1',
      signPayToFreelancerV2: 'gig/signPayToFreelancerV2',
      returnFundsToCustomerV1: 'gig/returnFundsToCustomerV1',
      refundToCustomerByCustomerV2: 'gig/refundToCustomerByCustomerV2',
    }),
    emitRefresh () {
      return this.$emit('refresh')
    },
    markApplicationAsRead () {
      if (this.isFreelancer && !this.application.isRead) {
        readApplications(this.application.id)
        this.$emit('application-read')
      }
    },
    onCreateOfferClick () {
      const budget = Number(this.application.budget) || 15
      const deadline = this.application.deadline > 0
        ? this.application.deadline / 86400
        : 0
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
          title: 'Send an offer',
          props: {
            applicationId: this.room.applicationId,
            initBudget: budget,
            initDays: deadline,
            gig: this.application.gig,
            onSuccess: () => {
              this.markApplicationAsRead()
              this.emitRefresh()
            }
          }
        }
      })
    },
    onCancelFreelancerGigClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CancelGig/CancelGig.vue'),
          props: {
            applicationId: this.application.id,
            userName: this.application.customer.name,
            onSuccess: () => {
              this.markApplicationAsRead()
              this.$router.push({ name: CHAT })
            },
          },
        },
      })
    },
    onCancelCustomerGigClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CancelGig/CancelGig.vue'),
          props: {
            applicationId: this.application.id,
            userName: this.application.freelancer.name,
            asCustomer: true,
            onSuccess: () => {
              this.$router.push({ name: CHAT })
            },
          },
        },
      })
    },
    async onDoneClick () {
      this.donning = true
      try {
        await notifiableRequest({
          request: async () => {
            await doneGig(this.offer.job.id)
            this.emitRefresh()
          },
          title: 'Completing gig',
          successText: 'Gig marked as complete.',
          failureText: 'Error submitting request. Please try again.'
        })
      } finally {
        this.donning = false
      }
    },
    async onCancelGigDoneClick () {
      this.cancelDone = true
      try {
        await notifiableRequest({
          request: async () => {
            await cancelDoneGig(this.offer.job.id)
            this.emitRefresh()
          },
          title: 'Completing gig',
          successText: 'Gig marked as incomplete.',
          failureText: 'Error submitting request. Please try again.'
        })
      } finally {
        this.cancelDone = false
      }
    },
    onReportClick () {
      const toUser = this.room.participants.find((user: ChatParticipant) => +user.userId !== this.userId)
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ReportUser/ReportUser.vue'),
          props: {
            toUserId: toUser.userId,
            toUserName: toUser.name,
          },
        },
      })
    },
    onStartJobClick () {
      this.startJob()
    },
    async startJob () {
      try {
        this.starting = true
        this.setDepositingOffer(this.application.offer?.id)
        this.signCreateContractV2({
          gig: this.offer,
          successSign: () => {
            this.openSnackbar({
              type: this.SnackTypes.SUCCESS,
              text: 'Your funds have successfully been deposited to escrow.',
            })
            this.starting = false
            this.emitRefresh()
          },
          cancelSign: () => {
            this.starting = false
            this.setDepositingOffer(null)
          }
        })
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.starting = false
        this.setDepositingOffer(null)
      }
    },
    onPayToTalentClick (onAccept: boolean) {
      if (this.offer.contractVersion === 1 || !this.offer.contractVersion) {
        this.signPayToFreelancerV1({
          gig: this.offer,
          successSign: (
            { tx, walletName, currency, blockchain }:
            { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
          ) => {
            this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
            this.emitRefresh()
          }
        })
      } else if (this.offer.contractVersion === 2) {
        this.signPayToFreelancerV2({
          gig: this.offer,
          successSign: (
            { tx, walletName, currency, blockchain }:
            { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
          ) => {
            this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
            this.emitRefresh()
          }
        })
      }
    },
    sendSuccessAnalytics (tx: string, walletName: string, currency: Currency, blockchain: Blockchain) {
      googleAnalyticsV2.send({
        event: 'offer-gig-payment',
        'event-content': tx,
        offer_id: this.offer.id,
        gig_id: this.application.gigId,
        block_type: BlockchainNames[blockchain],
        currency: currency.name,
        sum: this.offer.job.escrow_balance
          ? convertToUsd({
            value: this.offer.job.escrow_balance,
            currency,
            rates: this.rates,
          })
          : '0',
        wallet_integration_type: walletName,
      })
    },
    onCreateDisputeClick () {
      if (this.deadlineHasCome) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CreateDispute/CreateDispute.vue'),
            title: 'Open a dispute',
            props: {
              disputeData: {
                type: DISPUTE_GIG_TYPE,
                id: this.offer.job.id,
                name: this.offer.name,
                blockchain: this.offer.blockchain,
              },
              onSuccess: () => {
                this.emitRefresh()
              }
            }
          }
        })
      } else {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `You can only initiate a dispute once the deadline has been reached.`,
        })
      }
    },
    onRefundClick () {
      const refundMethod = this.offer.contractVersion === 1 || !this.offer.contractVersion
        ? 'returnFundsToCustomerV1'
        : 'refundToCustomerByCustomerV2'
      if (process.env.VUE_APP_MODE === 'dev') {
        this[refundMethod]({
          gig: this.offer,
          successSign: () => {
            this.emitRefresh()
          }
        })
      } else {
        if (this.refundHasCome) {
          this[refundMethod]({
            gig: this.offer,
            successSign: () => {
              this.emitRefresh()
            }
          })
        } else {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `You can only initiate a refund 48 hours after the deadline has been reached.`,
          })
        }
      }
    },
    onLeaveReviewClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/LeaveFeedback/LeaveFeedback.vue'),
          title: 'Leave a review',
          props: {
            fromFreelancer: this.isFreelancer,
            gigId: this.offer.job.id,
            name: this.offer.name,
            profile: this.isFreelancer ? this.offer.customerProfile : this.offer.freelancerProfile,
            onSuccess: () => {
              this.emitRefresh()
            },
          }
        }
      })
    },
  }
})
