import { Badge } from '../Badge'

export default class Badges {
  static InProgress: Badge = {
    cssClass: 'blue-status',
    name: 'In progress',
    description: 'Customer has escrowed funds and freelancer is working on the task',
  }
  static Dispute: Badge = {
    cssClass: 'red-status',
    name: 'In dispute',
    description: 'Dispute initiated by freelancer - job frozen',
  }
  static Resolved: Badge = {
    cssClass: 'blue-status',
    name: 'Resolved',
    description: 'Dispute resolved by third-party arbitration',
  }
  static Review: Badge = {
    cssClass: 'red-status',
    name: 'Waiting for feedback',
  }
  static Refunded: Badge = {
    cssClass: 'blue-status',
    name: 'Refunded',
    description: 'Task incomplete/unsatisfactory and funds returned to customer',
  }
  static Completed: Badge = {
    cssClass: 'blue-status',
    name: 'Completed',
    description: 'Task completed and funds paid to freelancer',
  }
}
