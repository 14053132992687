import Vue from 'vue'
import BigNumber from 'bignumber.js'
import { mapActions, mapMutations } from 'vuex'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import Skill from '@/models-ts/Skill'
import { BUTTON_TRANSPARENT_MEDIUM } from '@/constants/components/button'
import { PositionType, POSITION_TYPES } from '@/constants/vacancies/positionTypes'
import {
  BROWSE_VACANCIES_BY_ROLE,
  BROWSE_VACANCIES_BY_SKILL,
  CUSTOMER_PROFILE,
  VACANCIES_MY_PUBLISHED,
  VACANCY_DETAILS_ADMIN_APPLICATIONS,
  VACANCY_EDIT,
  VACANCIES_MY_DRAFTS
} from '@/constants/routes'
import { getHumanDate } from '@/utils/date'
import { formatUsd } from '@/utils/moneyFormat'
import { Stages, Statuses } from '@/constants/vacancies/statuses'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import Button from '@/models-ts/ui/Button'
import {
  VacancyModerationStages,
  VacancyModerationStagesExplanation
} from '@/constants/vacancies/vacancyModerationStages'
import { SalaryTypes } from '@/constants/vacancies/SalaryTypes'
import VacancyStatusBadge from '@/partials/StatusBadges/VacancyStatusBadge/VacancyStatusBadge.vue'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { formatCardsDescription } from '@/utils-ts/strings'
import VacancyLocationTooltip from '@/partials/VacancyLocationTooltip/VacancyLocationTooltip.vue'
import { unArchiveVacancy } from '@/api/vacancies'
import snackMixin from '@/mixins/snackMixin'

export default Vue.extend<any, any, any, any>({
  components: {
    VacancyStatusBadge,
    VacancyLocationTooltip,
  },
  mixins: [snackMixin],
  props: {
    vacancy: [MyVacancyListItem],
  },
  data () {
    return {
      BUTTON_TRANSPARENT_MEDIUM,
      CUSTOMER_PROFILE,
      VACANCY_DETAILS_ADMIN_APPLICATIONS,
      VACANCY_EDIT,
      publishing: false,
      unArchiving: false,
    }
  },
  computed: {
    description () {
      return formatCardsDescription(this.vacancy.description)
    },
    isPublished () {
      return this.vacancy.status === Statuses.PUBLISHED
    },
    createdAt () {
      const date: string = this.vacancy.published_at || this.vacancy.updated_at
      return getHumanDate(date)
    },
    userId () {
      return this.vacancy.user?.id
    },
    userAvatar () {
      return this.vacancy.user?.avatar
    },
    userType () {
      return this.vacancy.user?.type
    },
    userName () {
      return this.vacancy.user?.name
    },
    positionType () {
      return POSITION_TYPES[this.vacancy.position_type as PositionType]?.name
    },
    isRangeSalary () {
      return this.vacancy.salary_type === SalaryTypes.RANGE
    },
    salaryFrom () {
      const salaryFrom = new BigNumber(this.vacancy.salary_from).div(12)
      return salaryFrom.gt(100)
        ? formatUsd(salaryFrom, 0)
        : formatUsd(salaryFrom, 2)
    },
    salaryTo () {
      const salaryTo = new BigNumber(this.vacancy.salary_to).div(12)
      return salaryTo.gt(100)
        ? formatUsd(salaryTo, 0)
        : formatUsd(salaryTo, 2)
    },
    roleTag () {
      return this.vacancy.primaryRole?.id
        ? {
          text: this.vacancy.primaryRole.name,
          link: { name: BROWSE_VACANCIES_BY_ROLE, params: { role: this.vacancy.primaryRole.url } },
        }
        : null
    },
    skills () {
      const skills: Array<Skill> = (this.vacancy.skills || [])
      if (!this.roleTag && (!skills || skills.length <= 0)) {
        return []
      }
      const skillsRet = skills.slice(0, 2).map(skill => ({
        text: skill.name,
        link: {
          name: BROWSE_VACANCIES_BY_SKILL,
          params: { skill: skill.url }
        }
      }))
      return this.roleTag
        ? [this.roleTag, skillsRet[0]]
        : skillsRet
    },
    moreSkills (): number | undefined {
      if (this.skills.length > 1) {
        return this.skills.length - 1
      }
    },
    remoteInfo () {
      return this.vacancy.remoteInfo
    },
    status () {
      if (this.vacancy.stage === Stages.COMPLETED || this.vacancy.stage === Stages.ARCHIVED) {
        return {
          name: 'Archived',
          description: 'You have already selected a freelancer and your job is in progress.'
        }
      }
      return VacancyModerationStagesExplanation[this.vacancy.moderationStage as VacancyModerationStages]
    },
    failedModeration () {
      return this.vacancy.moderationStage === VacancyModerationStages.FAILED
    },
    passedModeration () {
      return this.vacancy.moderationStage === VacancyModerationStages.PASSED
    },
    hasActions (): boolean {
      return (this.vacancy.stage === Stages.NEW && !this.failedModeration) ||
        this.vacancy.stage === Stages.ARCHIVED
    },
    hasUnArchiveAction (): boolean {
      return this.vacancy.stage === Stages.ARCHIVED
    },
  },
  methods: {
    ...mapActions({
      publish: 'myVacancies/publish',
      unpublish: 'myVacancies/unpublish',
      openModal: 'ui/openModal',
    }),
    ...mapMutations({
      unArchiveCustomer: 'myVacancies/unArchiveCustomer'
    }),
    formatUsd,
    async onPublishClick () {
      try {
        this.publishing = true
        await notifiableRequest({
          request: () => this.publish(this.vacancy.id),
          title: 'Publish job',
          successText: (moderationStage) => moderationStage === VacancyModerationStages.MANUAL
            ? 'Your Job was successfully submitted for moderation. We will notify you of the outcome shortly.'
            : `Job: ${this.vacancy.name} was successfully published`,
          failureText: 'Error publishing Full-time Job. Please try again.'
        })
        googleAnalyticsV2.send({
          event: 'ftj-published',
          ftj_id: this.vacancy.id,
        })
      } finally {
        this.publishing = false
      }
    },
    async onUnpublishClick () {
      try {
        this.publishing = true
        this.openModal({
          component: 'lx-composite-modal-new',
          props: {
            title: 'Are you sure you want to unpublish this Full-time Job?',
            text: `Once a Full-time Job has been unpublished, all talent applications wil be automatically declined.`,
            buttons: [
              new Button({
                text: 'Cancel',
                classes: 'lx-transparent-blue-btn',
              }),
              new Button({
                text: 'Unpublish',
                classes: 'lx-blue-btn',
                onClick: async () => {
                  try {
                    this.publishing = true
                    await notifiableRequest({
                      request: () => this.unpublish(this.vacancy.id),
                      title: 'Unpublish job',
                      successText: `You have successfully unpublished your Full-time Job.`,
                      failureText: 'Error unpublishing Full-time Job. Please try again.'
                    })
                    googleAnalyticsV2.send({
                      event: 'ftj-drafted',
                      ftj_id: this.vacancy.id,
                    })
                  } finally {
                    this.publishing = false
                  }
                },
              }),
            ]
          }
        })
      } finally {
        this.publishing = false
      }
    },
    async onUnArchiveClick () {
      this.unArchiving = true
      await notifiableRequest({
        request: async () => {
          try {
            await unArchiveVacancy(this.vacancy.id)
            this.unArchiveCustomer(new MyVacancyListItem({
              ...this.vacancy,
              stage: Stages.NEW
            }))
            if (this.vacancy.status === Statuses.DRAFT) {
              this.$emit('changeTab', VACANCIES_MY_DRAFTS)
            }
            if (this.vacancy.status === Statuses.PUBLISHED) {
              this.$emit('changeTab', VACANCIES_MY_PUBLISHED)
            }
          } catch (err) {
            throw err
          } finally {
            this.unArchiving = false
          }
        },
        title: 'Offer accepted',
        successText: `Job <b>${this.vacancy.name}</b> has been unarchived.`,
        loadingText: `Job <b>${this.vacancy.name}</b> is being unarchived.`,
        failureText: 'Error unarchiving Job. Please try again.',
      })
    }
  },
})
