import Vue from 'vue'
import { mapActions } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import {
  SERVICE_ADD,
  SERVICE_MY_POSTED,
  SERVICE_MY_OFFERS,
  SERVICE_MY_IN_PROGRESS,
  SERVICE_MY_COMPLETED,
  SERVICE_MY_DRAFTS,
  SERVICE_MY_ARCHIVED
} from '@/constants/routes'
import ratesMixin from '@/mixins/ratesMixin'
import FreelancerArchivedTab from './FreelancerArchivedTab/FreelancerArchivedTab.vue'
import FreelancerPostedTab from './FreelancerPostedTab/FreelancerPostedTab.vue'
import FreelancerDraftsTab from './FreelancerDraftsTab/FreelancerDraftsTab.vue'
import FreelancerOffersTab from './FreelancerOffersTab/FreelancerOffersTab.vue'
import FreelancerInProgressTab from './FreelancerInProgressTab/FreelancerInProgressTab.vue'
import FreelancerCompletedTab from './FreelancerCompletedTab/FreelancerCompletedTab.vue'
import CustomerOffersTab from './CustomerOffersTab/CustomerOffersTab.vue'
import CustomerInProgressTab from './CustomerInProgressTab/CustomerInProgressTab.vue'
import CustomerCompletedTab from './CustomerCompletedTab/CustomerCompletedTab.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, ratesMixin],
  data () {
    return {
      currentTab: 0,
      SERVICE_ADD,
    }
  },
  computed: {
    tabs () {
      return this.isFreelancer
        ? [
          { title: 'Posted', component: FreelancerPostedTab, to: { name: SERVICE_MY_POSTED } },
          { title: 'Offers', component: FreelancerOffersTab, to: { name: SERVICE_MY_OFFERS } },
          {
            title: 'In Progress',
            component: FreelancerInProgressTab,
            to: { name: SERVICE_MY_IN_PROGRESS },
          },
          { title: 'Completed', component: FreelancerCompletedTab, to: { name: SERVICE_MY_COMPLETED } },
          { title: 'Drafts', component: FreelancerDraftsTab, to: { name: SERVICE_MY_DRAFTS } },
          { title: 'Archived', component: FreelancerArchivedTab, to: { name: SERVICE_MY_ARCHIVED } },
        ]
        : [
          { title: 'Offers', component: CustomerOffersTab, to: { name: SERVICE_MY_OFFERS } },
          {
            title: 'In Progress',
            component: CustomerInProgressTab,
            to: { name: SERVICE_MY_IN_PROGRESS },
          },
          { title: 'Completed', component: CustomerCompletedTab, to: { name: SERVICE_MY_COMPLETED } },
        ]
    },
  },
  watch: {
    $route: {
      handler () {
        this.currentTab = this.$route.name
      },
      immediate: true,
    }
  },
})
