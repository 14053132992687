import { mapActions, mapMutations } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import { BUTTON_BLUE_MEDIUM } from '@/constants/components/button'
import snackMixin from '@/mixins/snackMixin'

export default {
  mixins: [responseMixin, snackMixin],
  data () {
    return {
      BUTTON_BLUE_MEDIUM,
      oldPasswordVisible: false,
      newPasswordVisible: false,
      repeatPasswordVisible: false,
      oldPassword: '',
      newPassword: '',
      repeatPassword: '',
      processing: false,
      isPasswordChanging: false,
    }
  },
  methods: {
    ...mapMutations({
      confirmed2FA: 'ui/confirmed2FA',
    }),
    ...mapActions({
      changePassword: 'user/changePassword',
      openModal: 'ui/openModal',
    }),
    async onChangePasswordSubmit () {
      try {
        this.processing = true
        if (await this.$validator.validateAll()) {
          await this.changePassword({ oldPassword: this.oldPassword, newPassword: this.newPassword })
          this.oldPassword = ''
          this.newPassword = ''
          this.repeatPassword = ''
          this.openSnackbar({
            type: this.SnackTypes.SUCCESS,
            text: 'Your password was successfully changed.',
          })
        }
      } catch (e) {
        if (ErrorMatcher.is2FA(e)) {
          this.openModal({
            component: 'lx-confirm-2fa-modal',
            props: {
              confirm: async (key) => {
                await this.changePassword({ oldPassword: this.oldPassword, newPassword: this.newPassword, key })
                this.confirmed2FA()
                this.oldPassword = ''
                this.newPassword = ''
                this.repeatPassword = ''
                this.openSnackbar({
                  type: this.SnackTypes.SUCCESS,
                  text: 'Your password was successfully changed.',
                })
              }
            }
          })
        } else {
          this.parseError(e)
          if (this.errors.count() === 0) {
            this.openSnackbar({
              type: this.SnackTypes.FAILURE,
              text: 'Error changing password. Please try again.',
            })
          }
        }
      } finally {
        this.processing = false
      }
    },
  },
}
