import Vue from 'vue'
import {
  VACANCIES_MY_ALL,
  VACANCIES_MY_APPLIED,
  VACANCIES_MY_ARCHIVED,
  VACANCIES_MY_DRAFTS,
  VACANCIES_MY_IN_PROGRESS,
  VACANCIES_MY_PUBLISHED
} from '@/constants/routes'
import rolebleMixin from '@/mixins/rolebleMixin'
import CustomerPublishedTab from './CustomerPublishedTab/CustomerPublishedTab.vue'
import CustomerDraftsTab from './CustomerDraftsTab/CustomerDraftsTab.vue'
import CustomerArchivedTab from './CustomerArchivedTab/CustomerArchivedTab.vue'
import FreelancerAllTab from './FreelancerAllTab/FreelancerAllTab.vue'
import FreelancerAppliedTab from './FreelancerAppliedTab/FreelancerAppliedTab.vue'
import FreelancerInProgressTab from './FreelancerInProgressTab/FreelancerInProgressTab.vue'
import FreelancerArchivedTab from './FreelancerArchivedTab/FreelancerArchivedTab.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  data () {
    return {
      currentTab: 0,
    }
  },
  computed: {
    tabs () {
      return this.isFreelancer
        ? [
          { title: 'All', component: FreelancerAllTab, to: { name: VACANCIES_MY_ALL } },
          { title: 'Applied', component: FreelancerAppliedTab, to: { name: VACANCIES_MY_APPLIED } },
          { title: 'In Progress', component: FreelancerInProgressTab, to: { name: VACANCIES_MY_IN_PROGRESS } },
          { title: 'Archived', component: FreelancerArchivedTab, to: { name: VACANCIES_MY_ARCHIVED } },
        ]
        : [
          { title: 'Published', component: CustomerPublishedTab, to: { name: VACANCIES_MY_PUBLISHED } },
          { title: 'Drafts', component: CustomerDraftsTab, to: { name: VACANCIES_MY_DRAFTS } },
          { title: 'Archived', component: CustomerArchivedTab, to: { name: VACANCIES_MY_ARCHIVED } },
        ]
    },
  },
  watch: {
    $route: {
      handler () {
        this.currentTab = this.$route.name
      },
      immediate: true,
    }
  },
  methods: {
    async onChangeTab (newRoute: string) {
      this.$router.push({ name: newRoute })
    }
  }
})
