import { render, staticRenderFns } from "./ContactsSection.htm?vue&type=template&id=f9a5acee&scoped=true&"
import script from "./ContactsSection.ts?vue&type=script&lang=js&"
export * from "./ContactsSection.ts?vue&type=script&lang=js&"
import style0 from "./ContactsSection.scss?vue&type=style&index=0&id=f9a5acee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f9a5acee",
  null
  
)

export default component.exports