import Vue from 'vue'
import messageMixin from '../messageMixin'
import { formatUsd } from '@/utils/moneyFormat'
import rolebleMixin from '@/mixins/rolebleMixin'
import JobOfferChatMessage from '@/models-ts/chat/messages/JobOfferChatMessage'
import { JobOfferStagesInChat } from '@/constants/job/jobOfferStages'
import { declineOffer } from '@/api/jobs/offers'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'
import { mapMutations, mapState } from 'vuex'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin, snackMixin],
  props: {
    message: JobOfferChatMessage,
  },
  computed: {
    ...mapState<RootState>({
      depositingOffer: (state: RootState) => state.chatNew.depositingOffer,
      acceptingOffer: (state: RootState) => state.chatNew.acceptingOffer,
      decliningOffer: (state: RootState) => state.chatNew.decliningOffer,
    }),
    depositing () {
      return this.depositingOffer && this.depositingOffer === this.message.offerId
    },
    accepting () {
      return this.acceptingOffer && this.acceptingOffer === this.message.offerId
    },
    declining () {
      return this.decliningOffer && this.decliningOffer === this.message.offerId
    },
    isNew () {
      return this.message.stage === JobOfferStagesInChat.NEW
    },
    isDeclined () {
      return [JobOfferStagesInChat.DECLINED_BY_FREELANCER, JobOfferStagesInChat.DECLINED_BY_CUSTOMER].includes(this.message.stage)
    },
    whoDeclined () {
      if (this.isFreelancer) {
        return this.message.stage === JobOfferStagesInChat.DECLINED_BY_FREELANCER ? 'YOU' : 'CUSTOMER'
      } else {
        return this.message.stage === JobOfferStagesInChat.DECLINED_BY_CUSTOMER ? 'YOU' : 'TALENT'
      }
    },
    isAccepted () {
      return [JobOfferStagesInChat.ACCEPTED_BY_CUSTOMER].includes(this.message.stage)
    },
    isEscrowed () {
      return this.isAccepted && this.message.isEscrowed
    },
    deadline () {
      return `${this.message.deadlineInDays} days delivery`
    },
    budget () {
      return formatUsd(this.message.budget!)
    },
    waitForDeposit () {
      return this.isAccepted && !this.message.isEscrowed
    },
  },
  methods: {
    ...mapMutations({
      setAcceptingOffer: 'chatNew/setAcceptingOffer',
      setDecliningOffer: 'chatNew/setDecliningOffer',
    }),
    async onCancelOfferClick () {
      try {
        this.setDecliningOffer(this.message.offerId)
        googleAnalyticsV2.send({
          event: 'response-to-job',
          'event-content': this.isCustomer
            ? 'customer-button-click-decline-offer-from-chat'
            : 'freelancer-button-click-decline-offer-from-chat',
        })
        await declineOffer({ id: this.message.offerId, comment: 'Not specified' })
        this.$emit('cancel', this.message.offerId)
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error declining offer. Please try again.',
        })
      } finally {
        this.setDecliningOffer(null)
      }
    },
    async onEditOfferClick () {
      this.$emit('edit', this.message.offerId)
    },
    async onAcceptClick () {
      googleAnalyticsV2.send({
        event: 'response-to-job',
        'event-content': 'customer-button-click-hire-offer-from-chat',
      })
      googleAnalyticsV2.send({ event: 'job_accept_click' })
      this.$emit('accept', this.message.offerId)
    },
    onDepositClick () {
      this.$emit('deposit')
    },
  },
})
