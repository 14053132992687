import Vue from 'vue'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import rolebleMixin from '@/mixins/rolebleMixin'
import messageMixin from '../messageMixin'
import GigMarkedDoneChatMessage from '@/models-ts/chat/messages/GigMarkedDoneChatMessage'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin],
  props: {
    message: GigMarkedDoneChatMessage,
    room: ChatRoom,
  },
  computed: {
    msg () {
      if (this.message.isPayed) {
        if (this.isOwn) {
          return `Good job! Customer confirmed that Gig is done. Please rate this Gig.`
        } else {
          return `Good job! You confirmed that Gig is done. Please rate this Gig.`
        }
      } else if (this.message.markIsDone) {
        if (this.isOwn) {
          return `After successful confirming review by customer you'll receive your reward`
        } else {
          return `Talent marked Gig as complete.`
        }
      } else {
        const declinedFreelancer = this.message.sender === this.message.initiator
        if (this.isOwn) {
          return `${declinedFreelancer ? 'You' : 'Customer'} declined Gig's completion.`
        } else {
          return `${declinedFreelancer ? 'Talent' : 'You'} declined Gig's completion`
        }
      }
    },
  },
})
