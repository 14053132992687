import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapState } from 'vuex'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import GigDisputeChatMessage from '@/models-ts/chat/messages/GigDisputeChatMessage'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import rolebleMixin from '@/mixins/rolebleMixin'
import zendeskMixin from '@/mixins/zendeskMixin'
import messageMixin from '../messageMixin'
import JobDisputeChatMessage from '@/models-ts/chat/messages/JobDisputeChatMessage'
import { MessageTypes } from '@/constants/chat/MessageTypes'
import { STAGE_BLOCKED_BY_FREELANCER } from '@/constants/job/jobStages'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin, zendeskMixin],
  props: {
    message: [GigDisputeChatMessage, JobDisputeChatMessage],
    room: ChatRoom,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    byGig () {
      return this.message.type === MessageTypes.GIG_DISPUTE
    },
    entity () {
      return this.byGig ? 'gig' : 'job'
    },
    isNew () {
      return this.byGig
        ? this.message.body.stage === GigJobStages.BLOCKED
        : this.message.body.stage === STAGE_BLOCKED_BY_FREELANCER
    },
    participant () {
      return this.room.participants.find((user: ChatParticipant) => user.userId !== String(this.userId))
    },
    msg () {
      if (this.isOwn) {
        return `You have initiated dispute with Customer ${this.participant.name} about ${this.entity} “${this.room.entity?.name}”.`
      } else {
        return `Talent ${this.participant.name} have initiated dispute with you about ${this.entity} “${this.room.entity?.name}”.`
      }
    },
    myStake () {
      return new BigNumber(this.isFreelancer ? this.message.body.freelancerStake : this.message.body.customerStake)
    },
    strangerStake () {
      return new BigNumber(this.isFreelancer ? this.message.body.customerStake : this.message.body.freelancerStake)
    },
    isLost () {
      return this.myStake.isZero()
    },
    isWon () {
      return this.strangerStake.isZero()
    },
    myPart () {
      return this.myStake.dividedBy(this.myStake.plus(this.strangerStake)).multipliedBy(100).toNumber().toFixed(0)
    },
  },
  methods: {
    onClickSupport () {
      if (!this.isZendeskLoading) {
        this.openZendesk('My account')
      }
    },
  }
})
