import Vue from 'vue'
import { mapState } from 'vuex'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import rolebleMixin from '@/mixins/rolebleMixin'
import { DASHBOARD, PROFILE_SETTINGS } from '@/constants/routes'
import { getMyFreelancerProfile } from '@/api/users'
import ActionsSection from '../ActionsSection/ActionsSection.vue'
import GeneralTab from './GeneralTab/GeneralTab.vue'
import FreelanceTab from './FreelanceTab/FreelanceTab.vue'
import FullTimeTab from './FullTimeTab/FullTimeTab.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  components: {
    ActionsSection,
    FreelanceTab,
    FullTimeTab,
    GeneralTab,
  },
  data () {
    return {
      DASHBOARD,
      PROFILE_SETTINGS,
      profile: null,
    }
  },
  computed: {
    ...mapState('user', {
      userInfoLoaded: (state: any) => state.profile.isLoaded,
      userInfoLoading: (state: any) => state.profile.isLoading,
    }),
  },
  watch: {
    userInfoLoaded: {
      async handler () {
        if (this.activeRole) {
          this.profile = MyFreelancerProfile.fromServer(await getMyFreelancerProfile())
        }
      },
      immediate: true,
    }
  },
  methods: {
    onProfileUpdate (profile: MyFreelancerProfile) {
      this.profile = profile
    },
  }
})
