import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import JobApplication from '@/models-ts/job/JobApplication'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  props: {
    application: JobApplication,
  },
  computed: {
    inProgress () {
      return Boolean(this.application?.wasStarted)
    },
  },
})
