import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import JobRoomUnlockedChatMessage from '@/models-ts/chat/messages/JobRoomUnlockedChatMessage'
import messageMixin from '../messageMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin, rolebleMixin],
  props: {
    message: JobRoomUnlockedChatMessage,
  },
})
