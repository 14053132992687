import BigNumber from 'bignumber.js'
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { formatUsd } from '@/utils/moneyFormat'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import rolebleMixin from '@/mixins/rolebleMixin'
import sleep from '@/utils/sleep'
import snackMixin from '@/mixins/snackMixin'
import JobApplication from '@/models-ts/job/JobApplication'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { applyOffer, declineOffer } from '@/api/jobs/offers'
import Job from '@/models-ts/job/Job'
import { getJob } from '@/api/jobs/job'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import { RootState } from '@/store'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin, snackMixin],
  props: {
    application: {
      type: JobApplication,
      required: true,
    },
  },
  data () {
    return {
      starting: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      acceptingOffer: (state: RootState) => state.chatNew.acceptingOffer,
      decliningOffer: (state: RootState) => state.chatNew.decliningOffer,
      userId: (state: any) => state.user?.id,
    }),
    ...mapGetters({
      backendConfig: 'user/getConfigMap',
    }),
    appOffer () {
      return this.application.offer
    },
    accepting () {
      return this.acceptingOffer && this.acceptingOffer === this.appOffer?.id
    },
    declining () {
      return this.decliningOffer && this.decliningOffer === this.appOffer?.id
    },
    isOwn () {
      return this.appOffer?.freelancerId === this.userId
    },
    isClient () {
      return this.appOffer?.customerId === this.userId
    },
    isArchived () {
      return this.application.status === JobApplicationStatuses.ARCHIVED
    },
    isMetaArchived () {
      return this.application.meta?.archived
    },
    isNewOffer () {
      return this.appOffer?.stage === JobOfferStages.NEW
    },
    deadline () {
      return `${this.appOffer?.deadlineInDays} days delivery`
    },
    budget () {
      return formatUsd(new BigNumber(this.appOffer?.budget))
    },
    hasActionsBtn () {
      return this.appOffer?.stage === JobOfferStages.NEW
    },
  },
  methods: {
    ...mapMutations({
      setDecliningOffer: 'chatNew/setDecliningOffer',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      setApplicationMeta: 'jobDetails/setApplicationMeta',
      signCreateContractV2: 'jobDetails/signCreateContractV2',
    }),
    emitRefresh () {
      return this.$emit('refresh')
    },
    async onCancelOfferClick () {
      try {
        this.setDecliningOffer(this.appOffer.id)
        if (this.isCustomer) {
          googleAnalyticsV2.send({
            event: 'response-to-job',
            'event-content': 'customer-button-click-decline-offer',
            job_id: this.application.job.id,
          })
        }
        await declineOffer({ id: this.appOffer.id, comment: 'Not specified' })
        this.$emit('cancel', this.appOffer.id)
      } catch (e) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error declining offer. Please try again.',
        })
      } finally {
        this.setDecliningOffer(null)
      }
    },
    async onEditOfferClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
          title: 'Send an offer',
          props: {
            applicationId: this.application.id,
            offer: this.appOffer,
            initBudget: new BigNumber(this.appOffer.budget).toNumber(),
            initDays: this.appOffer.deadlineInDays,
            initHours: this.appOffer.timeValue,
            onSuccess: () => {
              this.emitRefresh()
            }
          }
        }
      })
    },
    async onAcceptClick () {
      if (this.isMetaArchived) {
        const meta = { ...this.application.meta, archived: false }
        await this.setApplicationMeta({
          jobId: this.application.job.id,
          applicationId: this.application.id,
          meta,
        })
        this.$emit('update-meta', meta)
      }
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/AcceptTalentsOffer/AcceptTalentsOffer.vue'),
          title: `Accept Talent's Offer`,
          props: {
            application: this.application,
            onSuccess: async () => {
              this.starting = true
              googleAnalyticsV2.send({
                event: 'response-to-job',
                'event-content': 'customer-button-click-hire-offer',
                job_id: this.application.job.id,
              })
              googleAnalyticsV2.send({
                event: 'job_accept_click',
                job_id: this.application.job.id,
              })
              this.emitRefresh()
              this.startJob()
            },
          }
        }
      })
    },
    async startJob () {
      try {
        this.starting = true
        const job = Job.fromServer(await getJob(`${this.application.job.slug}-${this.application.job.id}`))
        if (job) {
          this.signCreateContractV2({
            params: {
              deadline: this.appOffer.deadline,
              rate: this.appOffer.budget,
              // @ts-ignore
              freelancerWallets: job?.meta?.FreelancerWallets || [],
              id: job.id,
              slug: job.slug,
              scId: job.sc_id,
              name: job.name,
              offerId: this.appOffer.id,
              freelancer: {
                ...job.freelancer,
                wallet: this.appOffer.freelancerWallet,
              },
            },
            successSign: () => {
              this.openSnackbar({
                type: this.SnackTypes.SUCCESS,
                text: 'Your funds have successfully been deposited to escrow.',
              })
              this.starting = false
              this.emitRefresh()
            },
            cancelSign: () => {
              this.starting = false
            },
          })
        }
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.starting = false
      }
    },
  }
})
