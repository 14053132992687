import Vue from 'vue'
import messageMixin from '../messageMixin'
import { mapState } from 'vuex'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import GigRefundedChatMessage from '@/models-ts/chat/messages/GigRefundedChatMessage'
import { MessageTypes } from '@/constants/chat/MessageTypes'
import JobRefundedChatMessage from '@/models-ts/chat/messages/JobRefundedChatMessag'

export default Vue.extend<any, any, any, any>({
  mixins: [messageMixin],
  props: {
    message: [GigRefundedChatMessage, JobRefundedChatMessage],
    room: ChatRoom,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    byGig () {
      return this.message.type === MessageTypes.GIG_REFUNDED
    },
    participant () {
      return this.room.participants.find((user: ChatParticipant) => user.userId !== String(this.userId))
    },
    title () {
      if (this.isOwn) {
        return 'You have requested a refund'
      }
      return 'Customer requested a refund'
    },
    msg () {
      if (this.isOwn) {
        return `We are sorry that you weren't satisfied with ${this.participant.name}'s work.`
      } else {
        return `Customer requested a refund due to expiration of the deadline. This ${this.byGig ? 'gig' : 'job'} is cancelled.`
      }
    }
  },
})
