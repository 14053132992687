import Vue from 'vue'
import { CURRENCY_FIELD_BACKEND_ID } from '@/constants/currency'
import { convertToUsd, getCurrency } from '@/utils-ts/currencies'
import { formatCurrency } from '@/utils/moneyFormat'
import ratesMixin from '@/mixins/ratesMixin'
import { addDays, formatDate } from '@/utils/date'
import BigNumber from 'bignumber.js'
import JobApplication from '@/models-ts/job/JobApplication'

const EXTRA_DAYS = 2
const DATE_FORMAT = 'MMMM Do, YYYY hh:mm A'
export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin],
  props: {
    application: JobApplication,
  },
  computed: {
    currency () {
      return getCurrency({
        blockchain: this.application.job.blockchain,
        value: this.application.job.currency,
        field: CURRENCY_FIELD_BACKEND_ID,
      })
    },
    currencyImage () {
      return this.currency?.imageURL || '/static/images/blockchain/currencies/empty.svg'
    },
    circle () {
      return this.currency.name === 'BRUSH'
    },
    escrowBalance () {
      return new BigNumber(this.application.job.escrowBalance || 0).dividedBy(this.currency.baseUnits)
    },
    escrowBalanceFormatted () {
      return formatCurrency(this.escrowBalance, { currency: this.currency })
    },
    escrowBalanceInUsd () {
      return this.rates.length
        ? convertToUsd({ value: this.escrowBalance, currency: this.currency, rates: this.rates, format: true })
        : null
    },
    deadline () {
      return this.application.estimatedDeadline
    },
    refundDate () {
      return addDays(this.deadline, EXTRA_DAYS)
    },
  },
  methods: {
    getFormattedDate (date: Date) {
      return formatDate(date, DATE_FORMAT)
    },
  }
})
