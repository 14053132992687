import Vue from 'vue'
import InfiniteLoading from 'vue-infinite-loading'
import ChatRoomModel from '@/models-ts/chat/ChatRoom'
import ChatRoom from './ChatRoom/ChatRoom.vue'

const PAGE_SIZE = 40

export default Vue.extend<any, any, any, any>({
  components: {
    ChatRoom,
    InfiniteLoading,
  },
  data () {
    return {
      moreLoading: false,
      infiniteId: 0,
      interval: null,
    }
  },
  computed: {
    pageLoading () {
      return this.moreLoading || this.isLoading
    },
    sortedRooms () {
      return [...this.rooms].sort((a: ChatRoomModel, b: ChatRoomModel) => Number(b.lastMessageAt) - Number(a.lastMessageAt))
    },
  },
  watch: {
    isLoaded: {
      handler () {
        if (this.isLoaded) {
          this.infiniteId = +new Date()
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },
  methods: {
    async waitForRoomsLoaded () {
      return new Promise(resolve => {
        this.interval = setInterval(() => {
          if (!this.isLoading) {
            clearInterval(this.interval)
            this.interval = null
            resolve(true)
          }
        }, 100)
      })
    },
    async onLoadMore ($state: any) {
      try {
        if (this.rooms.length < this.pagination.total) {
          this.moreLoading = true
          await this.loadMore({
            limit: PAGE_SIZE,
            offset: this.rooms.length,
          })
          await this.waitForRoomsLoaded()
          return $state.loaded()
        }
        return $state.complete()
      } finally {
        this.moreLoading = false
      }
    },
  }
})
