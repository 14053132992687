import Vue, { PropType } from 'vue'
import { mapActions } from 'vuex'
import Skill from '@/models-ts/Skill'

export default Vue.extend<any, any, any, any>({
  props: {
    value: {
      type: Array as PropType<Array<Skill>>,
      default: [],
    },
    editMode: Boolean,
    errorMsg: String,
    required: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      skills: [],
    }
  },
  computed: {
    tags: {
      get () {
        return this.skills.map((skill: Skill) => ({ text: skill.name }))
      }
    },
  },
  watch: {
    value: {
      handler () {
        this.initForm()
      },
      immediate: true
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    initForm () {
      if (this.value.length) {
        this.skills = [...this.value]
      }
    },
    onDelete ({ index }: { index: number }) {
      this.skills.splice(index, 1)
      this.$emit('input', this.skills)
    },
    onAddClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "skills-modals" */ '@/modals/SkillsSelection/SkillsSelection.vue'),
          title: 'Skills',
          props: {
            skills: this.skills,
            onSuccess: (skills: Array<Skill>) => {
              this.skills = skills
              this.$emit('input', this.skills)
            }
          }
        }
      })
    },
  }
})
