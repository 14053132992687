import Vue from 'vue'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import uniq from 'lodash/uniq'
import { mapActions, mapState } from 'vuex'
import NotificationItem from '@/partials/NotificationItem/NotificationItem.vue'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import { USER_NOTIFICATIONS } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin, rolebleMixin],
  components: {
    NotificationItem,
  },
  data () {
    return {
      observer: null,
      readedIds: [],
      USER_NOTIFICATIONS,
    }
  },
  computed: {
    ...mapState({
      notifications: (state: any) => state.notifications.dashboardNotifications.values,
      isLoading: (state: any) => state.notifications.dashboardNotifications.isLoading,
      isLoaded: (state: any) => state.notifications.dashboardNotifications.isLoaded,
      unreadCount: (state: any) => state.notifications?.unreadedCount,
      userIsLoaded: (state: any) => state.user?.profile?.isLoaded,
    }),
    unreadCountFormatted () {
      return this.unreadCount > 99 ? '99+' : this.unreadCount
    },
  },
  watch: {
    userIsLoaded: {
      async handler () {
        if (this.userIsLoaded) {
          try {
            await this.loadNotifications()
            this.$watch('activeRole', async () => {
              await this.loadNotifications()
            })
            this.initObserver()
          } catch (e) {
            this.parseError(e)
          } finally {
            this.initLoading = false
          }
        }
      },
      immediate: true,
    },
  },
  beforeDestroy () {
    this.destroyObserver()
  },
  methods: {
    ...mapActions({
      loadNotifications: 'notifications/loadDashboardNotifications',
      readNotifications: 'notifications/readNotifications',
    }),
    initObserver () {
      if (this.$refs.notification) {
        const options = {
          rootMargin: '0px',
          threshold: times(10, (n) => n / 10 + 0.1)
        }
        if (!this.observer) {
          this.observer = new IntersectionObserver(this.onVisible, options)
        }
        this.$refs.notification.forEach((notify: any, i: number) => {
          if (!this.notifications[i].readed && !this.readedIds.includes(this.notifications[i].id)) {
            this.observer.observe(notify.$el || notify)
          }
        })
      }
    },
    onVisible (entries: any) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9) {
          const index = this.$refs.notification.findIndex((notify: any) => entry.target === (notify.$el || notify))
          if (this.notifications[index] && !this.notifications[index].readed) {
            this.readedIds.push(this.notifications[index].id)
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (this.readedIds.length) {
        this.readNotificationDebounced()
      }
    },
    readNotificationDebounced: debounce(async function (this: any) {
      const ids = uniq(this.readedIds)
      if (ids.length) {
        this.readedIds = this.readedIds.filter((id: number) => !ids.includes(id))
        try {
          await this.readNotifications(ids)
        } catch (e) {
          console.error('Error reading notifications', e)
          this.readedIds.push(...ids)
        }
      }
    }, 1000),
    destroyObserver () {
      if (this.observer) {
        this.observer.disconnect()
        this.observer = null
      }
    },
  },
})
