import { Badge } from '../Badge'

export default class Badges {
  static Draft: Badge = { cssClass: 'gray-status', name: 'Draft' }
  static Publish: Badge = { cssClass: 'blue-status', name: 'Published' }
  static Moderation: Badge = {
    name: 'Moderation',
    cssClass: 'orange-status',
    description: 'Your Gig is on pre-moderation. You will receive a notification within the next 12 hours that your Gig has been checked.',
  }
  static Failed: Badge = {
    name: 'Declined',
    cssClass: 'red-status',
    // eslint-disable-next-line max-len
    description: 'Our moderation team determined that your Gig violated our rules of engagement. If you have any concerns, feel free to contact our support team.',
  }
}
