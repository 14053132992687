import Vue from 'vue'
import { mapState } from 'vuex'
import JobApplication from '@/models-ts/job/JobApplication'
import {
  STAGE_BLOCKED_BY_FREELANCER,
  STAGE_COMPLETED,
  STAGE_DEADLINE_OVERDUE,
  STAGE_DISPUTED,
  STAGE_IN_PROGRESS,
} from '@/constants/job/jobStages'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'

const PAST = 1
const ACTIVE = 2
const FUTURE = 3

export default Vue.extend<any, any, any, any>({
  props: {
    application: JobApplication,
    offer: Object,
  },
  data () {
    return {
      PAST,
      ACTIVE,
      FUTURE,
    }
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    isCancelled () {
      return this.application.status === JobApplicationStatuses.ARCHIVED && !this.application.offer
    },
    isCompleted () {
      return [STAGE_COMPLETED, STAGE_DEADLINE_OVERDUE, STAGE_DISPUTED].includes(this.application.job.stage)
    },
    hasReview () {
      if (this.application.isCompleted) {
        const reviews = this.offer?.relations?.Job?.relations?.Review || []
        return reviews.find((review: any) => review.from_user_id === this.userId)
      }
      return false
    },
    statuses () {
      const statuses = []
      const offer = { title: 'Offer ', stage: PAST }
      if (!this.application?.offer || this.application.offer.stage === JobOfferStages.NEW) {
        offer.stage = ACTIVE
      }
      statuses.push(offer)

      const confirmation = { title: 'Confirmation & Deposit', stage: FUTURE }
      if (offer.stage === PAST) {
        confirmation.stage = this.application.wasStarted ? PAST : ACTIVE
      }
      statuses.push(confirmation)

      const inProgress = { title: 'In Progress', stage: FUTURE }
      if (confirmation.stage === PAST) {
        inProgress.stage = [STAGE_IN_PROGRESS, STAGE_BLOCKED_BY_FREELANCER].includes(this.application.job?.stage) ? ACTIVE : PAST
      }
      statuses.push(inProgress)

      const review = { title: 'Review', stage: FUTURE }
      if (inProgress.stage === PAST) {
        review.stage = this.hasReview ? PAST : ACTIVE
      }
      statuses.push(review)
      return [offer, confirmation, inProgress, review]
    },
  },
})
