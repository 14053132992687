import Vue from 'vue'
import BigNumber from 'bignumber.js'
import debounce from 'lodash/debounce'
import { mapActions, mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import AccountType from '@/constants/user/AccountType'
import { LOCK_MULTIPLIERS } from '@/constants/user/timeWarp'
import { PREMIUM } from '@/constants/routes'
import { formatCurrency } from '@/utils/moneyFormat'
import { formatDate } from '@/utils/date'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  data () {
    return {
      PREMIUM,
      tickPosition: 0,
    }
  },
  computed: {
    ...mapState({
      wallets: (state: any) => state.user.wallets,
      userType: (state: any) => state.user.type,
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      premiumByBlatu: (state: any) => state.user.premiumByBlatu,
      premiumExpiredAt: (state: any) => state.user.premiumExpiredAt,
      balances: (state: any) => state.balances.timeWarpBalance.value || [],
      balancesIsLoaded: (state: any) => state.balances.timeWarpBalance.isLoaded,
    }),
    isLoaded () {
      return this.userIsLoaded && this.balancesIsLoaded
    },
    accountType () {
      return AccountType.getType(this.userType)
    },
    nextAccountType () {
      return this.isNotPlatinum ? AccountType.getType(this.userType + 1) : null
    },
    isNotPremium () {
      return this.accountType === AccountType.USER_ACCOUNT_SIMPLE
    },
    isNotPlatinum () {
      return this.accountType !== AccountType.USER_ACCOUNT_PREMIUM_PLATINUM
    },
    amount () {
      // @ts-ignore
      return this.balances.reduce((total, balance) => total.plus(balance.userStacked), new BigNumber(0))
    },
    totalAmount () {
      return this.balances.reduce(
        // @ts-ignore
        (total, balance) => total.plus(balance.userStacked.multipliedBy(LOCK_MULTIPLIERS[balance.userLock])),
        new BigNumber(0)).toNumber()
    },
    leftAmount () {
      // @ts-ignore
      const maxUserLock = Math.max(...this.balances.map(b => LOCK_MULTIPLIERS[b.userLock]), 1)
      return Math.max((this.rightTick - this.totalAmount) / maxUserLock, 0)
    },
    leftTick () {
      return this.isNotPlatinum ? this.accountType.amount[0] : this.accountType.amount
    },
    rightTick () {
      return this.isNotPlatinum ? this.accountType.amount[1] : this.totalAmount
    },
    hasSlider () {
      return this.isLoaded && this.isNotPlatinum && !this.premiumByBlatu
    },
    sliderFilled () {
      if (this.hasSlider) {
        const extra = this.totalAmount - this.leftTick
        const range = this.rightTick - this.leftTick
        return Math.min(Math.max(extra / range * 100, 0), 100)
      }
      return 100
    },
    expiredFormatDate () {
      if (this.premiumExpiredAt) {
        return formatDate(this.premiumExpiredAt, 'D MMMM YYYY')
      }
    },
  },
  watch: {
    userIsLoaded: {
      async handler () {
        if (this.userIsLoaded) {
          await this.loadTimeWarpBalance(this.wallets)
        }
      },
      immediate: true
    },
    hasSlider: {
      handler () {
        if (this.hasSlider) {
          this.$nextTick(() => {
            this.moveBalance()
            window.addEventListener('resize', this.moveBalanceDebounced)
          })
        }
      },
      immediate: true,
    },
  },
  destroyed () {
    window.removeEventListener('resize', this.moveBalanceDebounced)
  },
  methods: {
    ...mapActions({
      loadTimeWarpBalance: 'balances/loadTimeWarpBalance',
    }),
    formatTime (amount: BigNumber) {
      return formatCurrency(amount, { currency: 'TIME', minDecimal: 0, maxDecimal: 0 })
    },
    moveBalance () {
      if (this.isLoaded && this.hasSlider && this.$el?.querySelector) {
        const tick = this.$el.querySelector('#tick')
        const track = this.$el.querySelector('#track')
        const rightTick = this.$el.querySelector('#rightTick')
        if (tick && track && rightTick) {
          const tickWidth = tick?.clientWidth || 25
          const trackWidth = track?.clientWidth
          this.tickPosition = Math.max(Math.min((this.sliderFilled / 100) + tickWidth / trackWidth / 2, 1), 0)
        } else {
          this.tickPosition = this.sliderFilled / 100
        }
      }
    },
    moveBalanceDebounced: debounce(function (this: any) { this.moveBalance() }, 200)
  },
})
