import Vue from 'vue'
import Education from '@/models-ts/user/Education'
import { EducationDegree, EducationDegrees } from '@/constants/user/educationDegrees'
import isNotEmptyEducation from '../isNotEmptyEducation'

export default Vue.extend<any, any, any, any>({
  props: {
    editMode: Boolean,
    education: Education,
  },
  data () {
    return {
      form: new Education({}),
      educationDegreesOptions: Object.values(EducationDegrees).map(opt => ({ key: opt.id, label: opt.name })),
    }
  },
  computed: {
    degree () {
      return EducationDegrees[this.form.degree as EducationDegree].name
    },
  },
  watch: {
    education: {
      handler () {
        if (this.education) {
          this.form = new Education(this.education)
        }
      },
      immediate: true
    }
  },
  methods: {
    getFieldName (field: string) {
      return `${field}_${this.form.id}`
    },
    async validate () {
      let isValid = true
      this.errors.clear()
      if (isNotEmptyEducation(this.form)) {
        isValid = await this.$validator.validateScopes('education')
        if (!this.form.year) {
          this.errors.add({
            field: this.getFieldName('form.year'),
            msg: 'Required field',
            scope: 'education'
          })
          isValid = false
        }
      }
      return isValid && !this.$validator.errors.count()
    },
    getErrorMsg (field: any) {
      if (this.errors.has(field)) {
        const err = this.errors.items.find((err: any) => err.field === field)
        if (err.rule === 'required') {
          return 'Required field'
        } else {
          return err.msg.replace(` ${field}`, '')
        }
      }
      return ''
    },
    emitUpdate () {
      this.$emit('update', this.form)
    },
  },
})
