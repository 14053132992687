import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import { DASHBOARD } from '@/constants/routes'
import responseMixin from '@/mixins/responseMixin'
import rolebleMixin from '@/mixins/rolebleMixin'
import NotificationItem from '@/partials/NotificationItem/NotificationItem.vue'
import debounce from 'lodash/debounce'
import times from 'lodash/times'
import uniq from 'lodash/uniq'

const NOTIFICATIONS_LIMIT = 20

export default Vue.extend<any, any, any>({
  mixins: [responseMixin, rolebleMixin],
  components: {
    InfiniteLoading,
    NotificationItem,
  },
  data () {
    return {
      DASHBOARD,
      infiniteId: 0,
      totalLoading: false,
      initLoading: true,
      observer: null,
      readedIds: [],
    }
  },
  computed: {
    ...mapState({
      notifications: (state: any) => state.notifications?.list.values,
      isLoading: (state: any) => state.notifications?.list.isLoading,
      isLoaded: (state: any) => state.notifications?.list.isLoaded,
      pagination: (state: any) => state.notifications?.list.pagination,
      unreadedCount: (state: any) => state.notifications?.unreadedCount,
      userIsLoaded: (state: any) => state.user?.profile?.isLoaded,
      ownId: (state: any) => state.user?.id,
    }),
  },
  async created () {
    // await this.load({ limit: NOTIFICATIONS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  watch: {
    userIsLoaded: {
      async handler () {
        if (this.userIsLoaded) {
          try {
            await this.loadNotifications({ limit: NOTIFICATIONS_LIMIT })
            await this.getUnreadNotificationsCount()
            this.$watch('activeRole', async () => {
              await this.reloadNotifications()
              await this.getUnreadNotificationsCount()
            })
            this.initObserver()
          } catch (e) {
            this.parseError(e)
          } finally {
            this.initLoading = false
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      loadNotifications: 'notifications/loadNotifications',
      reloadNotifications: 'notifications/reloadNotifications',
      readNotifications: 'notifications/readNotifications',
      getUnreadNotificationsCount: 'notifications/getUnreadNotificationsCount',
    }),
    initObserver () {
      if (document.body && this.$refs.notification) {
        const options = {
          rootMargin: '80px 0px 0px',
          threshold: times(10, (n) => n / 10 + 0.1)
        }
        if (!this.observer) {
          this.observer = new IntersectionObserver(this.onVisible, options)
        }
        this.$refs.notification.forEach((notify: any, i: number) => {
          if (!this.notifications[i].readed && !this.readedIds.includes(this.notifications[i].id)) {
            this.observer.observe(notify.$el || notify)
          }
        })
      }
    },
    onVisible (entries: any) {
      for (const entry of entries) {
        if (entry.intersectionRatio > 0.9 || entry.boundingClientRect.height + 20 >= entry.rootBounds.height) {
          const index = this.$refs.notification.findIndex((notify: any) => entry.target === (notify.$el || notify))
          if (this.notifications[index] && !this.notifications[index].readed) {
            this.readedIds.push(this.notifications[index].id)
            if (this.observer) {
              this.observer.unobserve(entry.target)
            }
          }
        }
      }
      if (this.readedIds.length) {
        this.readNotificationDebounced()
      }
    },
    readNotificationDebounced: debounce(async function (this: any) {
      const ids = uniq(this.readedIds)
      if (ids.length) {
        this.readedIds = this.readedIds.filter((id: number) => !ids.includes(id))
        try {
          await this.readNotifications(ids)
        } catch (e) {
          console.error('Error reading notifications', e)
          this.readedIds.push(...ids)
        }
      }
    }, 1000),
    async onLoadMore ($state: any) {
      try {
        if (this.notifications.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadNotifications({ limit: NOTIFICATIONS_LIMIT, offset: this.notifications.length })
          this.initObserver()
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
  },
  metaInfo: {
    title: 'Notifications',
  },
})
