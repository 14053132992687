import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { CUSTOMER_PROFILE, FREELANCER_PROFILE } from '@/constants/routes'
import MyFreelancerProfile from '@/models-ts/user/profiles/freelancer/MyFreelancerProfile'
import MyCustomerProfile from '@/models-ts/user/profiles/cutomer/MyCustomerProfile'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  props: {
    profile: [MyFreelancerProfile, MyCustomerProfile],
  },
  computed: {
    ...mapState('user', {
      userId: (state: any) => state.id,
      name: (state: any) => state.name,
    }),
    profileLink () {
      return this.isFreelancer
        ? { name: FREELANCER_PROFILE, params: { id: this.userId } }
        : { name: CUSTOMER_PROFILE, params: { id: this.userId } }
    },
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onShareProfile () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "settings-modals" */ '@/modals/ShareProfileModal/ShareProfileModal.vue'),
          props: {
            name: this.name,
            profile: this.profile,
          }
        }
      })
    }
  }
})
