import Vue, { PropType } from 'vue'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import WorkExperience from '@/models-ts/user/WorkExperience'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import responseMixin from '@/mixins/responseMixin'
import { saveMyWorkExperienceProfile } from '@/api/users'
import ExperienceItem from './ExperienceItem/ExperienceItem.vue'
import isNotEmptyExperience from './isNotEmptyExperience'
import focusableMixin from '@/pages/ProfileSettings/focusableMixin'

export default Vue.extend<any, any, any, any>({
  mixins: [focusableMixin, responseMixin],
  components: {
    ExperienceItem,
  },
  props: {
    workExperience: {
      type: Array as PropType<Array<WorkExperience>>,
      default: [],
    },
  },
  data () {
    return {
      editMode: false,
      saving: false,
      form: [],
      editableFields: ['experience'],
    }
  },
  computed: {
    wasUpdated () {
      const form = this.form.filter(isNotEmptyExperience)
      if (form.length !== this.workExperience.length) {
        return true
      }
      for (let expIndex in form) {
        const a = omit(form[expIndex].toServer(), 'id')
        const b = omit(this.workExperience[expIndex].toServer(), 'id')
        if (!isEqual(a, b)) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    workExperience: {
      handler () {
        if (this.workExperience) {
          this.initForm()
        }
      },
      immediate: true
    }
  },
  methods: {
    initForm () {
      this.form = this.workExperience.map((exp: WorkExperience) => new WorkExperience(exp))
    },
    onEditClick () {
      this.editMode = true
      if (!this.form.length) {
        this.form.push(new WorkExperience({}))
      }
    },
    onAddClick () {
      this.editMode = true
      this.form.push(new WorkExperience({}))
    },
    onClearClick (i: number) {
      this.form.splice(i, 1)
      if (!this.form.length) {
        this.form.push(new WorkExperience({}))
      }
    },
    onUpdate (exp: WorkExperience, i: number) {
      this.form.splice(i, 1, exp)
    },
    onCancelClick () {
      this.editMode = false
      this.initForm()
    },
    async onFormSubmit () {
      const isValid = await Promise.all((this.$refs.experienceItem || []).map((item: any) => item.validate()))
      if (isValid.every(Boolean)) {
        try {
          this.saving = true
          const experience = await notifiableRequest({
            request: async () => {
              return saveMyWorkExperienceProfile(this.form
                .filter(isNotEmptyExperience)
                .map((exp: WorkExperience) => exp.toServer()))
            },
            title: 'Work experience',
            successText: 'Your work experience has been successfully updated.',
            failureText: 'Error updating your work experience. Please try again.'
          })
          this.form = experience.map(WorkExperience.fromServer)
          this.$emit('update', this.form)
          this.editMode = false
        } catch (e) {
          console.error(e)
        } finally {
          this.saving = false
        }
      }
    },
  },
})
