import Vue from 'vue'
import { mapMutations } from 'vuex'
import { JOB_ADD, SERVICES } from '@/constants/routes'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import MyCustomerJobListItem from '@/models-ts/job/MyCustomerJobListItem'
import MyVacancyListItem from '@/models-ts/vacancies/MyVacancyListItem'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import MyGigApplication from '@/models-ts/gigs/MyGigApplication'
import JobPostedCardCustomer from '../../cards/job/JobPostedCardCustomer/JobPostedCardCustomer.vue'
import JobActiveCardCustomer from '../../cards/job/JobActiveCardCustomer/JobActiveCardCustomer.vue'
import MyVacancyCard from '../../cards/vacancy/MyVacancyCard/MyVacancyCard.vue'
import GigJobCard from '../../cards/gig/GigJobCard/GigJobCard.vue'
import OfferCard from '../../cards/gig/OfferCard/OfferCard.vue'
import { Statuses } from '@/constants/vacancies/statuses'

type ActiveJob = MyCustomerJobListItem | MyVacancyListItem | GigOfferListItem | MyGigApplication

export default Vue.extend<any, any, any, any>({
  components: {
    GigJobCard,
    JobActiveCardCustomer,
    MyVacancyCard,
    JobPostedCardCustomer,
  },
  props: {
    jobs: Array,
    isLoading: Boolean,
    isLoaded: Boolean,
  },
  data () {
    return {
      JOB_ADD,
      SERVICES,
    }
  },
  computed: {
    renderingJobs () {
      return this.jobs.map(this.getComponent)
    },
  },
  methods: {
    ...mapMutations({
      setJobReview: 'dashboard/setJobReview',
      setCustomerVacancyDelete: 'dashboard/setCustomerVacancyDelete',
      setCustomerVacancyUnArchive: 'dashboard/setCustomerVacancyUnArchive',
    }),
    isFailedModeration (job: MyCustomerJobListItem) {
      return job.moderationStage === ModerationStages.FAILED
    },
    getComponent (job: ActiveJob) {
      if (job instanceof MyCustomerJobListItem) {
        return {
          component: this.isFailedModeration(job)
            ? JobPostedCardCustomer
            : JobActiveCardCustomer,
          id: job.id,
          props: {
            job,
          },
          handlers: {
            leaveReview: (job: MyCustomerJobListItem) => {
              this.setJobReview(job)
            },
          }
        }
      } else if (job instanceof MyVacancyListItem) {
        return {
          component: MyVacancyCard,
          id: job.id,
          props: {
            vacancy: job,
          },
          handlers: {
            delete: (job: MyVacancyListItem) => {
              this.setCustomerVacancyDelete({ job, type: 'archive' })
            },
            unArchive: (job: MyVacancyListItem) => {
              this.setCustomerVacancyUnArchive(job)
              if (job.status === Statuses.DRAFT) {
                this.$emit('changeTab', 'draft')
              }
              if (job.status === Statuses.PUBLISHED) {
                this.$emit('changeTab', 'active')
              }
            }
          }
        }
      } else if (job instanceof GigOfferListItem) {
        return {
          component: GigJobCard,
          // @ts-ignore
          id: job.id,
          props: {
            gig: job,
            isFreelancer: false,
          }
        }
      } else if (job instanceof MyGigApplication) {
        return {
          component: OfferCard,
          id: job.id,
          props: {
            application: job,
          }
        }
      }
    },
  }
})
