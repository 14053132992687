import { Badge } from '../Badge'

export default class Badges {
  static Moderation: Badge = {
    cssClass: 'orange-status',
    name: 'Moderation',
    // eslint-disable-next-line max-len
    description: 'Your Gig application is on pre-moderation. You will receive a notification within the next 12 hours informing you if your application has been approved or not.',
  }
  static Declined: Badge = {
    cssClass: 'red-status',
    name: 'Declined',
    // eslint-disable-next-line max-len
    description: 'Our moderation team determined that your Gig application violated our rules of engagement. If you have any concerns, feel free to contact our support team.',
  }
  static OfferRequested: Badge = {
    cssClass: 'blue-status',
    name: 'Offer requested',
  }
  static OfferSent: Badge = {
    cssClass: 'blue-status',
    name: 'Offer sent',
  }
  static OfferDeclined: Badge = {
    cssClass: 'blue-status',
    name: 'Offer declined',
  }
  static OfferAccepted: Badge = {
    cssClass: 'blue-status',
    name: 'Offer accepted',
  }
}
