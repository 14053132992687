import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import rolebleMixin from '@/mixins/rolebleMixin'
import { JOB_DETAILS_ADMIN_APPLICATIONS, MY_JOBS } from '@/constants/routes'
import snackMixin from '@/mixins/snackMixin'
import notifiableRequest from '@/utils-ts/notifiableRequest'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import Currency from '@/models-ts/Currency'
import { Blockchain, BlockchainNames } from '@/constants/blockchain'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import ratesMixin from '@/mixins/ratesMixin'
import { addDays, isBefore } from '@/utils/date'
import Job from '@/models-ts/job/Job'
import Button from '@/models-ts/ui/Button'
import { declineApplicationAsFreelancer } from '@/api/jobs/applications'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'
import { STAGE_IN_PROGRESS } from '@/constants/job/jobStages'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import Rate from '@/models-ts/Rate'
import { formatUsd } from '@/utils/moneyFormat'
import BigNumber from 'bignumber.js'
import { cancelDoneJob, doneJob, getJob, unlockJobRoom } from '@/api/jobs/job'
import { STATUS_DRAFT } from '@/constants/job/jobStatuses'
import { RootState } from '@/store'
import { DISPUTE_JOB_TYPE } from '@/constants/components/CreateDispute'

const EXTRA_DAYS = 2

export default Vue.extend<any, any, any, any>({
  mixins: [ratesMixin, rolebleMixin, snackMixin],
  data () {
    return {
      paying: false,
      starting: false,
      donning: false,
      cancelDone: false,
      unlocking: false,
      archiving: false,
    }
  },
  computed: {
    ...mapState<RootState>({
      roomId: (state: RootState) => state.chatNew.openedRoomId,
      depositingOffer: (state: RootState) => state.chatNew.depositingOffer,
      userId: (state: RootState) => state.user?.id,
    }),
    depositing () {
      return this.depositingOffer && this.depositingOffer === this.application.offer?.id
    },
    ...mapGetters({
      room: 'chatNew/getOpenedRoom',
      getParticipant: 'chatNew/getParticipant',
    }),
    isArchived () {
      return this.application.status === JobApplicationStatuses.ARCHIVED
    },
    isLocked () {
      return this.room && !this.room.isUnlocked
    },
    isOwner () {
      return this.application.customer.id === this.userId
    },
    isClient () {
      return this.application?.offer?.customerId === this.userId
    },
    isEmployer () {
      return this.isCustomer && this.isClient
    },
    isWorker () {
      return this.isFreelancer && !this.isOwner
    },
    isDraft () {
      return this.application.job.status === STATUS_DRAFT
    },
    isStarted () {
      return this.application?.wasStarted
    },
    isCompleted () {
      return this.application.isCompleted
    },
    isMetaArchived () {
      return this.application.meta?.archived
    },
    deadline () {
      return this.application.estimatedDeadline
    },
    deadlineHasCome () {
      return isBefore(this.deadline, Date.now())
    },
    refundDate () {
      return addDays(this.deadline, EXTRA_DAYS)
    },
    refundHasCome () {
      return isBefore(this.refundDate, Date.now())
    },
    hasRequestOffer () {
      return this.isOwner && !this.application?.hasOffers && !this.isArchived
    },
    hasCreateOffer () {
      return this.isWorker && !this.isOwner && !this.isArchived && !this.isMetaArchived && this.isFreelancer && !this.application?.offer
    },
    hasJobDone () {
      return this.isWorker && !this.application.job?.isDone && this.application.job.stage === STAGE_IN_PROGRESS
    },
    hasJobWasDone () {
      return this.application.job?.isDone && this.application.job.stage === STAGE_IN_PROGRESS
    },
    hasCancelCustomerJob () {
      return this.isCustomer && !this.isArchived && this.isOwner && !this.application?.wasStarted && !this.isMetaArchived
    },
    hasRemoveFromArchiveJob () {
      return this.isCustomer && !this.isArchived && this.isOwner && !this.application?.wasStarted && this.isMetaArchived
    },
    hasCancelFreelancerJob () {
      return this.isFreelancer &&
        !this.isArchived &&
        !this.isStarted &&
        this.application.offer?.stage !== JobOfferStages.ACCEPTED_BY_CUSTOMER
    },
    hasStartJob () {
      return this.isEmployer && !this.isStarted && this.application.offer?.stage === JobOfferStages.ACCEPTED_BY_CUSTOMER
    },
    hasPayJob () {
      return this.isEmployer && this.application.job?.stage === STAGE_IN_PROGRESS && !this.hasJobWasDone
    },
    hasAcceptJob () {
      return this.isEmployer && this.application.job?.stage === STAGE_IN_PROGRESS && this.hasJobWasDone
    },
    hasCreateDispute () {
      return this.isWorker && this.application.job?.stage === STAGE_IN_PROGRESS && this.deadlineHasCome
    },
    hasRefund () {
      return this.isEmployer && this.application.job?.stage === STAGE_IN_PROGRESS && this.refundHasCome
    },
    hasReviewed () {
      if (this.isCompleted) {
        const reviews = this.offer?.relations?.Job?.relations?.Review || []
        return reviews.find((review: any) => review.from_user_id === this.userId)
      }
      return false
    },
    hasLeaveReview () {
      if (this.isCompleted) {
        const reviews = this.offer?.relations?.Job?.relations?.Review || []
        return !reviews.find((review: any) => review.from_user_id === this.userId)
      }
      return false
    },
    hasViewInvoice () {
      return this.application.job.invoiceUrl
    },
  },
  methods: {
    ...mapMutations({
      setDepositingOffer: 'chatNew/setDepositingOffer',
    }),
    ...mapActions({
      openModal: 'ui/openModal',
      closeRoom: 'chatNew/closeRoom',
      signCreateContractV2: 'jobDetails/signCreateContractV2',
      signPayToFreelancerV1: 'jobDetails/signPayToFreelancerV1',
      signPayToFreelancerV2: 'jobDetails/signPayToFreelancerV2',
      returnFundsToCustomerV1: 'jobDetails/returnFundsToCustomerV1',
      refundToCustomerByCustomerV2: 'jobDetails/refundToCustomerByCustomerV2',
      setApplicationMeta: 'jobDetails/setApplicationMeta',
    }),
    emitRefresh () {
      return this.$emit('refresh')
    },
    async onRequestOfferClick () {
      this.unlocking = true
      try {
        googleAnalyticsV2.send({
          event: 'response-to-job',
          'event-content': 'customer-button-click-request-offer',
          job_id: this.application.job.id,
        })
        await unlockJobRoom(this.room.id)
      } finally {
        this.unlocking = false
      }
    },
    onCreateOfferClick () {
      const budget = Number(this.application.budget.amount) || 15
      const deadline = this.application.deadlineInDays > 0
        ? this.application.deadlineInDays
        : 0
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/SendOffer/SendOffer.vue'),
          title: 'Send an offer',
          props: {
            applicationId: this.room.applicationId,
            initBudget: budget,
            initDays: deadline,
            onSuccess: () => {
              this.emitRefresh()
            }
          }
        }
      })
    },
    async onDeclineApplicationClick () {
      await this.setArchivedMeta(true)
      this.$router.push({
        name: JOB_DETAILS_ADMIN_APPLICATIONS,
        params: { id: this.application.job.id, slug: this.application.job.slug }
      })
    },
    onCancelFreelancerJobClick () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Retract application?',
          text: `This application will be removed from applications list.`,
          buttons: [
            new Button({
              text: 'Cancel',
              classes: 'lx-transparent-blue-btn',
            }),
            new Button({
              text: 'Proceed',
              classes: 'lx-blue-btn',
              onClick: async () => {
                try {
                  await declineApplicationAsFreelancer(this.application.id)
                  this.$router.push({ name: MY_JOBS })
                } catch (e) {
                  this.parseError(e)
                }
              },
            }),
          ]
        }
      })
    },
    async setArchivedMeta (flag: boolean) {
      if (flag) {
        googleAnalyticsV2.send({
          event: 'response-to-job',
          'event-content': 'customer-button-click-decline',
          job_id: this.application.job.id,
        })
      }
      this.archiving = true
      try {
        const meta = { ...this.application.meta, archived: flag }
        await this.setApplicationMeta({
          jobId: this.application.job.id,
          applicationId: this.application.id,
          meta,
        })
        this.$emit('update-meta', meta)
      } finally {
        this.archiving = false
      }
    },
    async onDoneClick () {
      this.donning = true
      try {
        await notifiableRequest({
          request: async () => {
            await doneJob(this.application.job.id)
            this.emitRefresh()
          },
          title: 'Completing job',
          successText: 'You have marked this Job as complete.',
          failureText: 'Error submitting request. Please try again.'
        })
      } finally {
        this.donning = false
      }
    },
    async onCancelJobDoneClick () {
      this.cancelDone = true
      try {
        await notifiableRequest({
          request: async () => {
            await cancelDoneJob(this.application.job.id)
            this.emitRefresh()
          },
          title: 'Completing job',
          successText: 'Job marked as incomplete.',
          failureText: 'Error submitting request. Please try again.'
        })
      } finally {
        this.cancelDone = false
      }
    },
    onReportClick () {
      const toUser = this.room.participants.find((user: ChatParticipant) => +user.userId !== this.userId)
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/ReportUser/ReportUser.vue'),
          props: {
            toUserId: toUser.userId,
            toUserName: toUser.name,
          },
        },
      })
    },
    onStartJobClick () {
      this.startJob()
    },
    async startJob () {
      try {
        this.starting = true
        this.setDepositingOffer(this.application.offer?.id)
        if (this.isMetaArchived) {
          await this.setArchivedMeta(false)
        }
        const job = Job.fromServer(await getJob(`${this.application.job.slug}-${this.application.job.id}`))
        if (job) {
          const offer = this.application.offer
          this.signCreateContractV2({
            params: {
              deadline: offer.deadline,
              rate: offer.budget,
              // @ts-ignore
              freelancerWallets: job?.meta?.FreelancerWallets || [],
              id: job.id,
              slug: job.slug,
              scId: job.sc_id,
              name: job.name,
              offerId: offer.id,
              freelancer: {
                ...job.freelancer,
                wallet: offer.freelancerWallet,
              },
            },
            successSign: () => {
              this.openSnackbar({
                type: this.SnackTypes.SUCCESS,
                text: 'Your funds have successfully been deposited to escrow.',
              })
              this.starting = false
              this.emitRefresh()
            },
            cancelSign: () => {
              this.starting = false
              this.setDepositingOffer(null)
            },
          })
        }
      } catch (err) {
        console.error(err)
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error starting job'
        })
        this.setDepositingOffer(null)
        this.starting = false
      }
    },
    async onPayToTalentClick (onAccept: boolean) {
      this.paying = true
      try {
        const job = Job.fromServer(await getJob(`${this.application.job.slug}-${this.application.job.id}`))
        if (this.application.job.contractVersion === 1 || !this.application.job.contractVersion) {
          this.signPayToFreelancerV1({
            job: job,
            successSign: async (
              { tx, walletName, currency, blockchain }:
              { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
            ) => {
              this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
              this.emitRefresh()
            }
          })
        } else if (this.application.job.contractVersion === 2) {
          this.signPayToFreelancerV2({
            job: job,
            successSign: async (
              { tx, walletName, currency, blockchain }:
              { tx: string, walletName: string, currency: Currency, blockchain: Blockchain }
            ) => {
              this.sendSuccessAnalytics(tx, walletName, currency, blockchain)
              this.emitRefresh()
            }
          })
        }
      } finally {
        this.paying = false
      }
    },
    sendSuccessAnalytics (tx: string, walletName: string, currency: Currency, blockchain: Blockchain) {
      googleAnalyticsV2.send({
        event: 'offer-job-payment',
        'event-content': tx,
        offer_id: this.application.offer.id,
        job_id: this.application.job.id,
        block_type: BlockchainNames[blockchain],
        currency: currency.name,
        sum: this.getUsdSum(currency),
        wallet_integration_type: walletName,
      })
    },
    getUsdSum (currency: Currency) {
      const price = this.rates.find((rate: Rate) => rate.currency === currency?.name)?.price || '0'
      return this.application.job.escrow_balance
        ? formatUsd(
          new BigNumber(this.application.job.escrow_balance)
            .dividedBy(currency.decimalsDivider || currency.baseUnits)
            .multipliedBy(price)
        )
        : '0'
    },
    onCreateDisputeClick () {
      if (this.deadlineHasCome) {
        this.openModal({
          component: 'lx-lazy-modal',
          props: {
            factory: import(/* webpackChunkName: "job-modals" */ '@/modals/CreateDispute/CreateDispute.vue'),
            title: 'Open a dispute',
            props: {
              disputeData: {
                type: DISPUTE_JOB_TYPE,
                id: this.application.job.id,
                name: this.application.job.name,
                blockchain: this.application.job.blockchain,
              },
              onSuccess: () => {
                this.emitRefresh()
              }
            }
          }
        })
      } else {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: `You can only initiate a dispute once the deadline has been reached.`,
        })
      }
    },
    async onRefundClick () {
      const job = Job.fromServer(await getJob(`${this.application.job.slug}-${this.application.job.id}`))
      const refundMethod = this.application.job.contractVersion === 1 || !this.application.job.contractVersion
        ? 'returnFundsToCustomerV1'
        : 'refundToCustomerByCustomerV2'
      if (process.env.VUE_APP_MODE === 'dev') {
        this[refundMethod]({
          job: job,
          successSign: () => {
            this.emitRefresh()
          }
        })
      } else {
        if (this.refundHasCome) {
          this[refundMethod]({
            job: job,
            successSign: () => {
              this.emitRefresh()
            }
          })
        } else {
          this.openSnackbar({
            type: this.SnackTypes.FAILURE,
            text: `You can only initiate a refund 48 hours after the deadline has been reached.`,
          })
        }
      }
    },
    onLeaveReviewClick () {
      this.openModal({
        component: 'lx-lazy-modal',
        props: {
          factory: import(/* webpackChunkName: "job-modals" */ '@/modals/LeaveFeedback/LeaveFeedback.vue'),
          title: 'Leave a review',
          props: {
            fromFreelancer: this.isFreelancer,
            jobId: this.application.job.id,
            name: this.application.job.name,
            profile: this.isFreelancer ? this.application.customer : this.application.freelancer,
            onSuccess: () => {
              this.emitRefresh()
            },
          }
        }
      })
    },
  }
})
