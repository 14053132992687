import get from 'lodash/get'
import { mapActions, mapMutations } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import GigOfferListItem from '@/models/lists/GigOfferListItem'
import notifiableRequest from '@/utils/notifiableRequest'
import {
  JOB_STATUS_BLOCKED,
  JOB_STATUS_DISPUTED,
  JOB_STATUS_IN_PROGRESS,
  JOB_STATUS_PAYED,
  JOB_STATUS_RETURNED,
  STATUS_PUBLISHED
} from '@/constants/backend/service'
import { getGigJob, publishService } from '@/api/gig'
import { googleAnalyticsV2 } from '@/servicies-ts/analytics'
import snackMixin from '@/mixins/snackMixin'

export default {
  mixins: [snackMixin],
  methods: {
    ...mapActions({
      executeBtnRequest: 'notifications/executeBtnRequest',
      returnGigFundsToCustomerV1: 'gig/returnFundsToCustomerV1',
      refundGigToCustomerByCustomerV2: 'gig/refundToCustomerByCustomerV2',
      signGigPayToFreelancerV1: 'gig/signPayToFreelancerV1',
      signGigPayToFreelancerV2: 'gig/signPayToFreelancerV2',
    }),
    ...mapMutations({
      setNotificationData: 'notifications/setNotificationData',
    }),
    onPublishGigClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: false,
        request: () => notifiableRequest({
          title: 'Publish Gig',
          successText: `Gig was successfully published`,
          failureText: (e) => {
            const defaultErr = 'Gig publishing error'
            return ErrorMatcher.isConflict(e) ? get(e, 'response.data.message', defaultErr) : defaultErr
          },
          request: async () => {
            await publishService(note.params.gig?.id)
            googleAnalyticsV2.send({
              event: 'gig-published',
              gig_id: note.params.gig?.id,
            })
            this.setNotificationData({ id: note.id, path: 'params.gig', property: 'status', data: STATUS_PUBLISHED })
          },
        }),
      })
    },
    onGigRefundClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: true,
        request: async () => {
          const data = await getGigJob(note.params.job?.id)
          const gig = GigOfferListItem.fromServerById(data)
          if (gig.job.stage === JOB_STATUS_IN_PROGRESS) {
            if (note.params.job.contract_version === 1 || !note.params.job.contract_version) {
              this.returnGigFundsToCustomerV1({
                gig,
                successSign: () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: JOB_STATUS_RETURNED
                  })
                }
              })
            }
            if (note.params.job.contract_version === 2) {
              this.refundGigToCustomerByCustomerV2({
                gig,
                successSign: () => {
                  this.setNotificationData({
                    id: note.id,
                    path: 'params.job',
                    property: 'stage',
                    data: JOB_STATUS_RETURNED
                  })
                }
              })
            }
          } else {
            this.notifyWrongStatus(gig)
            this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: gig.job.stage })
          }
        }
      })
    },
    onGigPayFreelancerClick (note) {
      this.executeBtnRequest({
        id: note.id,
        isFirstBtn: true,
        request: async () => {
          const data = await getGigJob(note.params.job?.id)
          const gig = GigOfferListItem.fromServerById(data)
          if (gig.job.stage === JOB_STATUS_IN_PROGRESS) {
            if (note.params.job.contract_version === 1 || !note.params.job.contract_version) {
              await this.signGigPayToFreelancerV1({
                gig,
                successSign: async () => {
                  this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: JOB_STATUS_PAYED })
                }
              })
            }
            if (note.params.job.contract_version === 2) {
              await this.signGigPayToFreelancerV2({
                gig,
                successSign: async () => {
                  this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: JOB_STATUS_PAYED })
                }
              })
            }
          } else {
            this.notifyWrongStatus(gig)
            this.setNotificationData({ id: note.id, path: 'params.job', property: 'stage', data: gig.job.stage })
          }
        }
      })
    },
    notifyWrongStatus (gig) {
      const msg = ![JOB_STATUS_BLOCKED, JOB_STATUS_DISPUTED].includes(gig.job.stage)
        ? 'A dispute has been initiated under this contract'
        : 'The contract is already completed'
      this.openSnackbar({
        type: this.SnackTypes.FAILURE,
        text: msg,
      })
    },
  },
}
