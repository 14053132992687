export const CATEGORY_SYSTEM = 1
export const CATEGORY_RELEASE_NOTES = 2
export const CATEGORY_NEWS_AND_EVENTS = 3
export const CATEGORY_GIGS = 4
export const CATEGORY_JOBS = 5
export const CATEGORY_VACANCIES = 6
export const CHAT = 7

export const Subscriptions = {
  CATEGORY_SYSTEM,
  CATEGORY_RELEASE_NOTES,
  CATEGORY_NEWS_AND_EVENTS,
  CATEGORY_GIGS,
  CATEGORY_JOBS,
  CATEGORY_VACANCIES,
  CHAT
} as const

export type Subscription = typeof Subscriptions[keyof typeof Subscriptions]
