import Vue, { PropType } from 'vue'
import { GigJobStages } from '@/constants/gig/gigJobStages'
import { mapState } from 'vuex'
import { Badge } from '../Badge'
import Badges from './Badges'
import { JobOfferStages } from '@/constants/job/jobOfferStages'
import { JobApplicationStatuses } from '@/constants/job/jobApplicationStatuses'

export default Vue.extend<any, any, any, any>({
  props: {
    applicationStatus: Number as PropType<JobApplicationStatuses>,
    offerStage: Number as PropType<JobOfferStages>,
  },
  computed: {
    ...mapState({
      userId: (state: any) => state.user?.id,
    }),
    isCompleted () {
      return [GigJobStages.PAYED, GigJobStages.RETURNED, GigJobStages.DISPUTED].includes(this.gig?.job?.stage)
    },
    hasReview (): boolean {
      return this.isCompleted && this.gig?.reviews.find((review: any) => review.from_user_id === this.userId)
    },
    badge (): Badge | undefined {
      if (this.applicationStatus === JobApplicationStatuses.ARCHIVED) return Badges.ApplicationDeclined
      if (this.applicationStatus === JobApplicationStatuses.NEW && !this.offerStage) return Badges.ApplicationSent
      if (this.offerStage === JobOfferStages.NEW) return Badges.OfferSent
      if (this.offerStage === JobOfferStages.DECLINED_BY_CUSTOMER || this.offerStage === JobOfferStages.DECLINED_BY_FREELANCER) {
        return Badges.OfferDeclined
      }
      if (this.offerStage === JobOfferStages.ACCEPTED_BY_CUSTOMER) return Badges.OfferAccepted
    },
  },
})
