import Vue from 'vue'
import ArchivedTab from './ArchivedTab/ArchivedTab.vue'
import FreelanceTab from './FreelanceTab/FreelanceTab.vue'
import FullTimeTab from './FullTimeTab/FullTimeTab.vue'
import { CHAT, CHAT_ARCHIVED, CHAT_FULL_TIME } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  computed: {
    tabs () {
      return [
        { title: 'Freelance', component: FreelanceTab, to: { name: CHAT } },
        { title: 'Full-time', component: FullTimeTab, to: { name: CHAT_FULL_TIME } },
        { title: 'Archived', component: ArchivedTab, to: { name: CHAT_ARCHIVED } },
      ]
    },
  },
  metaInfo: {
    title: 'Chat'
  }
})
