import Vue, { PropType } from 'vue'
import { GigStatuses } from '@/constants/gig/gigStatuses'
import Badges from './Badges'
import { ModerationStages } from '@/constants/backend/ModerationStages'
import { Badge } from '../Badge'

export default Vue.extend({
  props: {
    moderationStage: Number as PropType<ModerationStages>,
    status: Number as PropType<GigStatuses>,
  },
  computed: {
    badge (): Badge {
      if (this.status === GigStatuses.DRAFT) return Badges.Draft
      if ([ModerationStages.PREMODERATION, ModerationStages.MANUAL].includes(this.moderationStage)) return Badges.Moderation
      if (this.moderationStage === ModerationStages.FAILED) return Badges.Failed
      return Badges.Publish
    },
  },
})
