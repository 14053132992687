import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import responseMixin from '@/mixins/responseMixin'
import MyApplicationCard from '@/partials/VacancyCards/MyApplicationCard/MyApplicationCard.vue'

const JOBS_LIMIT = 20
export default Vue.extend<any, any, any, any>({
  mixins: [responseMixin],
  components: {
    MyApplicationCard,
    InfiniteLoading,
  },
  data () {
    return {
      infiniteId: 0,
      totalLoading: false,
    }
  },
  computed: {
    ...mapState('myVacancies', {
      applications: (state: any) => state.freelancerAll.values,
      isLoading: (state: any) => state.freelancerAll.isLoading,
      isLoaded: (state: any) => state.freelancerAll.isLoaded,
      pagination: (state: any) => state.freelancerAll.pagination,
    }),
  },
  async created () {
    await this.load({ limit: JOBS_LIMIT, offset: 0 })
    this.infiniteId = +new Date()
  },
  methods: {
    ...mapActions({
      load: 'myVacancies/loadFreelancerAll',
      loadMore: 'myVacancies/loadMoreFreelancerAll',
    }),
    async onLoadMore ($state: any) {
      try {
        if (this.applications.length < this.pagination.total) {
          this.totalLoading = true
          await this.loadMore({ limit: JOBS_LIMIT, offset: this.applications.length })
          return $state.loaded()
        }
        return $state.complete()
      } catch (e) {
        this.parseError(e)
      } finally {
        this.totalLoading = false
      }
    },
  },
  metaInfo: {
    title: 'My Full-time Jobs - Archived',
  },
})
