import Vue from 'vue'
import rolebleMixin from '@/mixins/rolebleMixin'
import VacancyUnavailableChatMessage from '@/models-ts/chat/messages/VacancyUnavailableChatMessage'
import VacancyArchivedChatMessage from '@/models-ts/chat/messages/VacancyArchivedChatMessage'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  props: {
    message: [VacancyUnavailableChatMessage, VacancyArchivedChatMessage],
  },
})
