import { mapActions, mapMutations, mapState } from 'vuex'
import ErrorMatcher from '@/utils/ErrorMatcher'
import responseMixin from '@/mixins/responseMixin'
import { BUTTON_BLUE_MEDIUM } from '@/constants/components/button'

export default {
  mixins: [responseMixin],
  data () {
    return {
      BUTTON_BLUE_MEDIUM,
      newEmail: '',
      processing: false,
      isEmailChanging: false,
    }
  },
  computed: {
    ...mapState({
      email: state => state.user?.email || '',
    }),
  },
  methods: {
    ...mapMutations({
      confirmed2FA: 'ui/confirmed2FA',
    }),
    ...mapActions({
      changeEmail: 'user/changeEmail',
      openModal: 'ui/openModal',
    }),
    async onChangeEmailSubmit () {
      try {
        this.processing = true
        if (await this.$validator.validateAll()) {
          await this.changeEmail({ email: this.newEmail })
          this.openSuccessModal()
        }
      } catch (e) {
        if (ErrorMatcher.is2FA(e)) {
          this.openModal({
            component: 'lx-confirm-2fa-modal',
            props: {
              confirm: async (key) => {
                await this.changeEmail({ email: this.newEmail, key })
                this.openSuccessModal()
                this.confirmed2FA()
              }
            }
          })
        } else {
          this.parseError(e)
        }
      } finally {
        this.processing = false
      }
    },
    openSuccessModal () {
      this.openModal({
        component: 'lx-composite-modal-new',
        props: {
          title: 'Changing email',
          text: `We've sent you an email to <b>${this.newEmail}</b> with confirmation link`,
        }
      })
    },
  },
}
