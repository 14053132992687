import Vue from 'vue'
import { mapState, mapActions } from 'vuex'
import ChatParticipant from '@/models-ts/chat/ChatParticipant'
import ChatRoom from '@/models-ts/chat/ChatRoom'
import rolebleMixin from '@/mixins/rolebleMixin'
import { getHumanDate } from '@/utils/date'

export default Vue.extend<any, any, any, any>({
  mixins: [rolebleMixin],
  props: {
    room: ChatRoom,
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapState({
      userIsLoaded: (state: any) => state.user.profile.isLoaded,
      userId: (state: any) => state.user?.id,
    }),
    participant () {
      return this.room.participants.find((user: ChatParticipant) => user.userId !== String(this.userId))
    },
    date () {
      return getHumanDate(this.room.lastMessageAt)
    },
    unreadCount () {
      return this.room.unreadCount > 99 ? '99+' : this.room.unreadCount
    }
  },
  methods: {
    ...mapActions({
      openRoom: 'chatNew/openRoom',
    }),
    onSelfClick () {
      this.openRoom(this.room.id)
      this.$router.push(this.room.chatLink)
    },
  },
})
